import { useState } from "react";
import _ from "lodash";
import {
  Box,
  FormControl,
  MenuItem,
  TextField,
  Select,
  FormLabel,
} from "@mui/material";
import ModalDialog from "../../../../components/shared/Modal/Modal";
import { useCreateInviteMutation } from "../../../../store/services/tprla/users";
import "./TprlaRoleAssign.scss";
import { Typography } from "@material-ui/core";
import { emailPattern } from "../../../../utils/constants";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../store/slices/dtms/loading";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const InviteUser = (props) => {
  let { open = false, onClose = () => {}, refreshUserList = () => {} } = props;

  const tprlaProjectRoles = useSelector(
    (state) => state.tprlaMaster.tprlaProjectRoles
  );

  const [createInvite] = useCreateInviteMutation();
  const dispatch = useDispatch();
  const params = useParams();

  const [selectedRole, setSelectedRole] = useState("");
  const [email, setEmail] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [role, setRole] = useState("");
  const [roleErrorText, setRoleErrorText] = useState("");

  const inviteUserModalOptions = {
    title: "Invite user",
    option: {
      open,
      maxWidth: "sm",
      actionButtonName: "Send Request",
    },
  };

  const [modalOptions, setModalOptions] = useState(inviteUserModalOptions);

  const onEmailChange = (e) => {
    const email = e.target.value;
    if (email.trim() !== "" && emailPattern.test(email)) {
      setEmail(email);
      setEmailErrorText("");
    } else if (!emailPattern.test(email)) {
      setEmail("");
      setEmailErrorText("Please Enter a valid Email");
    } else {
      setEmail("");
      setEmailErrorText("Please Enter your Email");
    }
  };

  const onRoleChange = (e) => {
    const role = e.target.value;
    if (role !== "") {
      setRole(role);
      setRoleErrorText("");
      setSelectedRole(e.target.value);
    } else {
      setRole("");
      setRoleErrorText("Please Select a Role");
    }
  };

  const handleSubmit = () => {
    let isEmailValid = false;
    let isRoleSelectedValid = false;

    if (email.trim() === "") {
      setEmailErrorText("Please Enter your Email");
    } else {
      setEmailErrorText("");
      isEmailValid = true;
    }

    if (role === "" && selectedRole === "") {
      setRoleErrorText("Please Select a Role");
    } else {
      setRoleErrorText("");
      isRoleSelectedValid = true;
    }

    if (isEmailValid && isRoleSelectedValid) {
      console.log("REQUEST SENT");
      const payload = {
        email,
        modules: [{ name: "TPRLA", role: { name: role, isActive: "Y" } }],
        role: role,
      };

      handleClose();
      dispatch(setLoading(true));
      createInvite({ payload, projectId: params.projectId })
        .unwrap()
        .then((payload) => {
          console.log("fulfilled", payload);
          dispatch(setLoading(false));

          if (payload && payload.success) {
            confirmAlert({
              title: "Success",
              message: "Invitation sent successfully",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    refreshUserList();
                  },
                },
              ],
            });
          } else {
            dispatch(setLoading(false));
            confirmAlert({
              title: "Error",
              message: payload.result.description,
              buttons: [
                {
                  label: "Ok",
                },
              ],
            });
          }
        })
        .catch((error) => {
          console.log("Oops! ", error);
          const errorMessage =
            error.data && error.data.error
              ? error.data.error.message
                ? error.data.error.message
                : "Invite user failed"
              : "Invite user failed";
          dispatch(setLoading(false));
          confirmAlert({
            title: "Error",
            message: errorMessage,
            buttons: [
              {
                label: "Ok",
              },
            ],
          });
        });
    }
  };

  const handleClose = () => {
    setModalOptions({
      ...modalOptions,
      option: { ...modalOptions.option, open: false },
    });
    setEmail("");
    setRole("");
    setSelectedRole("");
    setEmailErrorText("");
    setRoleErrorText("");
    onClose();
  };

  return (
    <ModalDialog
      {...inviteUserModalOptions}
      onClose={handleClose}
      onSave={handleSubmit}
    >
      <div className="invite-user">
        <Box autoComplete="off">
          <FormLabel className="invite-user-form-label">Email</FormLabel>
          <TextField
            id="outlined-basic"
            type="email"
            variant="outlined"
            fullWidth
            onChange={onEmailChange}
          />
          <Typography className="error-text" sx={{ mt: 2 }}>
            {emailErrorText}
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <FormControl fullWidth>
            <FormLabel className="invite-user-form-label">Role</FormLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedRole}
              onChange={onRoleChange}
            >
              {tprlaProjectRoles?.map((role) => (
                <MenuItem key={role.name} value={role.name}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
            <Typography className="error-text">{roleErrorText}</Typography>
          </FormControl>
        </Box>
      </div>
    </ModalDialog>
  );
};

export default InviteUser;
