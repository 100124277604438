import { forwardRef } from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";

import "./Table.scss";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#df301e",
    },
  },
});

const Table = (props) => {
  const options = {
    ...props.options,
    actionsColumnIndex: -1,
    addRowPosition: "first",
    paginationType: "stepped",
  };

  const tableIcons = {
    FirstPage: forwardRef((props, ref) => (
      <span
        className="Appkit4-icon icon-chevron-double-left-fill ap-font-12"
        {...props}
        ref={ref}
      />
    )),
    LastPage: forwardRef((props, ref) => (
      <span
        className="Appkit4-icon icon-chevron-double-right-fill ap-font-12"
        {...props}
        ref={ref}
      />
    )),
    NextPage: forwardRef((props, ref) => (
      <span
        className="Appkit4-icon icon-right-chevron-fill ap-font-12"
        {...props}
        ref={ref}
      />
    )),
    PreviousPage: forwardRef((props, ref) => (
      <span
        className="Appkit4-icon icon-left-chevron-fill ap-font-12"
        {...props}
        ref={ref}
      />
    )),
    Search: forwardRef((props, ref) => (
      <span {...props} ref={ref}>
        <a className="Appkit4-icon icon-search-outline ap-font-16" />
      </span>
    )),
    Clear: forwardRef((props, ref) => (
      <span
        className="Appkit4-icon icon-close-outline ap-font-16"
        {...props}
        ref={ref}
      />
    )),
    ResetSearch: forwardRef((props, ref) => (
      <span
        className="Appkit4-icon icon-close-outline ap-font-16"
        {...props}
        ref={ref}
      />
    )),
    SortArrow: forwardRef((props, ref) => (
      <span {...props} ref={ref}>
        <a className="Appkit4-icon icon-arrow-up-fill ap-font-12" />
      </span>
    )),
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="tpra-table">
        <MaterialTable {...props} icons={tableIcons} options={options} />
      </div>
    </ThemeProvider>
  );
};

export default Table;
