import React from "react";
import "./FloatingButton.scss";
import { Button } from "@mui/material";

const FloatingButton = ({ buttonClick, buttonText, icon, disabled }) => {
  return (
    <Button
      onClick={buttonClick}
      className="floatingButton"
      variant="contained"
      disabled={disabled}
    >
      {icon}
      {buttonText}
    </Button>
  );
};
export default FloatingButton;
