// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-main-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
}
.view-main .rpv-core__viewer {
  height: 100vh !important;
}`, "",{"version":3,"sources":["webpack://./src/modules/tprla/tprla-project/project-files/View/View.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,mBAAA;AAFJ;AAKE;EACE,wBAAA;AAHJ","sourcesContent":["@import \"../../../../../variables.scss\";\n\n.view-main {\n  &-header {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    margin-bottom: 1rem;\n  }\n\n  .rpv-core__viewer {\n    height: 100vh !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
