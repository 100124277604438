// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floatingButton {
  position: fixed !important;
  z-index: 10;
  right: 25px;
  bottom: 30px;
  padding: 10px !important;
  background-color: white !important;
  font-weight: bolder !important;
  cursor: pointer !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5) !important;
}
.floatingButton.MuiButton-root {
  font-size: smaller !important;
}
.floatingButton.MuiButton-root svg {
  font-size: small !important;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/FloatingButton/FloatingButton.scss"],"names":[],"mappings":"AAEA;EACE,0BAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,wBAAA;EACA,kCAAA;EACA,8BAAA;EACA,0BAAA;EACA,sFAAA;AADF;AAEE;EACE,6BAAA;AAAJ;AACI;EACE,2BAAA;AACN","sourcesContent":["@import \"../../../variables.scss\";\n\n.floatingButton {\n  position: fixed !important;\n  z-index: 10;\n  right: 25px;\n  bottom: 30px;\n  padding: 10px !important;\n  background-color: white !important;\n  font-weight: bolder !important;\n  cursor: pointer !important;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5) !important;\n  &.MuiButton-root {\n    font-size: smaller !important;\n    svg {\n      font-size: small !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
