import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="Appkit4-icon icon-close-fill ap-font-16" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ModalDialog(props) {
  const {
    maxWidth = "sm",
    open = false,
    actionButtonRequired = true,
    actionButtonName = "Save",
    twoActionButtonRequired = false,
    actionSecondaryButtonName = "Cancel",
    shouldCloseIconVisible = true,
    fullWidth = true,
  } = props.option;

  return (
    <div>
      <BootstrapDialog
        onClick={(e) => e.stopPropagation()}
        onClose={props.onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={shouldCloseIconVisible ? () => props.onClose() : null}
        >
          {props.title}
        </BootstrapDialogTitle>
        <DialogContent dividers>{props.children}</DialogContent>
        <DialogActions sx={{ mx: 1 }}>
          {twoActionButtonRequired && (
            <Button variant="outlined" onClick={props.onClose}>
              {actionSecondaryButtonName}
            </Button>
          )}
          {actionButtonRequired && (
            <Button
              variant="contained"
              onClick={props.onSave}
              disabled={props.isDisabled}
            >
              {actionButtonName}
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
