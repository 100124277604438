import { useEffect } from "react";
import { useRevokeTokenMutation } from "../../store/services/dtms/auth";
import { _getLogoutUrl } from "../../envUrl";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/dtms/loading";

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [revokeToken, revokeTokenResult] = useRevokeTokenMutation();

  useEffect(() => {
    const pIAM_authorization_token = sessionStorage.getItem(
      "pIAM_authorization_token"
    );
    if (pIAM_authorization_token !== null) {
      revokeToken(pIAM_authorization_token);
    } else {
      navigate("/");
      dispatch(setLoading(false));
    }
  }, []);

  useEffect(() => {
    const { isError, isSuccess, data, error } = revokeTokenResult;
    const logoutPath = _getLogoutUrl();
    if (isSuccess) {
      console.log("Yay! ", data);
      // Ask other tabs for session storage (this is ONLY to trigger event for ALL TAB)
      localStorage.setItem("setLogoutSession", "dealsSsp");
      localStorage.removeItem("setLogoutSession", "dealsSsp");
      localStorage.removeItem("refreshToken-nanoedmo:time");
      localStorage.removeItem("refreshToken-nanoedmo:running");
      localStorage.removeItem("sessionEndingAlert-nanoedmo:time");
      localStorage.removeItem("sessionEndingAlert-nanoedmo:running");
      localStorage.removeItem("sessionClosing-nanoedmo:time");
      localStorage.removeItem("sessionClosing-nanoedmo:running");

      sessionStorage.removeItem("pIAM_authorization_token");
      sessionStorage.removeItem("pIAM_refreshtoken_token");
      sessionStorage.removeItem("pIAM_id_token");
      sessionStorage.removeItem("pIAM");
      sessionStorage.clear();
      sessionStorage.setItem("initCall", true);
      window.location.href = logoutPath;
    }
    if (isError) {
      console.log("Oops! ", error);
      // Ask other tabs for session storage (this is ONLY to trigger event for ALL TAB)
      localStorage.setItem("setLogoutSession", "dealsSsp");
      localStorage.removeItem("setLogoutSession", "dealsSsp");
      localStorage.removeItem("refreshToken-nanoedmo:time");
      localStorage.removeItem("refreshToken-nanoedmo:running");
      localStorage.removeItem("sessionEndingAlert-nanoedmo:time");
      localStorage.removeItem("sessionEndingAlert-nanoedmo:running");
      localStorage.removeItem("sessionClosing-nanoedmo:time");
      localStorage.removeItem("sessionClosing-nanoedmo:running");

      sessionStorage.removeItem("pIAM_authorization_token");
      sessionStorage.removeItem("pIAM_refreshtoken_token");
      sessionStorage.removeItem("pIAM_id_token");
      sessionStorage.removeItem("pIAM");
      sessionStorage.clear();

      sessionStorage.setItem("initCall", true);
      window.location.href = logoutPath;
    }
  }, [revokeTokenResult]);

  return <></>;
};

export default Logout;
