import { useEffect, useState } from "react";
import {
  Paper,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  FormLabel,
  OutlinedInput,
  FormHelperText,
  InputAdornment,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
} from "@mui/material";

import "./Auth.scss";
import { emailPattern, passwordPattern, regApps } from "../../utils/constants";
import _ from "lodash";
import { _getDMTLink, _getESRALink, _getLoginUrl } from "../../envUrl";
import { useRegisterUserMutation } from "../../store/services/dtms/user";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/dtms/loading";
import { useRegisterEsraUserMutation } from "../../store/services/dtms/esraUser";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [registerUser, registerUserResult] = useRegisterUserMutation();
  const [registerEsraUser, registerEsraUserResult] =
    useRegisterEsraUserMutation();

  const [userDetails, setUserDetails] = useState({
    email: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    password: "",
    registrationToken: "",
  });
  const [error, setError] = useState({
    email: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [selectedApp, setSelectedApp] = useState(regApps[0]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const registrationToken = params.get("registrationToken");
    const email = params.get("email");
    setUserDetails({
      ...userDetails,
      email: email || "",
      registrationToken: registrationToken || "",
    });
  }, []);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = registerUserResult;
    if (isSuccess) {
      console.log("Yay! ", data);
      confirmAlert({
        title: "Success",
        message: "Your are successfully registered !",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              navigate("/");
            },
          },
        ],
      });
    }
    if (isError) {
      console.log("Oops! ", error);
      const errorMessage =
        error.data && error.data.error
          ? error.data.error.message
            ? error.data.error.message
            : "Registration failed"
          : "Registration failed";
      confirmAlert({
        title: "Error",
        message: errorMessage,
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
    dispatch(setLoading(isLoading));
  }, [registerUserResult]);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } =
      registerEsraUserResult;
    if (isSuccess && data.status === "SUCCESS") {
      console.log("Yay! ", data);
      confirmAlert({
        title: "Success",
        message: "Your are successfully registered !",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              const esraLink = _getESRALink();
              window.open(esraLink, "_self");
            },
          },
        ],
      });
    } else if (data !== undefined) {
      console.log("Yay! ", data);

      confirmAlert({
        title: "Error",
        message: data?.description ? data?.description : "Registration failed",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
    if (isError) {
      console.log("Oops! ", error);
      const errorMessage =
        error.data && error.data.error
          ? error.data.error.message
            ? error.data.error.message
            : "Registration failed"
          : "Registration failed";
      confirmAlert({
        title: "Error",
        message: errorMessage,
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
    dispatch(setLoading(isLoading));
  }, [registerEsraUserResult]);

  const handleUserDetailsChange = (e, key) => {
    const value = e.target.value;
    if (
      value.trim() !== "" ||
      (key === "email" && emailPattern.test(userDetails.email)) ||
      (key === "password" && passwordPattern.test(userDetails.password))
    ) {
      setError({ ...error, [key]: "" });
    }
    setUserDetails({ ...userDetails, [key]: value });
  };

  const handleUserRegistration = () => {
    if (selectedApp === "DMT") {
      if (typeof window !== "undefined") {
        window.location.href = `${_getDMTLink()}${window.location.search}`;
      }
    } else {
      if (
        userDetails.email.trim() === "" ||
        userDetails.firstName.trim() === "" ||
        userDetails.lastName.trim() === "" ||
        userDetails.password.trim() === "" ||
        !emailPattern.test(userDetails.email) ||
        !passwordPattern.test(userDetails.password)
      ) {
        setError({
          ...error,
          email:
            userDetails.email.trim() === ""
              ? "Please enter your email id"
              : !emailPattern.test(userDetails.email)
              ? "Please enter a valid email id"
              : "",
          firstName:
            userDetails.firstName.trim() === ""
              ? "Please enter your first name"
              : "",
          lastName:
            userDetails.lastName.trim() === ""
              ? "Please enter your last name"
              : "",
          password:
            userDetails.password.trim() === ""
              ? "Please enter a password"
              : !passwordPattern.test(userDetails.password)
              ? `Please enter a password that contains atleast one uppercase and one lowercase character, one digit, and one special character out of "@", "$", "!", "%", "*", "?", and "&".`
              : "",
        });
      } else if (
        _.isEmpty(userDetails.registrationToken) ||
        userDetails.registrationToken === ""
      ) {
        confirmAlert({
          title: "Error",
          message: "Registration Token not found",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
      } else {
        if (selectedApp === "DTMS") {
          registerUser(userDetails);
        } else {
          registerEsraUser(userDetails);
        }
      }
    }
  };
  return (
    <div className="registration-page">
      <Paper elevation={3} className="registration-page-card">
        <Card className="registration-page-container">
          <CardContent>
            <div className="registration-page-form">
              <Typography className="form-header">Register</Typography>
              <div className="form-element">
                <FormLabel className="form-text">Application</FormLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedApp}
                  onChange={(e) => setSelectedApp(e.target.value)}
                >
                  {regApps.map((app) => (
                    <MenuItem value={app}>{app}</MenuItem>
                  ))}
                </Select>
              </div>
              {selectedApp !== "DMT" && (
                <>
                  <div className="form-username-element">
                    <div className="form-element">
                      <FormLabel className="form-text" required>
                        First Name
                      </FormLabel>
                      <TextField
                        autoComplete="off"
                        id="firstName"
                        variant="outlined"
                        value={userDetails.firstName}
                        onChange={(e) =>
                          handleUserDetailsChange(e, "firstName")
                        }
                        size="small"
                      />
                      <FormHelperText>{error.firstName}</FormHelperText>
                    </div>
                    <div className="form-element">
                      <FormLabel className="form-text" required>
                        Last Name
                      </FormLabel>
                      <TextField
                        autoComplete="off"
                        id="lastName"
                        variant="outlined"
                        value={userDetails.lastName}
                        onChange={(e) => handleUserDetailsChange(e, "lastName")}
                        size="small"
                      />
                      <FormHelperText>{error.lastName}</FormHelperText>
                    </div>
                  </div>
                  <div className="form-element">
                    <FormLabel className="form-text" required>
                      Email
                    </FormLabel>
                    <TextField
                      autoComplete="off"
                      id="email"
                      variant="outlined"
                      value={userDetails.email}
                      onChange={(e) => handleUserDetailsChange(e, "email")}
                      size="small"
                    />
                    <FormHelperText>{error.email}</FormHelperText>
                  </div>
                  <div className="form-element">
                    <FormLabel className="form-text">Phone Number</FormLabel>
                    <TextField
                      autoComplete="off"
                      id="mobileNumber"
                      variant="outlined"
                      value={userDetails.mobileNumber}
                      onChange={(e) =>
                        handleUserDetailsChange(e, "mobileNumber")
                      }
                      size="small"
                    />
                    <FormHelperText>{error.mobileNumber}</FormHelperText>
                  </div>
                  <div className="form-element">
                    <FormLabel className="form-text" required>
                      Password
                    </FormLabel>
                    <OutlinedInput
                      autoComplete="off"
                      id="password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowPassword((show) => !show);
                            }}
                            onMouseDown={(e) => {
                              e.preventDefault();
                            }}
                            edge="end"
                          >
                            {showPassword ? (
                              <span className="Appkit4-icon icon-view-off-outline ap-font-20" />
                            ) : (
                              <span className="Appkit4-icon icon-view-fill ap-font-20" />
                            )}
                          </IconButton>
                          <Tooltip
                            title={
                              <ul
                                style={{
                                  fontSize: "12px",
                                  lineHeight: "14px",
                                  fontWeight: 400,
                                }}
                              >
                                <li>Password must have 8 to 20 characters.</li>
                                <li>
                                  Password must have atleast one uppercase
                                  character.
                                </li>
                                <li>
                                  Password must have atleast one lowercase
                                  character.
                                </li>
                                <li>Password must have atleast one number.</li>
                                <li>
                                  Password must have atleast one special
                                  character out of "@", "$", "!", "%", "*", "?",
                                  and "&".
                                </li>
                              </ul>
                            }
                            placement="right"
                          >
                            <IconButton
                              style={{ marginLeft: "10px" }}
                              aria-label="toggle password validation visibility"
                              edge="end"
                            >
                              <span className="Appkit4-icon icon-help-question-fill ap-font-16" />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      }
                      value={userDetails.password}
                      onChange={(e) => handleUserDetailsChange(e, "password")}
                      size="small"
                    />
                    <FormHelperText>{error.password}</FormHelperText>
                  </div>
                </>
              )}
              <div className="form-element btn">
                <Button
                  variant="contained"
                  className="register-btn"
                  onClick={handleUserRegistration}
                >
                  Register
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      </Paper>
    </div>
  );
};

export default Register;
