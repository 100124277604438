import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { IconButton, Tooltip, Typography } from "@mui/material";
import "./TprlaProject.scss";
import { tprlaProjectRoles } from "../tprlaUtils";
import { useNavigate, useParams } from "react-router-dom";
import TprlaProjectFiles from "./project-files/TprlaProjectFiles";
import TprlaRoleAssign from "./project-role/TprlaRoleAssign";
import { useSelector } from "react-redux";
import { _getLoggedInUserDetails } from "../../../envUrl";
import _ from "lodash";

const TprlaProject = ({ userRole, getAllUsers }) => {
  const navigate = useNavigate();
  const params = useParams();

  const [activeIndex, setActiveIndex] = useState(0);
  const [projectName, setProjectName] = useState("");
  const { userRoleTPA } = useSelector((state) => state.user);

  useEffect(() => {
    getAllUsers(params.projectId);
  }, [params.projectId]);

  const handleChange = (event, newValue) => {
    setActiveIndex(newValue);
  };

  const getProjectName = (pname) => {
    setProjectName(pname);
  };

  const goToDashboard = () => {
    navigate("/tprla");
  };

  return (
    <div className="workspace-details">
      <Box className="tprla-project-header">
        <Tooltip title="Back to dashboard">
          <IconButton onClick={goToDashboard}>
            <span className="Appkit4-icon icon-left-chevron-fill ap-font-24" />
          </IconButton>
        </Tooltip>
        <Typography variant="h5" className="tprla-project-header__text">
          {projectName}
        </Typography>
      </Box>

      <TabContext value={activeIndex}>
        <Box>
          <TabList
            onChange={handleChange}
            sx={{
              borderBottom: "1px solid #d5d5d5",
            }}
          >
            <Tab label="File List" value={0} />
            <Tab label="Role Assignment" value={1} />
          </TabList>
          <TabPanel value={0} className="tab-panel-padding-dashboard">
            <TprlaProjectFiles
              userRole={userRole}
              getProjectName={getProjectName}
              userType={userRoleTPA}
            />
          </TabPanel>
          <TabPanel value={1} className="tab-panel-padding">
            <TprlaRoleAssign userType={userRoleTPA} />
          </TabPanel>
        </Box>
      </TabContext>
    </div>
  );
};

export default TprlaProject;
