import React, { useEffect, useState } from "react";
import { Avatar, IconButton, Tooltip, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { timestampToDate } from "../../../../utils/utilFns";
import _ from "lodash";
import { _getLoggedInUserDetails } from "../../../../envUrl";
import { useParams } from "react-router-dom";
import DeleteCommentModal from "./DeleteCommentModal";
import Mentions from "../../Mentions/Mentions";

const ReplyView = ({
  comment,
  disableComment,
  editComment = () => {},
  deleteComment = () => {},
  sharedWith = [],
}) => {
  const [content, setContent] = useState("");
  const [isEditActive, setIsEditActive] = useState(false);
  const [deleteReplyModal, setDeleteReplyModal] = useState({
    open: false,
    commentId: null,
    comment: "",
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [mentions, setMentions] = useState([]);
  const user = _getLoggedInUserDetails();
  const params = useParams();

  useEffect(() => {
    const clonedComment = _.cloneDeep(comment.comment);
    setContent(clonedComment);
  }, [comment.comment, isEditActive]);

  useEffect(() => {
    if (
      content &&
      comment.comment &&
      content.trim() === comment.comment.trim()
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [content, comment.comment]);

  const handleReplyEdit = () => {
    if (content === "") {
      console.log("error");
      return;
    }
    const payload = {
      projectId: params.projectId,
      releaseId: params.fileId,
      comments: [
        {
          id: comment.id,
          comment: content,
          commenterId: user.id,
          commenterName: `${user.firstName} ${user.lastName}`,
          commenterEmail: user.email,
          replyList: [],
          replyOf: comment.replyOf,
          commentTime: comment.commentTime,
          mentionedUserList: mentions,
        },
      ],
    };
    editComment(payload);
    setContent("");
    setIsEditActive(false);
  };

  const openDeleteModal = () => {
    setDeleteReplyModal({
      ...deleteReplyModal,
      open: true,
      commentId: comment.id,
      comment: comment.comment,
    });
  };

  const closeDeleteModal = () => {
    setDeleteReplyModal({
      ...deleteReplyModal,
      open: false,
    });
  };

  const onDeleteReply = () => {
    deleteComment({
      projectId: params.projectId,
      releaseId: params.fileId,
      commentId: comment.id,
    });
    setDeleteReplyModal({
      open: false,
      commentId: null,
    });
    setIsEditActive(false);
  };

  const onMention = (e) => {
    const i = mentions.findIndex((m) => m === e);
    if (i === -1) {
      setMentions([...mentions, e]);
    }
  };

  return (
    <div className="replies-body">
      <div className="reply-header">
        <Avatar sx={{ width: 27, height: 27 }}>{`${comment.commenterName
          .split(" ")[0]
          .slice(0, 1)}${comment.commenterName
          .split(" ")[1]
          .slice(0, 1)}`}</Avatar>

        <Typography className="name">{comment.commenterName}</Typography>
        <FiberManualRecordIcon
          sx={{ width: "0.2em", height: "0.2em", fill: "#9b9a9c" }}
        />
        <Typography className="date">
          {timestampToDate(comment.commentTime)}
        </Typography>
        {comment.isCommentEdited && (
          <>
            <FiberManualRecordIcon
              sx={{ width: "0.2em", height: "0.2em", fill: "#9b9a9c" }}
            />
            <p className="edited">Edited</p>
          </>
        )}

        {comment.commenterEmail === user.email && !disableComment && (
          <div className="btn-grp">
            {!isEditActive ? (
              <Tooltip title="Edit Reply">
                <IconButton
                  size="small"
                  sx={{ fill: "#000" }}
                  id={comment.id}
                  onClick={() => setIsEditActive(true)}
                >
                  <span className="Appkit4-icon icon-pencil-fill ap-font-16 icon-grey-dark" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Cancel Edit">
                <IconButton
                  size="small"
                  sx={{ fill: "#000" }}
                  id={comment.id}
                  onClick={() => setIsEditActive(false)}
                >
                  <span className="Appkit4-icon icon-close-outline ap-font-16 icon-grey-dark" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Delete Reply">
              <IconButton
                size="small"
                onClick={openDeleteModal}
                sx={{ fill: "#000" }}
              >
                <span className="Appkit4-icon icon-delete-outline ap-font-16 icon-grey-dark" />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
      <div
        className={`${
          isEditActive ? "comment-reply-view edit" : "comment-reply-view"
        }`}
      >
        <Mentions
          data={sharedWith}
          text={content}
          onChange={(e) => setContent(e.target.value)}
          onAdd={onMention}
          isDisabled={!isEditActive}
          className="reply-content"
          style={{
            "&multiLine": {
              control: {
                fontSize: "0.8rem !important",
              },
            },
          }}
        />
        {isEditActive && (
          <Tooltip title="Send">
            <IconButton
              size="small"
              sx={{ height: "30px" }}
              onClick={handleReplyEdit}
              disabled={isDisabled}
            >
              <span className="Appkit4-icon icon-right-chevron-outline ap-font-16" />
            </IconButton>
          </Tooltip>
        )}
      </div>

      <DeleteCommentModal
        open={deleteReplyModal.open}
        onClose={closeDeleteModal}
        commentId={deleteReplyModal.commentId}
        comment={deleteReplyModal.comment}
        onDeleteComment={onDeleteReply}
        type="reply"
      />
    </div>
  );
};

export default ReplyView;
