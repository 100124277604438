import {
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import IconMaker from "../shared/Icon/IconMaker";
import "./Home.scss";
import { useSelector } from "react-redux";
import _ from "lodash";
import { dtms } from "../../utils/constants";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const Home = () => {
  const modules = useSelector((state) => state.module.moduleDetails);

  return (
    <div className="dtms-admin-home">
      <Grid container spacing={2}>
        {/* {!_.isEmpty(modules) &&
          modules?.length > 0 &&
          modules
            .filter((module) => module.name !== dtms)
            .map((module) => ( */}
        <Grid item xs={12} md={4}>
          <Card className="module-cards">
            <CardContent className="home-card-flex">
              <div className="card-header-flex">
                <IconMaker text="TPRLA" />
                <div className="name-role-flex">
                  <Typography className="module-name">
                    Third-Party Reliance Letter Approval (TPRLA)
                  </Typography>
                  <div>
                    <Typography className="text key">Role:</Typography>
                    <Typography className="text value">Admin</Typography>
                  </div>
                </div>
              </div>
              <div>
                <Typography className="text key">Description:</Typography>
                <Typography className="text value">
                  This module helps auditors to make the reliance letter
                  available to their clients and third-party in a few simple
                  steps. Auditors and third-party users can sign and submit the
                  reliance letter in this portal. After validation the report
                  can be made available by the auditors.
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>
        {/* ))} */}

        {/* <Grid item xs={12} md={4}>
          <Card className="module-cards">
            <CardContent className="home-card-flex">
              <div className="card-header-flex">
                <IconMaker text="Education" />
                <div className="name-role-flex">
                  <Typography className="module-name">Education</Typography>
                  <div>
                    <Typography className="text key">Role:</Typography>
                    <Typography className="text value">Member</Typography>
                  </div>
                </div>
              </div>
              <div>
                <Typography className="text key">Description:</Typography>
                <Typography className="text value">
                  This is a test non-restricted module created for testing
                  purpose. There is no content in this module. It is just an
                  example that DTMS can cater such type of requirements.
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className="module-cards">
            <CardContent className="home-card-flex">
              <div className="card-header-flex">
                <IconMaker text="Media" />
                <div className="name-role-flex">
                  <Typography className="module-name">Media</Typography>
                  <div>
                    <Typography className="text key">Role:</Typography>
                    <Typography className="text value">Member</Typography>
                  </div>
                </div>
              </div>
              <div>
                <Typography className="text key">Description:</Typography>
                <Typography className="text value">
                  This is a test non-restricted module created for testing
                  purpose. There is no content in this module. It is just an
                  example that DTMS can cater such type of requirements.
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Grid> */}

        {/*<Grid item xs={4}>
          <Item>Media</Item>
        </Grid>
        <Grid item xs={4}>
          <Item>Test</Item>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default Home;
