import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  _getAuthHeaders,
  _getClientID,
  _getDTMSProxy,
  _getESRALink,
  _getEncoderKey,
  _getLoggedInUserDetails,
} from "../../../envUrl";
import { inviteUserConstants } from "../../../utils/constants";
import api from "../../api";
import { hmacEncode } from "../../../utils/utilFns";

export const esraUserApi = createApi({
  reducerPath: "dtmsUserApi",
  baseQuery: fetchBaseQuery({
    baseUrl: _getESRALink(),
  }),
  endpoints: (builder) => ({
    registerEsraUser: builder.mutation({
      query: (userDetails) => {
        const key = _getEncoderKey();
        const payload = {
          email: userDetails.email,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          mobileNumber: userDetails.mobileNumber,
          password: userDetails.password,
          product: _getClientID(),
          registrationToken: userDetails.registrationToken,
          preferredLanguage: "en",
        };

        return {
          url: api.registerEsraUser,
          method: "POST",
          body: payload,
        };
      },
    }),
  }),
});

export const { useRegisterEsraUserMutation } = esraUserApi;
