// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tpra-table .MuiTable-root td {
  color: #2d2d2d !important;
  box-sizing: border-box;
  font-size: 0.85rem !important;
  font-family: inherit !important;
  font-weight: inherit;
}
.tpra-table .MuiTableCell-root.MuiTableCell-head {
  font-size: 0.875rem !important;
}
.tpra-table .MuiInputBase-root {
  font-family: inherit !important;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/Table/Table.scss"],"names":[],"mappings":"AAGE;EACE,yBAAA;EACA,sBAAA;EACA,6BAAA;EACA,+BAAA;EACA,oBAAA;AAFJ;AAKE;EACE,8BAAA;AAHJ;AAME;EACE,+BAAA;AAJJ","sourcesContent":["@import \"../../../variables.scss\";\n\n.tpra-table {\n  .MuiTable-root td {\n    color: $charcoal !important;\n    box-sizing: border-box;\n    font-size: 0.85rem !important;\n    font-family: inherit !important;\n    font-weight: inherit;\n  }\n\n  .MuiTableCell-root.MuiTableCell-head {\n    font-size: 0.875rem !important;\n  }\n\n  .MuiInputBase-root {\n    font-family: inherit !important;\n  }\n\n  .MuiInputAdornment-root {\n    .MuiInputAdornment-positionEnd {\n    }\n  }\n\n  // .MuiToolbar-root {\n  //   flex-direction: row-reverse;\n  //   div[class^=\"MTableToolbar-spacer\"] {\n  //     display: none;\n  //   }\n  // }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
