import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  _getAuthHeaders,
  _getDTMSProxy,
  _getLoggedInUserDetails,
} from "../../../envUrl";
import api from "../../api";

export const commentApi = createApi({
  reducerPath: "commentApi",
  baseQuery: fetchBaseQuery({ baseUrl: _getDTMSProxy() }),
  endpoints: (builder) => ({
    postComment: builder.mutation({
      query: (payload) => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.comment,
          method: "POST",
          body: payload,
          params: { action: "save" },
          headers: authHeaders,
        };
      },
    }),
    getAllComments: builder.mutation({
      query: (payload) => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.comment,
          method: "GET",
          params: {
            projectId: payload.projectId,
            releaseId: payload.releaseId,
          },
          headers: authHeaders,
        };
      },
    }),
    editComment: builder.mutation({
      query: (payload) => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.comment,
          method: "POST",
          body: payload,
          params: { action: "edit" },
          headers: authHeaders,
        };
      },
    }),
    deleteComment: builder.mutation({
      query: (payload) => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.comment,
          method: "DELETE",
          params: payload,
          headers: authHeaders,
        };
      },
    }),
    agreeOnComment: builder.mutation({
      query: (payload) => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.comment,
          method: "POST",
          body: payload,
          params: { action: "agree" },
          headers: authHeaders,
        };
      },
    }),
    replyComment: builder.mutation({
      query: (payload) => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.comment,
          method: "POST",
          body: payload,
          params: { action: "save" },
          headers: authHeaders,
        };
      },
    }),
  }),
});

export const {
  usePostCommentMutation,
  useGetAllCommentsMutation,
  useEditCommentMutation,
  useDeleteCommentMutation,
  useAgreeOnCommentMutation,
  useReplyCommentMutation,
} = commentApi;
