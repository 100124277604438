import { useState } from "react";
import ModalDialog from "../../../../components/shared/Modal/Modal";
import { _getAuthHeaders, _getDTMSProxy } from "../../../../envUrl";
import { useDispatch } from "react-redux";
import { downloadFile } from "../../../../store/slices/tprla/fileSlice";

const Download = (props) => {
  const dispatch = useDispatch();

  let { open = false, onClose = () => {}, file } = props;

  const downloadModalOptions = {
    title: "Download",
    option: {
      open,
      maxWidth: "sm",
      actionButtonName: "Yes",
      twoActionButtonRequired: true,
      actionSecondaryButtonName: "No",
    },
  };

  const [modalOptions, setModalOptions] = useState(downloadModalOptions);

  const handleClose = () => {
    setModalOptions({
      ...modalOptions,
      option: { ...modalOptions.option, open: false },
    });
    onClose();
  };

  const onDownload = () => {
    onClose();
    dispatch(downloadFile(file));
    handleClose();
  };

  return (
    <ModalDialog
      {...downloadModalOptions}
      onClose={handleClose}
      onSave={onDownload}
    >
      Do you want to download <strong>{file.fileName}</strong> file ?
    </ModalDialog>
  );
};

export default Download;
