// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tpra-footer {
  padding: 1rem;
  z-index: 1;
}
.tpra-footer-text {
  font-size: 0.6875rem;
  color: #7d7d7d;
  letter-spacing: 0.05em;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,UAAA;AACF;AAAE;EACE,oBAAA;EACA,cAAA;EACA,sBAAA;EACA,kBAAA;AAEJ","sourcesContent":[".tpra-footer {\n  padding: 1rem;\n  z-index: 1;\n  &-text {\n    font-size: 0.6875rem;\n    color: #7d7d7d;\n    letter-spacing: 0.05em;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
