import { forwardRef } from "react";
import Table from "../../../../components/shared/Table/Table";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { fileTypesConstants, tprlaProjectRoles } from "../../tprlaUtils";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import {
  _getLoggedInUserDetails,
  _getUserTPAProjRole,
} from "../../../../envUrl";
import _ from "lodash";
import "./ProjectFiles.scss";

export const TprlaProjectFileList = ({
  data,
  onView,
  onDownload,
  onShare,
  onDelete,
  userType,
}) => {
  const navigate = useNavigate();
  const params = useParams();

  const user = _getLoggedInUserDetails();

  const table = {
    title: "",
    columns: [
      { title: "File", field: "fileName", cellStyle: { width: "35%" } },
      {
        title: "Uploaded by",
        field: "createdBy",
        cellStyle: { width: "25%" },
      },
      {
        title: "Upload date",
        field: "createdTime",
        render: (row) => (
          <span>{moment(row.createdTime).format("DD-MM-YYYY")}</span>
        ),
        cellStyle: { width: "15%" },
      },
      {
        title: "Type",
        field: "type",
        cellStyle: { width: "25%" },
      },
    ],
    actions: [
      {
        icon: forwardRef((props, ref) => (
          <span
            className="Appkit4-icon icon-upload-light-fill ap-font-20"
            {...props}
            ref={ref}
          />
        )),
        tooltip: "Upload",
        hidden: userType === tprlaProjectRoles.client,
        onClick: () => navigate(`/tprla/${params.projectId}/uploadfile`),
        isFreeAction: true,
      },
      (rowData) => ({
        icon: forwardRef((props, ref) => (
          <span
            className="Appkit4-icon icon-document-file-fill ap-font-20"
            {...props}
            ref={ref}
          />
        )),
        tooltip:
          rowData.type === fileTypesConstants.release &&
          userType !== tprlaProjectRoles.client &&
          rowData.sharedWith.includes(user.email)
            ? "View / Comment / E-Sign"
            : rowData.type === fileTypesConstants.release &&
              userType === tprlaProjectRoles.client
            ? "View / Comment"
            : "View",
        onClick: (event, data) => {
          onView(rowData);
        },
        cellStyle: {
          textAlign: "center",
          justifyContent: "center",
        },
      }),
      (rowData) => ({
        icon: forwardRef((props, ref) => (
          <span
            className="Appkit4-icon icon-download-light-fill ap-font-20"
            {...props}
            ref={ref}
          />
        )),
        tooltip: "Download",
        onClick: (event, data) => {
          onDownload(rowData);
        },
        cellStyle: {
          textAlign: "center",
          justifyContent: "center",
        },
      }),
      (rowData) => ({
        icon: forwardRef((props, ref) => (
          <ShareOutlinedIcon {...props} ref={ref} />
        )),
        tooltip: !rowData.sharedWith.includes(user.email)
          ? "You are not authorised to share this file"
          : "Share",
        hidden: false,
        onClick: (event, data) => {
          onShare(data);
        },
        cellStyle: {
          textAlign: "center",
          justifyContent: "center",
        },
        disabled:
          !rowData.sharedWith.includes(user.email) ||
          rowData.type === fileTypesConstants.signedRelease,
      }),
      (rowData) => ({
        icon: forwardRef((props, ref) => (
          <span
            className="Appkit4-icon icon-delete-fill ap-font-20"
            {...props}
            ref={ref}
          />
        )),
        tooltip: !rowData.sharedWith.includes(user.email)
          ? "You are not authorised to delete this file"
          : rowData.type === fileTypesConstants.signedRelease
          ? "Signed file cannot be deleted"
          : "Delete",
        onClick: (event, data) => {
          onDelete(data);
        },
        cellStyle: {
          textAlign: "center",
          justifyContent: "center",
        },
        hidden: userType !== tprlaProjectRoles.auditor,
        disabled:
          !rowData.sharedWith.includes(user.email) ||
          rowData.type === fileTypesConstants.signedRelease,
      }),
    ],
  };

  return (
    <Table
      {...table}
      data={data}
      options={{
        actionsColumnIndex: -1,
        toolbarButtonAlignment: "left", // here is the option to change toolbar buttons' alignment
      }}
    />
  );
};
