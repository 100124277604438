import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  _getAuthHeaders,
  _getClientID,
  _getDTMSProxy,
  _getEncoderKey,
  _getLoggedInUserDetails,
} from "../../../envUrl";
import api from "../../api";
import _ from "lodash";
import { hmacEncode } from "../../../utils/utilFns";
import { inviteUserConstants } from "../../../utils/constants";

export const projectApi = createApi({
  reducerPath: "projectApi",
  baseQuery: fetchBaseQuery({ baseUrl: _getDTMSProxy() }),
  endpoints: (builder) => ({
    getProjects: builder.mutation({
      query: ({ searchText, currentPage }) => {
        const authHeaders = _getAuthHeaders();
        return {
          url: api.getProjects,
          method: "POST",
          body: {
            criteria: searchText,
            currentPage: currentPage,
            endDate: "",
            pageSize: 9,
            projectId: "",
            sortColumn: "",
            sortOrder: "",
            startDate: "",
          },
          headers: authHeaders,
        };
      },
    }),
    createProject: builder.mutation({
      query: (payload) => {
        const loggedInUserDetails = _getLoggedInUserDetails();
        const authHeaders = _getAuthHeaders();
        const key = _getEncoderKey();
        var clonedPayload = _.cloneDeep(payload);
        var userObj = {};

        clonedPayload.users.forEach((user) => {
          user.requestingUserEmail = loggedInUserDetails.email;
          user.product = _getClientID();
          user.emailLanguage = inviteUserConstants.emailLanguage;
          user.state = inviteUserConstants.state;
          userObj[user.email] = hmacEncode(user, key);
        });

        clonedPayload.authKey = userObj;
        return {
          url: api.createProject,
          method: "POST",
          body: clonedPayload,
          headers: authHeaders,
        };
      },
    }),
    deleteProject: builder.mutation({
      query: (id) => {
        const authHeaders = _getAuthHeaders();
        return {
          url: api.deleteProject,
          method: "DELETE",
          headers: authHeaders,
          params: { projectId: id },
        };
      },
    }),
  }),
});

export const {
  useGetProjectsMutation,
  useCreateProjectMutation,
  useDeleteProjectMutation,
} = projectApi;
