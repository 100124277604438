import "./Auth.scss";

const Unauthorised = () => {
  return (
    <div className="unauthorised">
      <h3>
        You do not have access to this portal. Please contact the administrator
        to get access privileges. In order to request access, kindly fill and
        submit
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSeKAGBJ9NZavbs08tBfUskqXKEprMuvhxzF8ujvyjNE_epT6w/viewform"
          target="_blank"
          className="click-here"
        >
          {" "}
          <strong>form</strong>
        </a>
        .
      </h3>
    </div>
  );
};

export default Unauthorised;
