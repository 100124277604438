import { useEffect, useState } from "react";
import useDidMountEffect from "../CustomHooks/didMountEffect";

function useLocalStorage(key, initialValue, parseValue = (v) => v) {
  const [item, setValue] = useState(() => {
    const value = parseValue(localStorage.getItem(key)) || initialValue;
    localStorage.setItem(key, value);
    return value;
  });

  const setItem = (newValue) => {
    setValue(newValue);
    window.localStorage.setItem(key, newValue);
  };

  return [item, setItem];
}

export function useSetPersistentTimeout(name, fn, timeout) {
  const [callfn, setCallfn] = useState(false);
  const [lapse, setLapse] = useLocalStorage(`${name}-nanoedmo:time`, 0, (v) =>
    Number(v)
  );
  const [running, setRunning] = useLocalStorage(
    `${name}-nanoedmo:running`,
    false,
    (string) => string === "true"
  );
  const loggedInAt = sessionStorage.getItem("time");

  useEffect(() => {
    const startTime = Date.now() - lapse;

    // round to the nearest second
    const timer = setInterval(() => {
      if (running) {
        console.log(
          "Lapse: ",
          loggedInAt,
          Date.now(),
          Date.now() - loggedInAt,
          timeout,
          Date.now() - loggedInAt >= timeout
        );
        setLapse(Date.now() - loggedInAt);
      }
      // round to the nearest second
    }, 1000 * 60);

    if (lapse >= timeout) {
      setCallfn(true);
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  }, [running, lapse, setLapse]);

  useDidMountEffect(() => {
    fn();
  }, [callfn]);

  const startTimer = () => {
    setRunning(true);
  };

  return [startTimer];
}
