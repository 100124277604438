import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  _getClientID,
  _getDTMSProxy,
  _getAuthHeaders,
  _getLoggedInUserDetails,
  _getEncoderKey,
} from "../../../envUrl";
import { inviteUserConstants } from "../../../utils/constants";
import api from "../../api";
import { hmacEncode } from "../../../utils/utilFns";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: _getDTMSProxy(),
  }),
  endpoints: (builder) => ({
    deleteUser: builder.mutation({
      query: (payload) => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.deleteTprlaUser,
          method: "DELETE",
          body: payload.payload,
          headers: authHeaders,
          params: {
            projectId: payload.projectId,
            userId: payload.userId,
          },
        };
      },
    }),
    getAllUsers: builder.mutation({
      query: (projectId) => {
        const authHeaders = _getAuthHeaders();
        const user = _getLoggedInUserDetails();
        return {
          url: api.getAllUsers,
          method: "GET",
          params: { moduleName: "TPRLA", projectId: projectId },
          headers: { ...authHeaders, email: user.email },
        };
      },
    }),
    editUser: builder.mutation({
      query: (data) => {
        const authHeaders = _getAuthHeaders();
        const loggedInUserDetails = _getLoggedInUserDetails();
        const payload = {
          orgId: loggedInUserDetails.orgId,
          email: data.user.email,
          requestingUserEmail: loggedInUserDetails.email,
          product: _getClientID(),
          ...inviteUserConstants,
          modules: data.user.modules,
          role: data.user.role,
        };
        return {
          url: api.editTprlaUserRoles,
          method: "POST",
          body: payload,
          headers: authHeaders,
          params: { projectId: data.projectId },
        };
      },
    }),
    createInvite: builder.mutation({
      query: (data) => {
        const loggedInUserDetails = _getLoggedInUserDetails();
        const authHeaders = _getAuthHeaders();
        const key = _getEncoderKey();
        const payload = {
          orgId: loggedInUserDetails.orgId,
          email: data.payload.email,
          requestingUserEmail: loggedInUserDetails.email,
          product: _getClientID(),
          ...inviteUserConstants,
          modules: data.payload.modules,
          role: data.payload.role,
        };
        const encodedPayload = hmacEncode(payload, key);
        return {
          url: api.createTprlaUser,
          method: "POST",
          body: payload,
          headers: { ...authHeaders, AuthKey: encodedPayload },
          params: { projectId: data.projectId },
        };
      },
    }),
  }),
});

export const {
  useDeleteUserMutation,
  useGetAllUsersMutation,
  useEditUserMutation,
  useCreateInviteMutation,
} = userApi;
