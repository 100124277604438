import { createSlice } from "@reduxjs/toolkit";

const tprlaMasterSlice = createSlice({
  name: "tprlaMaster",
  initialState: { tprlaProjectRoles: [], tprlaModuleRoles: [] },
  reducers: {
    setTprlaProjectRoles(state, action) {
      state.tprlaProjectRoles = action.payload;
    },
    setTprlaModuleRoles(state, action) {
      state.tprlaModuleRoles = action.payload;
    },
  },
});

export const { setTprlaModuleRoles, setTprlaProjectRoles } =
  tprlaMasterSlice.actions;
export default tprlaMasterSlice.reducer;
