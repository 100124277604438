import { configureStore } from "@reduxjs/toolkit";
import { filesApi } from "./services/tprla/files";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import fileReducer from "./slices/tprla/fileSlice";
import { authApi } from "./services/dtms/auth";
import userReducer from "./slices/tprla/userSlice";
import uploadReducer from "./slices/tprla/uploadSlice";
import loadingReducer from "./slices/dtms/loading";
import { userApi } from "./services/tprla/users";
import { moduleApi } from "./services/dtms/module";
import { dtmsUserApi } from "./services/dtms/user";
import moduleReducer from "./slices/dtms/module";
import { tprlaMasterApi } from "./services/tprla/masterData";
import tprlaMasterReducer from "./slices/tprla/masterSlice";
import { projectApi } from "./services/tprla/project";
import { commentApi } from "./services/tprla/comment";
import { esraUserApi } from "./services/dtms/esraUser";

export const store = configureStore({
  reducer: {
    [filesApi.reducerPath]: filesApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [dtmsUserApi.reducerPath]: dtmsUserApi.reducer,
    [esraUserApi.reducerPath]: esraUserApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [moduleApi.reducerPath]: moduleApi.reducer,
    [tprlaMasterApi.reducerPath]: tprlaMasterApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [commentApi.reducerPath]: commentApi.reducer,
    file: fileReducer,
    user: userReducer,
    upload: uploadReducer,
    loading: loadingReducer,
    module: moduleReducer,
    tprlaMaster: tprlaMasterReducer,
  },

  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare().concat(
      filesApi.middleware,
      userApi.middleware,
      dtmsUserApi.middleware,
      authApi.middleware,
      moduleApi.middleware,
      tprlaMasterApi.middleware,
      projectApi.middleware,
      commentApi.middleware,
      esraUserApi.middleware
    ),
});

setupListeners(store.dispatch);
