// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-bar {
  display: flex;
  justify-content: flex-end;
}
.search-bar .MuiInputBase-root {
  max-width: 248px;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/search-filter/SearchAndFilter.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;AACF;AACE;EACE,gBAAA;AACJ","sourcesContent":[".search-bar {\n  display: flex;\n  justify-content: flex-end;\n\n  .MuiInputBase-root {\n    max-width: 248px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
