// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workspace-details .tprla-project-header {
  display: flex;
  align-items: center;
}
.workspace-details .tprla-project-header .MuiIconButton-root {
  padding-left: 0 !important;
}
.workspace-details .tprla-project-header .MuiIconButton-root:hover {
  background-color: transparent !important;
}
.workspace-details .tab-panel-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.workspace-details .tab-panel-padding-dashboard {
  padding-left: 0px !important;
  padding-right: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/modules/tprla/tprla-project/TprlaProject.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,mBAAA;AAFJ;AAII;EACE,0BAAA;AAFN;AAIM;EACE,wCAAA;AAFR;AAME;EACE,4BAAA;EACA,6BAAA;AAJJ;AAKI;EACE,4BAAA;EACA,6BAAA;AAHN","sourcesContent":["@import \"../../../variables.scss\";\n\n.workspace-details {\n  .tprla-project-header {\n    display: flex;\n    align-items: center;\n\n    .MuiIconButton-root {\n      padding-left: 0 !important;\n\n      &:hover {\n        background-color: transparent !important;\n      }\n    }\n  }\n  .tab-panel-padding {\n    padding-left: 0px !important;\n    padding-right: 0px !important;\n    &-dashboard {\n      padding-left: 0px !important;\n      padding-right: 0px !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
