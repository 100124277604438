import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { _getDTMSProxy } from "../../../envUrl";
import api from "../../api";
import { _getAuthHeaders } from "../../../envUrl";
import { confirmAlert } from "react-confirm-alert";
import { trackPromise } from "react-promise-tracker";
import { requestConnectorWithInterceptor } from "../../services/restapi-controller";
const proxy = _getDTMSProxy();

export const onUploadFile = createAsyncThunk(
  "onUploadFile",
  async (payload) => {
    const authHeaders = _getAuthHeaders();
    var formData = new FormData();
    formData.append("file", payload.fileSys);
    const response = await trackPromise(
      requestConnectorWithInterceptor(
        "POST",
        proxy + api.uploadFile,
        {
          ...authHeaders,
          "Content-Type": "multipart/form-data",
        },
        payload.params,
        formData
      )
    );

    return response;
  }
);

const initialState = {
  fileDetails: {},
  isLoading: false,
  isError: false,
};

const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(onUploadFile.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(onUploadFile.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log("UPLOAD RESPONSE >>>>", action.payload);
        confirmAlert({
          title: "Uploaded Sucessfully",
          message: `${action.payload?.result.originalFileName} uploaded successfully`,
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
        state.fileDetails = action.payload.result;
        state.isError = false;
      })
      .addCase(onUploadFile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.log(action.payload);
        confirmAlert({
          title: "Error",
          message: `${
            action?.error?.message ? action.error.message : "File upload failed"
          }`,
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
      });
  },
});

export default uploadSlice.reducer;
