import { useState, useEffect, forwardRef } from "react";
import ModalDialog from "../../../components/shared/Modal/Modal";
import "./TprlaDashboard.scss";
import {
  Box,
  Checkbox,
  FormHelperText,
  FormLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { _getLoggedInUserDetails } from "../../../envUrl";
import { useGetAllUsersMutation } from "../../../store/services/tprla/users";
import { format } from "../../../utils/utilFns";
import { useCreateProjectMutation } from "../../../store/services/tprla/project";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/slices/dtms/loading";
import { tprlaProjectRoles } from "../tprlaUtils";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import Table from "../../../components/shared/Table/Table";
import AddEditUserModal from "./AddEditUserModal";

const AddProject = ({
  open = false,
  handleClose = () => {},
  refreshProjects,
}) => {
  const dispatch = useDispatch();
  const [projectName, setProjectName] = useState("");
  const [clientName, setClientName] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [users, setUsers] = useState([]);
  const [selectedUsersList, setSelectedUsersList] = useState();
  const [isEndDate, setIsEndDate] = useState(true);
  const [error, setError] = useState({
    projectNameError: "",
    clientNameError: "",
    startDateError: "",
    endDateError: "",
    userError: "",
  });
  const [table, setTable] = useState();
  const [addEditUserModal, setAddEditUserModal] = useState({
    open: false,
    userDetails: null,
  });

  const [getAllUsers, getAllUsersResult] = useGetAllUsersMutation();
  const [createProject, createProjectResult] = useCreateProjectMutation();

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (open) {
      const userDts = _.cloneDeep(_getLoggedInUserDetails());
      setSelectedUsersList([
        {
          ...userDts,
          modules: [
            {
              name: "TPRLA",
              role: { name: tprlaProjectRoles.auditor, isActive: "Y" },
            },
          ],
          isCreator: true,
        },
      ]);
    }
  }, [open]);

  useEffect(() => {
    const { isLoading, isFetching, isError, isSuccess, data, error } =
      getAllUsersResult;
    if (isSuccess) {
      const clonedRes = _.cloneDeep(data.result);
      setUsers(clonedRes);
    } else if (isError) {
      console.log("Oops!", error);
    }
  }, [getAllUsersResult]);

  useEffect(() => {
    const { isLoading, isFetching, isError, isSuccess, data, error } =
      createProjectResult;

    if (isSuccess) {
      handleCloseModal();
      confirmAlert({
        title: "Project Created",
        message: `${data.result.projectName} is created successfully`,
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              refreshProjects();
            },
          },
        ],
      });
    } else if (isError) {
      console.log("Oops! ", error);
      confirmAlert({
        title: "Error",
        message:
          error?.data?.error && error?.data?.error?.message
            ? error.data.error.message
            : "Unable to create project. Please try again later.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
    dispatch(setLoading(isLoading));
  }, [createProjectResult]);

  useEffect(() => {
    setTable({
      title: "",
      columns: [
        { title: "Email", field: "email", style: { width: "30%" } },
        {
          title: "Role",
          render: (data) => data.modules[0].role.name,
          style: { width: "20%" },
        },
      ],
      actions: [
        (rowData) => ({
          icon: forwardRef((props, ref) => (
            <span
              className="Appkit4-icon icon-pencil-fill ap-font-16"
              {...props}
              ref={ref}
            />
          )),
          tooltip: "Edit",
          disabled: rowData.isCreator,
          onClick: (event, data) => {
            setAddEditUserModal({
              ...addEditUserModal,
              open: true,
              userDetails: data,
            });
          },
          cellStyle: {
            textAlign: "center",
            justifyContent: "center",
          },
        }),
        (rowData) => ({
          icon: forwardRef((props, ref) => (
            <span
              className="Appkit4-icon icon-delete-fill ap-font-16"
              {...props}
              ref={ref}
            />
          )),
          tooltip: "Delete",
          disabled: rowData.isCreator,
          onClick: (event, data) => {
            var clonedSelectUserList = _.cloneDeep(selectedUsersList);
            clonedSelectUserList.splice(data.tableData.id, 1);
            setSelectedUsersList(clonedSelectUserList);
          },
          cellStyle: {
            textAlign: "center",
            justifyContent: "center",
          },
        }),
      ],
      options: {
        paging: false,
        toolbar: false,
        showTitle: false,
      },
    });
  }, [selectedUsersList]);

  const createProjectModalOptions = {
    title: "Create project",
    option: {
      open,
      maxWidth: "md",
      actionButtonName: "Create",
    },
  };

  const onProjectNameChange = (e) => {
    const name = e.target.value;
    if (name.trim() !== "") {
      setProjectName(name);
      setError({ ...error, projectNameError: "" });
    } else {
      setProjectName("");
      setError({ ...error, projectNameError: "Please enter a project name" });
    }
  };

  const onClientNameChange = (e) => {
    const name = e.target.value;
    if (name.trim() !== "") {
      setClientName(name);
      setError({ ...error, clientNameError: "" });
    } else {
      setClientName("");
      setError({ ...error, clientNameError: "Please enter a client name" });
    }
  };

  const onStartDateChange = (newValue) => {
    if (Object.keys(newValue).length !== 0) {
      setStartDate(newValue.$d);
      setError({ ...error, startDateError: "" });
      setIsEndDate(false);
    } else {
      setStartDate("");
      setError({ ...error, startDateError: "Please enter a start date" });
    }
  };

  const onEndDateChange = (newValue) => {
    if (Object.keys(newValue).length !== 0) {
      setEndDate(newValue.$d);
      setError({ ...error, endDateError: "" });
    } else {
      setEndDate("");
      setError({ ...error, endDateError: "Please enter an end date" });
    }
  };

  const onCreateProject = async () => {
    let isProjectNameValid = false;
    let isClientNameValid = false;
    let isStartDateValid = false;
    let isEndDateValid = false;

    const tempError = {};

    if (projectName.trim() !== "") {
      tempError.projectNameError = "";
      isProjectNameValid = true;
    } else {
      tempError.projectNameError = "Please enter a project name";
      isProjectNameValid = false;
    }

    if (clientName.trim() !== "") {
      tempError.clientNameError = "";
      isClientNameValid = true;
    } else {
      tempError.clientNameError = "Please enter a client name";
      isClientNameValid = false;
    }

    if (startDate !== undefined) {
      tempError.startDateError = "";
      isStartDateValid = true;
    } else {
      tempError.startDateError = "Please select a start date";
      isStartDateValid = false;
    }

    if (endDate !== undefined) {
      tempError.endDateError = "";
      isEndDateValid = true;
    } else {
      tempError.endDateError = "Please select an end date";
      isEndDateValid = false;
    }

    setError(tempError);

    if (
      isProjectNameValid &&
      isClientNameValid &&
      isStartDateValid &&
      isEndDateValid
    ) {
      const startDateResult = format(new Date(startDate));
      const endDateResult = format(new Date(endDate));
      const payload = {
        projectName,
        clientName,
        startDate: startDateResult,
        endDate: endDateResult,
        users: selectedUsersList,
      };
      createProject(payload);
    }
  };

  const handleCloseModal = () => {
    setProjectName("");
    setClientName("");
    setStartDate(undefined);
    setEndDate(undefined);
    setIsEndDate(true);
    handleClose();
    setError({
      projectNameError: "",
      clientNameError: "",
      startDateError: "",
      endDateError: "",
      userError: "",
    });
  };

  const addEditUserModalClose = () => {
    setAddEditUserModal({
      ...addEditUserModal,
      open: false,
      userDetails: null,
    });
  };

  const addNewUsers = (newUsers) => {
    setSelectedUsersList([...selectedUsersList, ...newUsers]);
  };

  const editUserRole = (editedUser) => {
    const clonedSelectedUsers = _.cloneDeep(selectedUsersList);
    clonedSelectedUsers.forEach((user) => {
      if (user.email === editedUser.email) {
        user.modules[0].role.name = editedUser.modules[0].role.name;
      }
    });
    setSelectedUsersList(clonedSelectedUsers);
  };

  return (
    <>
      <ModalDialog
        {...createProjectModalOptions}
        onSave={onCreateProject}
        onClose={handleCloseModal}
      >
        <Box
          sx={{
            "& .MuiTextField-root": { mt: 1 },
            "& .MuiFormLabel-root:not(:first-of-type)": { mt: 1 },
            "&. MuiInputBase-root": { mt: 1 },
          }}
          className="create-project"
        >
          <FormLabel className="add-project-form-label">Project name</FormLabel>
          <TextField required onChange={onProjectNameChange} />
          <FormHelperText>{error.projectNameError}</FormHelperText>
          <FormLabel className="add-project-form-label">Client name</FormLabel>
          <TextField required onChange={onClientNameChange} />
          <FormHelperText>{error.clientNameError}</FormHelperText>
          <FormLabel className="add-project-form-label">Start date</FormLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={startDate}
              onChange={onStartDateChange}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
          <FormHelperText>{error.startDateError}</FormHelperText>
          <FormLabel className="add-project-form-label">End date</FormLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={endDate}
              onChange={onEndDateChange}
              format="DD/MM/YYYY"
              minDate={dayjs(startDate).add(1, "day")}
              disabled={isEndDate}
            />
          </LocalizationProvider>
          <FormHelperText>{error.endDateError}</FormHelperText>
          <div className="add-project-userlist">
            <FormLabel className="add-project-form-label">Users</FormLabel>
            <IconButton
              onClick={() =>
                setAddEditUserModal({ ...addEditUserModal, open: true })
              }
            >
              <AddIcon />
            </IconButton>
          </div>
          <Table {...table} data={selectedUsersList} />
          <FormHelperText>{error.userError}</FormHelperText>
        </Box>
      </ModalDialog>
      {!_.isEmpty(users) && users.length > 0 && (
        <AddEditUserModal
          open={addEditUserModal.open}
          users={users}
          closeModal={addEditUserModalClose}
          addNewUsers={addNewUsers}
          editUserRole={editUserRole}
          userDetails={addEditUserModal.userDetails}
        />
      )}
    </>
  );
};

export default AddProject;
