import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import { useState } from "react";
import { useEffect } from "react";
import { Card, Tooltip } from "@material-ui/core";
import _ from "lodash";
import "./StickyPanel.scss";

const drawerWidth = 450;

const StickyPanel = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      height: "600px",
    },
    openDrawerButton: {
      width: "48px",
      height: "48px",
      marginRight: props.anchor === "right" ? 0 : theme.spacing(2),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    closeDrawerButton: {
      position: "absolute",
      [props.anchor === "right" ? "left" : "right"]: 0,
    },
    buttonAbsolute: {
      position: "absolute",
      top: "101.5px",
      [props.anchor === "right" ? "right" : "left"]: "0px",
    },
    buttonFixed: {
      position: "fixed",
      top: "0px",
      left: "0px",
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: props.anchor === "right" ? 0 : drawerWidth,
      flexShrink: 0,
      zIndex: 0,
      height: "700px",
    },
    drawerPaper: {
      top: "105px",
      width: drawerWidth,
      position: "absolute",
      height: "calc(100% - 100px)",
      zIndex: 0,
    },
    drawerPaperFixed: {
      top: "0px",
      width: drawerWidth,
      position: "fixed",
      height: "calc(100% - 1px)",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      minHeight: "48px",
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),

      [props.anchor === "right" ? "marginRight" : "marginLeft"]:
        props.anchor === "right" ? drawerWidth : -drawerWidth + 48,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    paddingAdjusted: {
      width: "fit-content",
      height: "fit-content",
      padding: "5px",
    },
  }));
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [isPanelFixed, setIsPanelFixed] = useState();

  window.onscroll = function () {
    if (window.scrollY > 100) {
      setIsPanelFixed(true);
    } else {
      setIsPanelFixed(false);
    }
  };
  // useEffect(() => {
  //   console.log("Y: ", isPanelFixed);
  // }, [isPanelFixed]);

  const handleDrawerOpen = () => {
    setOpen(true);
    props.toggleDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    props.toggleDrawer(false);
  };

  useEffect(() => {
    setOpen(props.drawerStatus);
  }, [props.drawerStatus]);

  return (
    <div className={`${classes.root} sticky-panel-dtms`}>
      <CssBaseline />
      <Drawer
        className={`${classes.drawer} drawer-container`}
        variant="persistent"
        open={open}
        anchor={props.anchor}
        classes={{
          paper: clsx({
            [classes.drawerPaperFixed]: isPanelFixed,
            [classes.drawerPaper]: !isPanelFixed,
          }),
        }}
      >
        {/* <div className={classes.drawerHeader}> */}
        {!props.disableCloseBtn && (
          <IconButton
            className={[classes.paddingAdjusted, classes.closeDrawerButton]}
            onClick={handleDrawerClose}
          >
            {theme.direction === "ltr" ? (
              <Tooltip title="Click to collapse the side menu">
                <span className="Appkit4-icon icon-left-chevron-fill ap-font-20" />
              </Tooltip>
            ) : (
              <Tooltip title="Click to collapse the side menu">
                <span className="Appkit4-icon icon-right-chevron-fill ap-font-20" />
              </Tooltip>
            )}
          </IconButton>
        )}
        {/* </div> */}
        <hr />
        {props.panelContent}
      </Drawer>
      <main
        className={`${clsx(classes.content, {
          [classes.contentShift]: open,
        })} height-setting`}
      >
        <Card
          className={clsx(classes.openDrawerButton, open && classes.hide, {
            [classes.buttonFixed]: isPanelFixed,
            [classes.buttonAbsolute]: !isPanelFixed,
          })}
        >
          <IconButton
            disabled={props.disableCloseBtn}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={classes.paddingAdjusted}
          >
            {theme.direction === "ltr" ? (
              <Tooltip title="Click to expand the side menu">
                <span className="Appkit4-icon icon-right-chevron-fill ap-font-20" />
              </Tooltip>
            ) : (
              <Tooltip title="Click to expand the side menu">
                <span className="Appkit4-icon icon-left-chevron-fill ap-font-20" />
              </Tooltip>
            )}
          </IconButton>
        </Card>
        {props.mainContent}
      </main>
    </div>
  );
};
export default StickyPanel;
