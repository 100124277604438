import { useState, useEffect, forwardRef } from "react";
import _ from "lodash";
import "./TprlaRoleAssign.scss";
import Table from "../../../../components/shared/Table/Table";
import { useGetAllUsersMutation } from "../../../../store/services/tprla/users";
import { Avatar, Box } from "@mui/material";
import EditRole from "./EditRole";
import DeleteRole from "./DeleteRole";
import InviteUser from "./InviteUser";
import { useDispatch } from "react-redux";
import { setTprlaUser } from "../../../../store/slices/tprla/userSlice";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import "./TprlaRoleAssign.scss";
import useDidMountEffect from "../../../../components/shared/CustomHooks/didMountEffect";
import { setLoading } from "../../../../store/slices/dtms/loading";
import { useParams } from "react-router-dom";
import { _getLoggedInUserDetails } from "../../../../envUrl";

const TprlaRoleAssign = ({ userType }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const tprlaProjectRoles = useSelector(
    (state) => state.tprlaMaster.tprlaProjectRoles
  );

  const [users, setUsers] = useState([]);
  const [table, setTable] = useState();
  //const [loading, setLoading] = useState(false);
  const [editRoleModal, setEditRoleModal] = useState({
    isOpen: false,
    user: "",
    roles: [],
    userRole: "",
  });
  const [deleteUserModal, setDeleteUserModal] = useState({
    isOpen: false,
    user: "",
    userRole: "",
  });
  const [inviteUserModal, setInviteUserModal] = useState({
    isOpen: false,
    roles: [],
  });

  const [getUsers, getUsersResult] = useGetAllUsersMutation();

  useEffect(() => {
    dispatch(setLoading(true));
    getUsers(params.projectId);
  }, []);

  useEffect(() => {
    dispatch(setTprlaUser(users));
  }, [users]);

  useEffect(() => {
    setTable({
      title: "",
      columns: [
        {
          title: "Name",
          field: "firstName",
          render: (row) => (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Avatar>{`${row.firstName[0]}${row.lastName[0]}`}</Avatar>
              {row.firstName} {row.lastName}
            </Box>
          ),
          style: { width: "30%" },
        },
        { title: "Email", field: "email", style: { width: "30%" } },
        {
          title: "Role",
          field: "role",
          render: (rowData) => {
            var role = "";
            rowData.modules?.forEach((module) => {
              if (module.name === "TPRLA") {
                role = module.role.name;
              }
            });
            return <p>{role}</p>;
          },
          style: { width: "20%" },
        },
      ],
      actions: [
        {
          icon: forwardRef((props, ref) => (
            <span
              className="Appkit4-icon icon-add-user-fill ap-font-24 icon-grey-dark"
              {...props}
              ref={ref}
            />
          )),
          tooltip: "Invite",
          onClick: () => onAdd(),
          isFreeAction: true,
        },
        (rowData) => ({
          icon: forwardRef((props, ref) => (
            <span
              className="Appkit4-icon icon-pencil-fill ap-font-16"
              {...props}
              ref={ref}
            />
          )),
          tooltip:
            userType !== "AUDITOR"
              ? "You are not allowed to edit users"
              : "Edit",
          disabled: rowData.modules[0].role.isCreator || userType !== "AUDITOR",
          onClick: (event, data) => {
            onEdit(rowData);
          },
          cellStyle: {
            textAlign: "center",
            justifyContent: "center",
          },
        }),
        (rowData) => ({
          icon: forwardRef((props, ref) => (
            <span
              className="Appkit4-icon icon-delete-fill ap-font-16"
              {...props}
              ref={ref}
            />
          )),
          tooltip:
            userType !== "AUDITOR"
              ? "You are not allowed to remove users"
              : "Delete",
          disabled: rowData.modules[0].role.isCreator || userType !== "AUDITOR",
          onClick: (event, data) => {
            onDelete(rowData);
          },
          cellStyle: {
            textAlign: "center",
            justifyContent: "center",
          },
        }),
      ],
    });
  }, [tprlaProjectRoles]);

  useDidMountEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = getUsersResult;

    if (isSuccess) {
      console.log("Yay!", data);
      const clonedRes = _.cloneDeep(data.result);
      clonedRes.forEach((d) => (d.role = d.role?.name));
      setUsers(clonedRes);
    } else if (isError) {
      console.log("Oops!", error);
      confirmAlert({
        title: "Error",
        message: "Unable to fetch users",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
    dispatch(setLoading(isLoading));
  }, [getUsersResult]);

  const onAdd = () => {
    setInviteUserModal({
      ...inviteUserModal,
      isOpen: true,
    });
  };

  const onCloseAddModal = () => {
    setInviteUserModal({
      ...inviteUserModal,
      isOpen: false,
    });
  };

  const onEdit = (user) => {
    var role = "";
    user.modules.forEach((module) => {
      if (module.name === "TPRLA") {
        role = module.role.name;
      }
    });

    setEditRoleModal({
      ...editRoleModal,
      isOpen: true,
      user: _.cloneDeep(user),
      userRole: role,
    });
  };

  const onCloseEditModal = () => {
    setEditRoleModal({
      ...editRoleModal,
      isOpen: false,
    });
  };

  const onDelete = (user) => {
    var role = "";
    user.modules.forEach((module) => {
      if (module.name === "TPRLA") {
        role = module.role.name;
      }
    });

    setDeleteUserModal({
      ...deleteUserModal,
      isOpen: true,
      user,
      userRole: role,
    });
  };

  const onCloseDeleteModal = () => {
    setDeleteUserModal({
      ...editRoleModal,
      isOpen: false,
    });
  };

  const refreshUserList = () => {
    getUsers(params.projectId);
  };

  return (
    <div className="tpra-role-assign">
      <Table {...table} data={users} />
      <InviteUser
        open={inviteUserModal.isOpen}
        onClose={onCloseAddModal}
        refreshUserList={refreshUserList}
      />
      <EditRole
        open={editRoleModal.isOpen}
        onClose={onCloseEditModal}
        user={editRoleModal.user}
        userRole={editRoleModal.userRole}
        refreshUserList={refreshUserList}
      />
      <DeleteRole
        open={deleteUserModal.isOpen}
        onClose={onCloseDeleteModal}
        user={deleteUserModal.user}
        userRole={deleteUserModal.userRole}
        refreshUserList={refreshUserList}
        setLoading={setLoading}
      />
    </div>
  );
};

export default TprlaRoleAssign;
