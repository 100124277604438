// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dtms-admin-home .module-cards {
  height: 100%;
}
.dtms-admin-home .home-card-flex {
  display: flex;
  flex-direction: column;
}
.dtms-admin-home .card-header-flex {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}
.dtms-admin-home .module-name {
  margin-bottom: 5px;
}
.dtms-admin-home .name-role-flex {
  margin-left: 10px;
  display: inline-flex;
  flex-direction: column;
}
.dtms-admin-home .text {
  display: inline;
  font-size: 0.875rem;
}
.dtms-admin-home .text.value {
  margin-left: 10px;
  color: #818181 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/home/Home.scss"],"names":[],"mappings":"AAGE;EACE,YAAA;AAFJ;AAIE;EACE,aAAA;EACA,sBAAA;AAFJ;AAIE;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AAFJ;AAIE;EACE,kBAAA;AAFJ;AAIE;EACE,iBAAA;EACA,oBAAA;EACA,sBAAA;AAFJ;AAIE;EACE,eAAA;EACA,mBAAA;AAFJ;AAGI;EACE,iBAAA;EACA,yBAAA;AADN","sourcesContent":["@import \"../../variables.scss\";\n\n.dtms-admin-home {\n  .module-cards {\n    height: 100%;\n  }\n  .home-card-flex {\n    display: flex;\n    flex-direction: column;\n  }\n  .card-header-flex {\n    display: flex;\n    flex-direction: row;\n    margin-bottom: 5px;\n  }\n  .module-name {\n    margin-bottom: 5px;\n  }\n  .name-role-flex {\n    margin-left: 10px;\n    display: inline-flex;\n    flex-direction: column;\n  }\n  .text {\n    display: inline;\n    font-size: 0.875rem;\n    &.value {\n      margin-left: 10px;\n      color: $charcoal2 !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
