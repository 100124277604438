import React, { useState, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import fallbackRender from "../../components/error/Error";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { _getLoggedInUserDetails } from "../../envUrl";
import { useGetTprlaRolesMutation } from "../../store/services/tprla/masterData";
import { moduleNames } from "../../utils/constants";
import { tprlaProjectRoles } from "./tprlaUtils";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import { setLoading } from "../../store/slices/dtms/loading";
import useDidMountEffect from "../../components/shared/CustomHooks/didMountEffect";
import TprlaDashboard from "./tprla-dashboard/TprlaDashboard";
import { Suspense } from "react";
import TprlaProject from "./tprla-project/TprlaProject";
import { setTprlaProjectRoles } from "../../store/slices/tprla/masterSlice";
import UploadFile from "./tprla-project/project-files/Upload/UploadFile";
import { tprlaModuleRoles } from "./tprlaUtils";
import ESign from "./tprla-project/project-files/E-Sign/ESign";
import { setTprlaUser } from "../../store/slices/tprla/userSlice";
import { useGetAllUsersMutation } from "../../store/services/tprla/users";
import ViewAndComment from "./tprla-project/project-files/View/ViewAndComment";

const TprlaApp = ({ isAdmin }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [userRole, setUserRole] = useState(null);

  const [getTprlaRoles, getTprlaRolesResult] = useGetTprlaRolesMutation();
  const [getAllUsers, getAllUsersResult] = useGetAllUsersMutation();

  useEffect(() => {
    const user = _getLoggedInUserDetails();
    getAllUsers();
    const moduleDetails = user.modules.find(
      (module) => module.name === "TPRLA"
    );
    const isTpraAdmin = isAdmin
      ? tprlaModuleRoles.admin
      : moduleDetails?.role?.name;

    getTprlaRoles();

    setUserRole(isTpraAdmin);
  }, []);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = getAllUsersResult;
    if (isSuccess) {
      const clonedRes = _.cloneDeep(data.result);
      clonedRes.forEach((d) => (d.role = d.role?.name));
      dispatch(setTprlaUser(clonedRes));
    } else if (isError) {
      dispatch(setLoading(isLoading));
      console.log("Error: ", error);
      confirmAlert({
        title: "Unable to fetch",
        message: "Unable to fetch users",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [getAllUsersResult]);

  useDidMountEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = getTprlaRolesResult;
    if (isSuccess) {
      console.log("Yay!", data);

      const clonedRes = _.cloneDeep(data.result);
      const moduleDetails = clonedRes.find(
        (module) => (module.name = moduleNames.tprla)
      );
      dispatch(setTprlaProjectRoles(moduleDetails.roles));
    } else if (isError) {
      console.log("Oops!", error);
      const errorMessage =
        error.data && error.data.error
          ? error.data.error.message
            ? error.data.error.message
            : "Unable to fetch roles"
          : "Unable to fetch roles";
      confirmAlert({
        title: "Error",
        message: errorMessage,
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
    dispatch(setLoading(isLoading));
  }, [getTprlaRolesResult]);

  return (
    <div className="tpra-app">
      {userRole !== null && (
        <Routes>
          <Route
            path=":projectId/uploadfile"
            element={
              <ErrorBoundary isAdmin={isAdmin} fallbackRender={fallbackRender}>
                <UploadFile isAdmin={isAdmin} />
              </ErrorBoundary>
            }
          />
          <Route
            path=":projectId/view/:fileType/:fileId"
            element={
              <ErrorBoundary fallbackRender={fallbackRender}>
                <ViewAndComment />
              </ErrorBoundary>
            }
          />
          <Route
            path=":projectId/sign/:fileType/:fileId"
            element={
              <ErrorBoundary fallbackRender={fallbackRender}>
                <ESign />
              </ErrorBoundary>
            }
          />
          <Route
            path=":projectId"
            element={
              <ErrorBoundary fallbackRender={fallbackRender}>
                <Suspense>
                  <TprlaProject
                    userRole={userRole}
                    isAdmin={isAdmin}
                    getAllUsers={getAllUsers}
                  />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path=""
            element={
              <ErrorBoundary fallbackRender={fallbackRender}>
                <TprlaDashboard userRole={userRole} isAdmin={isAdmin} />
              </ErrorBoundary>
            }
          />
          {<Route path="*" element={<Navigate to="" />} />}
        </Routes>
      )}
    </div>
  );
};

export default TprlaApp;
