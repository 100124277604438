import { useEffect, useState } from "react";
import _ from "lodash";
import { useGetAllFilesMutation } from "../../../../store/services/tprla/files";
import { _getLoggedInUserDetails } from "../../../../envUrl";
import { resetfileUrl } from "../../../../store/slices/tprla/fileSlice";
import { useNavigate, useParams } from "react-router-dom";
import Download from "./Download";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../store/slices/dtms/loading";
import { setTprlaUser } from "../../../../store/slices/tprla/userSlice";
import { confirmAlert } from "react-confirm-alert";
import { useGetAllUsersMutation } from "../../../../store/services/tprla/users";
import "../TprlaProject.scss";
import Delete from "./Delete";
import { makeFileDataForTP } from "../../../../utils/utilFns";
import { TprlaProjectFileList } from "./TprlaProjectFileList";
import Share from "./Share";
import { tprlaProjectRoles } from "../../tprlaUtils";

const TprlaProjectFiles = ({ userRole, getProjectName, userType }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const [tableData, setTableData] = useState([]);
  const [getAllFiles, getAllFilesResult] = useGetAllFilesMutation();

  const [downloadFileModal, setDownloadFileModal] = useState({
    isOpen: false,
    file: {},
    file_id: null,
    fileName: "",
    isLocked: false,
    isError: false,
    isDownloading: false,
  });

  const [shareModal, setShareModal] = useState({
    isOpen: false,
    title: "",
    file: {},
    users: [],
    reports: [],
  });

  const [deleteModal, setDeleteModal] = useState({
    open: false,
    details: {},
  });

  useEffect(() => {
    dispatch(resetfileUrl());
  }, [userRole, params.projectId]);

  const getFiles = () => {
    const user = _getLoggedInUserDetails();
    getAllFiles({
      userId: user.id,
      projectId: params.projectId,
    });
  };

  useEffect(() => {
    getFiles();
  }, []);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = getAllFilesResult;
    if (isSuccess) {
      const files = _.cloneDeep(data.result.content);
      getProjectName(data.result.projectname);
      if (userRole !== tprlaProjectRoles.thirdparty) {
        setTableData(files);
      } else {
        const tpFiles = makeFileDataForTP(files);
        setTableData(tpFiles);
      }
    }
    if (isError) {
      console.log("Oops!", error);
      confirmAlert({
        title: "Error",
        message: error.data
          ? error.data.error.message
          : "Unable to fetch files",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
    dispatch(setLoading(isLoading));
  }, [getAllFilesResult]);

  const onView = (file) => {
    navigate(`/tprla/${params.projectId}/view/${file.type}/${file.id}`);
  };

  const onDownload = (file) => {
    setDownloadFileModal({
      ...downloadFileModal,
      isOpen: true,
      file,
    });
  };

  const onShare = async (file) => {
    setShareModal({
      ...shareModal,
      isOpen: true,
      title: "",
      file,
    });
  };

  const closeshareModal = () => {
    setShareModal({
      ...shareModal,
      isOpen: false,
    });
  };

  const handleDownloadClose = () => {
    setDownloadFileModal({
      ...downloadFileModal,
      isOpen: false,
      file_id: null,
    });
  };

  const onDelete = (data) => {
    setDeleteModal({ open: true, details: { ...data } });
  };

  const onCloseDeleteModal = () => {
    setDeleteModal({ open: false, details: {} });
  };

  return (
    <div className="tpra-dashboard-table">
      <TprlaProjectFileList
        userRole={userRole}
        onView={onView}
        onDownload={onDownload}
        onShare={onShare}
        onDelete={onDelete}
        data={tableData}
        userType={userType}
      />
      <Download
        open={downloadFileModal.isOpen}
        onClose={handleDownloadClose}
        file={downloadFileModal.file}
        isLoading={downloadFileModal.isDownloading}
      />
      <Share
        open={shareModal.isOpen}
        onClose={closeshareModal}
        file={shareModal.file}
        users={shareModal.users}
        refreshFiles={getFiles}
      />
      <Delete
        open={deleteModal.open}
        fileDetails={deleteModal.details}
        getFiles={getFiles}
        onCloseModal={onCloseDeleteModal}
      />
    </div>
  );
};

export default TprlaProjectFiles;
