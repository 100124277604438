import React, { useEffect, useRef } from "react";

const useDidMountEffect = (toExecute, onChangeOf) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) toExecute();
    else didMount.current = true;
  }, onChangeOf);
};

export default useDidMountEffect;
