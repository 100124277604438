import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import _ from "lodash";

import "./UploadFile.scss";
import { confirmAlert } from "react-confirm-alert";

const FileDropzone = ({ onFileSelection }) => {
  const onDrop = useCallback((acceptedFiles) => {
    if (!_.isEmpty(acceptedFiles) && acceptedFiles.length > 0) {
      onFileSelection(acceptedFiles[0]);
    } else {
      confirmAlert({
        title: "Error",
        message: "Please select a PDF file of size 10MB or less",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    multiple: false,
    maxSize: 10 * 1024 * 1024,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="dropzone-box">
          <p>
            Drag and drop or{" "}
            <button className="browse-button" onClick={open}>
              browse
            </button>{" "}
            your files
          </p>
        </div>
      </div>
    </>
  );
};

export default FileDropzone;
