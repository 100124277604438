import { useState } from "react";
import "./Comment.scss";
import ModalDialog from "../../Modal/Modal";
import { _getLoggedInUserDetails } from "../../../../envUrl";
import Mentions from "../../Mentions/Mentions";

const DeleteCommentModal = (props) => {
  let {
    open = false,
    onClose = () => {},
    commentId,
    onDeleteComment,
    comment,
    type,
  } = props;

  const commentModalOptions = {
    title: "Delete",
    option: {
      open,
      maxWidth: "sm",
      actionButtonName: "Yes",
      twoActionButtonRequired: true,
      actionSecondaryButtonName: "No",
    },
  };

  const [modalOptions, setModalOptions] = useState(commentModalOptions);

  const onDelete = () => {
    onDeleteComment(commentId);
  };

  const handleClose = () => {
    setModalOptions({
      ...modalOptions,
      option: { ...modalOptions.option, open: false },
    });
    onClose();
  };
  return (
    <div className="view-modal">
      <ModalDialog
        {...commentModalOptions}
        onClose={handleClose}
        onSave={onDelete}
      >
        Are you sure you want to delete the following {type} ?
        <div
          style={{
            paddingTop: "15px",
          }}
        >
          <Mentions text={comment} isDisabled={true} />
        </div>
      </ModalDialog>
    </div>
  );
};

export default DeleteCommentModal;
