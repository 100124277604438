import {
  Paper,
  Card,
  CardContent,
  Typography,
  FormLabel,
  TextField,
  FormHelperText,
  Button,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { _getLoggedInUserDetails } from "../../../envUrl";
import { useState, useEffect, useRef } from "react";

import moment from "moment";

const EsignSidePanel = ({ submitSignature, goToView }) => {
  const { fileDetails, isLoading, isError, isDisabled, base64EncodedData } =
    useSelector((state) => state.file);

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const userDetails = _getLoggedInUserDetails();
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [institution, setInstitution] = useState("");
  const [signature, setSignature] = useState("");
  const [signatureError, setSignatureError] = useState(false);
  const [signtureErrorText, setSignatureErrorText] = useState("");
  const [nameError, setNameError] = useState(false);
  const [positionError, setPositionError] = useState(false);
  const [institutionError, setInstitutionError] = useState(false);
  const [nameErrorText, setNameErrorText] = useState("");
  const [positionErrorText, setPositionErrorText] = useState("");
  const [institutionErrorText, setInstitutionErrorText] = useState("");

  let sigCanvas = useRef({});

  useEffect(() => {
    if (isDisabled) {
      sigCanvas.current.off();
    }
  }, [isDisabled]);

  const onNameChange = (e) => {
    const name = e.target.value;
    if (name.trim() !== "") {
      setNameError(false);
      setNameErrorText("");
      setName(name);
    } else {
      setName("");
      setNameError(true);
      setNameErrorText("Please Enter Your Name");
    }
  };

  const onPositionChange = (e) => {
    const position = e.target.value;
    if (position.trim() !== "") {
      setPositionError(false);
      setPositionErrorText("");
      setPosition(position);
    } else {
      setPosition("");
      setPositionError(true);
      setPositionErrorText("Please Enter Your Position");
    }
    setPosition(position);
  };

  const onInstitutionChange = (e) => {
    const institution = e.target.value;
    if (institution.trim() !== "") {
      setInstitutionError(false);

      setInstitutionErrorText("");
      setInstitution(institution);
    } else {
      setInstitution("");
      setInstitutionError(true);
      setInstitutionErrorText("Please Enter Your Institution");
    }
    setInstitution(institution);
  };

  const save = () => {
    let data = sigCanvas.current.toDataURL();
    setSignature(data);
    setSignatureError(false);
    setSignatureErrorText("");
  };

  const clear = () => {
    sigCanvas.current.clear();
    setSignature("");
    setSignatureError(true);
    setSignatureErrorText("Please Enter Your Signature");
  };

  const handleSubmit = (e) => {
    let isNameValid = false;
    let isPositionValid = false;
    let isInstitutionValid = false;
    let isSignatureValid = false;

    if (name.trim() === "") {
      setNameError(true);
      setNameErrorText("Please Enter Your Name");
    } else {
      isNameValid = true;
      setNameError(false);
      setNameErrorText("");
    }
    if (position.trim() === "") {
      setPositionError(true);
      setPositionErrorText("Please Enter Your Position");
    } else {
      isPositionValid = true;
      setPositionError(false);
      setPositionErrorText("");
    }
    if (institution.trim() === "") {
      setInstitutionError(true);
      setInstitutionErrorText("Please Enter Your Institution");
    } else {
      isInstitutionValid = true;
      setInstitutionError(false);
      setInstitutionErrorText("");
    }
    if (signature === "") {
      setSignatureError(true);
      setSignatureErrorText("Please Enter Your Signature");
    } else {
      isSignatureValid = true;
      setSignatureError(false);
      setSignatureErrorText("");
    }

    if (
      isNameValid &&
      isPositionValid &&
      isInstitutionValid &&
      isSignatureValid
    ) {
      const payload = {
        body: {
          name,
          position,
          institution,
          signature,
          investorId: userDetails.id,
          signerEmail: userDetails.email,
          projectId: params.projectId,
          fileId: +params.fileId,
          signDate: moment().format("DD/MM/YYYY h:mm a"),
          file: [
            {
              fileName: fileDetails.fileName,
              url: `data:application/pdf;base64,${base64EncodedData}`,
            },
          ],
        },
        projectId: params.projectId,
      };
      submitSignature(payload);
    }
  };

  return (
    <Paper className="esign-container">
      <Card variant="outlined" className="esign-container__card">
        <CardContent className="esign-container__card--form">
          <Typography>Please fill all the required fields.</Typography>
          <FormLabel className="form-label">Name</FormLabel>
          <TextField
            required
            id="outlined-required"
            size="small"
            className="form-input"
            onChange={onNameChange}
            error={nameError}
            disabled={isDisabled}
          />
          <FormHelperText>{nameErrorText}</FormHelperText>
          <FormLabel className="form-label">Position</FormLabel>
          <TextField
            required
            id="outlined-required"
            size="small"
            className="form-input"
            onChange={onPositionChange}
            error={positionError}
            disabled={isDisabled}
          />
          <FormHelperText>{positionErrorText}</FormHelperText>
          <FormLabel className="form-label">Institution</FormLabel>
          <TextField
            required
            id="outlined-required"
            size="small"
            className="form-input"
            onChange={onInstitutionChange}
            error={institutionError}
            disabled={isDisabled}
          />
          <FormHelperText>{institutionErrorText}</FormHelperText>
          <FormLabel className="form-label">Signature</FormLabel>
          <div className="signature-container">
            <SignatureCanvas
              penColor="black"
              minWidth={2}
              maxWidth={1}
              minDistance={0}
              throttle={0}
              canvasProps={{
                width: 400,
                height: 110,
                className: signatureError
                  ? "signature-canvas signature-canvas--error"
                  : "signature-canvas",
                style: { cursor: isDisabled ? "not-allowed" : "" },
              }}
              ref={sigCanvas}
              onEnd={() => save()}
            />
            <FormHelperText>{signtureErrorText}</FormHelperText>
            {signature && !isDisabled && (
              <Button
                variant="text"
                onClick={clear}
                sx={{ mt: "10px !important" }}
              >
                Clear
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
      <div className="submit-comment-btn">
        <Button
          variant="outlined"
          onClick={goToView}
          sx={{ mt: "15px !important", mr: "15px !important" }}
        >
          Comment
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{ mt: "15px !important" }}
          disabled={isLoading || isDisabled ? true : false}
        >
          {isLoading ? "Submitting" : "Submit"}
        </Button>
      </div>
    </Paper>
  );
};
export default EsignSidePanel;
