import React from "react";
import "./IconMaker.scss";

export const getColour = () => {
  let n = (Math.random() * 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
};

const IconMaker = ({ text = "", colour = "", disabled = false }) => {
  const color = "orange"; //colour === "" ? getColour() : colour;
  return (
    <div
      className={`elevated icon-outline ${disabled ? "disabled" : ""}`}
      style={{ border: `1px solid ${color}` }}
    >
      <div className="icon-text" style={{ color: color }}>
        {text.substring(0, 3)}
      </div>
    </div>
  );
};

export default IconMaker;
