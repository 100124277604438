import { useEffect, useState } from "react";
import "./TprlaDashboard.scss";
import { Box, Button, Grid, Typography } from "@mui/material";
import ProjectCard from "./ProjectCard";
import SearchAndFilter from "../../../components/shared/search-filter/SearchAndFilter";
import InfiniteScroll from "react-infinite-scroll-component";
import { useGetProjectsMutation } from "../../../store/services/tprla/project";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/slices/dtms/loading";
import useDeepCompareEffect from "use-deep-compare-effect";
import _ from "lodash";
import AddProject from "./AddProject";
import DeleteProject from "./DeleteProject";
import { tprlaModuleRoles } from "../tprlaUtils";

const TprlaDashboard = ({ userRole, isAdmin }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [hasChanged, setHasChanged] = useState("");
  const [isScroll, setIsScroll] = useState(false);
  const [projects, setProjects] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [isProjectsLoading, setIsProjectsLoading] = useState(false);
  const [projectsToRender, setProjectsToRender] = useState(null);
  const [getProjects, getProjectsResult] = useGetProjectsMutation();
  const dispatch = useDispatch();
  const [action, setAction] = useState({
    open: false,
  });
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    projectId: null,
    projectName: "",
  });

  const onAdd = () => {
    setAction({ ...action, open: true });
  };

  const onClose = () => {
    setAction({ ...action, open: false });
  };

  const openDeleteModal = (details) => {
    setDeleteModal({
      ...deleteModal,
      open: true,
      projectId: details.projectId,
      projectName: details.projectName,
    });
  };

  const closeDeleteModal = () => {
    setDeleteModal({ ...deleteModal, open: false });
  };

  const onScroll = () => {
    setCurrentPage(currentPage + 1);
    setHasChanged("scroll");
    setIsScroll(!isScroll);
  };

  const onSearchChange = (text) => {
    setCurrentPage(1);
    setHasChanged("search");
    setSearchText(text);
  };

  useEffect(() => {
    getProjects({ searchText, currentPage });
  }, [searchText, isScroll]);

  useEffect(() => {
    const { isLoading, isFetching, isSuccess, isError, data, error } =
      getProjectsResult;

    if (isSuccess) {
      const clonedRes = _.cloneDeep(data.result);
      setProjects(clonedRes);
    } else if (isError) {
      confirmAlert({
        title: "Error",
        message: "Unable to fetch projects!",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
    setIsProjectsLoading(isLoading);
    dispatch(setLoading(isLoading));
  }, [getProjectsResult]);

  useDeepCompareEffect(() => {
    if (!_.isEmpty(projects) || projects.length > 0) {
      setHasMore(projects.totalPages > currentPage ? true : false);
      if (
        projectsToRender !== null &&
        projectsToRender !== undefined &&
        hasChanged !== "search"
      ) {
        setProjectsToRender([...projectsToRender, ...projects.content]);
      } else {
        setProjectsToRender(projects.content);
      }
    }
  }, [projects]);

  const refreshProjects = () => {
    getProjects({ searchText: "", currentPage: 1 });
    setProjectsToRender(null);
    setCurrentPage(1);
  };

  return (
    <>
      <InfiniteScroll
        dataLength={
          projectsToRender !== null && projectsToRender !== undefined
            ? projectsToRender.length
            : 0
        }
        next={onScroll}
        hasMore={hasMore}
      >
        <Box className="workspace-header">
          {(isAdmin || userRole === tprlaModuleRoles.admin) && (
            <Button variant="outlined" onClick={onAdd}>
              Create Project
            </Button>
          )}
          <SearchAndFilter
            onSearchChange={onSearchChange}
            isFilterRequired={isAdmin}
          />
        </Box>
        <Box className="tprla-dashboard">
          {projectsToRender !== null ? (
            projectsToRender.length > 0 ? (
              <Grid container spacing={2}>
                {projectsToRender.map((project, i) => (
                  <Grid key={i} item xs={12} sm={6} md={4}>
                    <ProjectCard
                      projectDetails={project}
                      isAdmin={isAdmin}
                      onDelete={openDeleteModal}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography className="no-projects">
                No projects to display
              </Typography>
            )
          ) : (
            <p>
              <Typography className="no-projects">
                {isProjectsLoading ? "Loading...." : "No Projects to display"}
              </Typography>
            </p>
          )}
        </Box>
      </InfiniteScroll>
      {(isAdmin || userRole === tprlaModuleRoles.admin) && (
        <AddProject
          open={action.open}
          handleClose={onClose}
          refreshProjects={refreshProjects}
        />
      )}
      <DeleteProject
        open={deleteModal.open}
        onClose={closeDeleteModal}
        projectId={deleteModal.projectId}
        projectName={deleteModal.projectName}
        refreshProjects={refreshProjects}
      />
    </>
  );
};

export default TprlaDashboard;
