import React, { useEffect } from "react";

import { MentionsInput, Mention } from "react-mentions";
import defaultStyle from "./defaultStyle";
import defaultMentionStyle from "./defaultMentionStyle";

// use first/outer capture group to extract the full entered sequence to be replaced
// and second/inner capture group to extract search string from the match
const emailRegex = /(([^\s@]+@[^\s@]+\.[^\s@]+))$/;

function Mentions({
  data = [],
  text = "",
  onChange = () => {},
  onAdd = () => {},
  isDisabled = true,
  style = {},
}) {
  return (
    <>
      <MentionsInput
        placeholder={"Type your reply...."}
        disabled={isDisabled}
        value={text}
        onChange={onChange}
        style={{ ...defaultStyle, ...style }}
        a11ySuggestionsListLabel={"Suggested mentions"}
      >
        <Mention
          markup="@[__display__](user:__id__)"
          trigger="@"
          data={data}
          renderSuggestion={(highlightedDisplay, focused) => (
            <div className={`user ${focused ? "focused" : ""}`}>
              {highlightedDisplay.display}
            </div>
          )}
          onAdd={onAdd}
          style={defaultMentionStyle}
          allowSuggestionsAboveCursor={true}
        />
      </MentionsInput>
    </>
  );
}

export default Mentions;
