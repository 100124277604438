import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  _getAuthHeaders,
  _getClientID,
  _getDTMSProxy,
  _getEncoderKey,
  _getLoggedInUserDetails,
} from "../../../envUrl";
import { inviteUserConstants } from "../../../utils/constants";
import api from "../../api";
import { hmacEncode } from "../../../utils/utilFns";

export const dtmsUserApi = createApi({
  reducerPath: "dtmsUserApi",
  baseQuery: fetchBaseQuery({
    baseUrl: _getDTMSProxy(),
  }),
  endpoints: (builder) => ({
    inviteUser: builder.mutation({
      query: (data) => {
        const loggedInUserDetails = _getLoggedInUserDetails();
        const authHeaders = _getAuthHeaders();
        const key = _getEncoderKey();
        const payload = {
          orgId: loggedInUserDetails.orgId,
          email: data.email,
          requestingUserEmail: loggedInUserDetails.email,
          product: _getClientID(),
          ...inviteUserConstants,
          modules: data.modules,
        };
        const encodedPayload = hmacEncode(payload, key);

        return {
          url: api.inviteUser,
          method: "POST",
          body: payload,
          headers: { ...authHeaders, AuthKey: encodedPayload },
        };
      },
    }),
    registerUser: builder.mutation({
      query: (userDetails) => {
        const key = _getEncoderKey();
        const payload = {
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          mobileNumber: userDetails.mobileNumber,
          password: userDetails.password,
          product: _getClientID(),
          email: userDetails.email,
          registrationToken: userDetails.registrationToken,
        };
        const encodedPayload = hmacEncode(payload, key);

        return {
          url: api.registerUser,
          method: "POST",
          body: payload,
          headers: { AuthKey: encodedPayload },
        };
      },
    }),
    editUserRoles: builder.mutation({
      query: (userDetails) => {
        const authHeaders = _getAuthHeaders();
        return {
          url: api.editUserRoles,
          method: "POST",
          body: userDetails,
          headers: authHeaders,
        };
      },
    }),
    deleteUser: builder.mutation({
      query: (userDetails) => {
        const authHeaders = _getAuthHeaders();
        return {
          url: api.editUserRoles,
          method: "POST",
          body: userDetails,
          headers: authHeaders,
        };
      },
    }),
    getAllUser: builder.mutation({
      query: () => {
        const authHeaders = _getAuthHeaders();
        return {
          url: api.getAllUsers,
          method: "GET",
          params: { moduleName: "DTMS" },
          headers: authHeaders,
        };
      },
    }),
    updatePublicModuleAccess: builder.mutation({
      query: (userDetails) => {
        const authHeaders = _getAuthHeaders();
        return {
          url: api.updatePublicModuleAccess,
          method: "POST",
          body: userDetails,
          headers: authHeaders,
        };
      },
    }),
    selfInviteUser: builder.mutation({
      query: (data) => {
        const key = _getEncoderKey();
        const payload = {
          email: data.email,
          product: _getClientID(),
          ...inviteUserConstants,
          modules: data.modules,
        };
        const encodedPayload = hmacEncode(payload, key);

        return {
          url: api.selfInvite,
          method: "POST",
          body: payload,
          headers: { AuthKey: encodedPayload },
        };
      },
    }),
  }),
});

export const {
  useInviteUserMutation,
  useRegisterUserMutation,
  useGetAllUserMutation,
  useDeleteUserMutation,
  useEditUserRolesMutation,
  useUpdatePublicModuleAccessMutation,
  useSelfInviteUserMutation,
} = dtmsUserApi;
