import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  List,
  ListItem,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import "./Media.scss";

const Media = () => {
  return (
    <Paper className="media-container">
      <Typography variant="h6">
        Application for cheap services as energy price-related Additional grants
        for adult education centers and further education institutions in other
        sponsorship in the business area of ​​the MKW (Equity guideline for
        energy fund further education of the state of North Rhine- Westphalia)
      </Typography>
      <Typography>
        (Circular of the Ministry of Culture and Science of XX. January 2023)
      </Typography>

      <div className="media-container-form">
        <Typography variant="h6">1. General Information</Typography>
        <Card className="media-container-form_card">
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Typography style={{ marginBottom: 4 }}>
                Sponsor of the institution:
              </Typography>
              <FormLabel className="media-container-form_label">Name</FormLabel>
              <TextField fullWidth variant="outlined" />
              <FormLabel className="media-container-form_label">
                Designation
              </FormLabel>
              <TextField fullWidth variant="outlined" />
              <FormLabel className="media-container-form_label">
                Address
              </FormLabel>
              <TextField fullWidth variant="outlined" multiline rows={2} />
              <FormLabel className="media-container-form_label">
                District government
              </FormLabel>
              <TextField fullWidth variant="outlined" multiline rows={2} />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <FormLabel
                  className="media-container-form_label"
                  style={{ marginBottom: 2 }}
                >
                  Information provided:
                </FormLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  style={{ marginTop: 22 }}
                />
              </div>
              <div>
                <FormLabel className="dblock">Phone:</FormLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="number"
                  margin="dense"
                />
                <FormLabel className="dblock">Email:</FormLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="email"
                  margin="dense"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Typography style={{ marginBottom: 4 }}>Furnishings</Typography>
              <FormLabel className="media-container-form_label">Name</FormLabel>
              <TextField fullWidth variant="outlined" />
              <FormLabel className="media-container-form_label">
                Designation
              </FormLabel>
              <TextField fullWidth variant="outlined" />
              <FormLabel className="media-container-form_label">
                Address
              </FormLabel>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={2}
                style={{ marginBottom: 50 }}
              />
              <FormLabel className="media-container-form_label">
                Az. at district government:
              </FormLabel>
              <TextField fullWidth variant="outlined" multiline rows={4} />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <FormLabel
                  className="media-container-form_label"
                  style={{ marginBottom: 2 }}
                >
                  Credit institution:
                </FormLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={2}
                  style={{ marginTop: 22 }}
                />
              </div>
              <div>
                <FormLabel className="dblock">IBAN:</FormLabel>
                <TextField variant="outlined" fullWidth />
                <FormLabel className="dblock">BIC:</FormLabel>
                <TextField variant="outlined" fullWidth />
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
      <div className="media-container-description">
        <Typography variant="h6">2. Requirements</Typography>
        <Typography>
          The purpose of the Energy Fund is to increase the Energy prices – as a
          result of the Russian war of aggression against Ukraine – caused
          hardships in adult education centers and WbG-recognized Further
          training institutions in other sponsorship in the business area of
          Ministry of Culture and Science through targeted assistance during the
          funding period from 01/01/2023 to 12/31/2023. The aid is intended to
          compensate of additional costs caused by the energy crisis despite
          gas, heat and Electricity price brake are caused. The
          consumption-independent base price is not taken into account.
          <br />
          <strong>
            A condition for the cheap payment is a subsidy according to the WbG.
          </strong>
          <br />I confirm
        </Typography>
      </div>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox />}
          label="that I am a community college."
        />
        <FormControlLabel
          control={<Checkbox />}
          label="that I am an institution recognized according to the WbG"
        />
        <FormLabel
          component="legend"
          style={{ color: "inherit", marginTop: 4 }}
        >
          The additional demand is due to the higher energy costs.
        </FormLabel>
        <FormGroup aria-label="position" row>
          <FormControlLabel value="top" control={<Checkbox />} label="Yes" />
          <FormControlLabel value="start" control={<Checkbox />} label="No" />
        </FormGroup>
      </FormGroup>
      <Typography style={{ marginTop: 10, marginBottom: 10 }}>
        Reimbursement takes place in the form of an equity benefit. Need for
        refund Costs of at least €500.00 (de minimis limit) arise.
      </Typography>
      <Typography>
        If necessary, a brief explanation (mandatory to fill in if different
        from the reference year framework conditions have occurred, otherwise
        fill in only as an option)
      </Typography>
      <TextField multiline rows={8} variant="outlined" fullWidth />
      <FormControlLabel
        control={<Checkbox />}
        label="I hereby apply for an equitable benefit in the amount of"
      />
      <TextField type="number" />
      <FormLabel style={{ color: "inherit" }} size="small">
        €.
      </FormLabel>
      <br />
      <FormControlLabel
        control={<Checkbox />}
        label="The equitable benefit should be transferred to the above account"
      />
      <Typography style={{ marginTop: 8 }}>
        <strong>
          Attached to the application (incomplete applications cannot be
          processed):
        </strong>
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="Completed Excel spreadsheet to calculate the eligible costs"
      />
      <FormControlLabel
        control={<Checkbox />}
        label="Evidence of consumption and costs in the assessment period, for example
        due to advance payments to be made (in PDF format)"
      />
      <FormControlLabel
        control={<Checkbox />}
        label="Proof of consumption and costs in the reference year 2019 or 2021 (in PDF format)"
      />
      <Typography variant="h6" style={{ marginTop: 8 }}>
        3. Statements from the applicant organization
      </Typography>
      <List>
        <ListItem disableGutters>
          <span className="list-item-number">3.1</span>
          <FormControlLabel
            control={<Checkbox />}
            label="I assure that the application requirements specified in No. 2 are all met
        present. I acknowledge that I have no legal entitlement to the grant
        the equitable performance exists."
          />
        </ListItem>
        <ListItem disableGutters>
          <span className="list-item-number">3.2</span>
          <FormControlLabel
            control={<Checkbox />}
            label="I certify that I will use EU, federal and local funds as a priority
            and indicate when calculating the equitable benefit. With is known that a
            Double funding is not permitted."
          />
        </ListItem>
        <ListItem disableGutters>
          <span className="list-item-number">3.3</span>
          <FormControlLabel
            control={<Checkbox />}
            label="I am aware that in case of overcompensation, the received
            Equitable performance is to be repaid in whole or in part, since compensation and
            Insurance benefits and other subsidies are to be given priority."
          />
        </ListItem>
        <ListItem disableGutters>
          <span className="list-item-number">3.4</span>
          <FormControlLabel
            control={<Checkbox />}
            label="I confirm that I will provide the permitting authority with the
            Clarify the facts and process my application
            Make documents and information available immediately."
          />
        </ListItem>
        <ListItem disableGutters>
          <span className="list-item-number">3.5</span>
          <FormControlLabel
            control={<Checkbox />}
            label="I am aware that the information given in numbers 1, 2 and 3 is
            Subsidy relevant facts i. s.d. Section 264 of the Criminal Code i. V. m. § 2
            of the Subsidy Act of July 29, 1976 (BGBI I p. 2037) and Art. 1 of the
            State Subsidy Act (GV. NW. 1977 p. 136). I am aware that
            intentionally or carelessly false or incomplete information as well as the
            willful or negligent omission of notification of changes in
            prosecution for subsidy fraud (section 264 of the Criminal Code) with this information
            can have consequences."
          />
        </ListItem>
        <ListItem disableGutters>
          <span className="list-item-number">3.6</span>
          <FormControlLabel
            control={<Checkbox />}
            label="I consent to the collection and processing of my for the
            Subsidy required data within the framework of data protection law
            regulations (EU-DSGVO)."
          />
        </ListItem>
        <ListItem disableGutters>
          <span className="list-item-number">3.7</span>
          <FormControlLabel
            control={<Checkbox />}
            label="A review by the approval authorities, the North Rhine-Westphalian tax authorities, the State Audit Office NRW, the
            Federal Audit Office, the Federal Ministry for Economic Affairs and Energy and the
            I agree with the European Commission."
          />
        </ListItem>
        <ListItem disableGutters>
          <span className="list-item-number">3.8</span>
          <FormControlLabel
            control={<Checkbox />}
            label="I certify that I have taken every opportunity to reduce expenses
            only have or will use the cheap service when it is absolutely necessary
            scope to take advantage of."
          />
        </ListItem>
        <ListItem disableGutters>
          <span className="list-item-number">3.9</span>
          <FormControlLabel
            control={<Checkbox />}
            label="I certify that I have completed the advanced training provided by the Energy Fund
            Landes allowed full relief in pricing for everyone
            participants."
          />
        </ListItem>
        <ListItem disableGutters>
          <span className="list-item-number">3.10</span>
          <FormControlLabel
            control={<Checkbox />}
            label="I certify that I have given all information to the best of my knowledge and belief
            and did truthfully."
          />
        </ListItem>
      </List>
      <Box sx={{ display: "flex", justifyContent: "flex-end", m: 2 }}>
        <Button variant="contained">Submit</Button>
      </Box>
    </Paper>
  );
};

export default Media;
