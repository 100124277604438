import "./TprlaRoleAssign.scss";
import ModalDialog from "../../../../components/shared/Modal/Modal";
import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  TextField,
  Select,
  FormLabel,
} from "@mui/material";
import { useEditUserMutation } from "../../../../store/services/tprla/users";
import { Typography } from "@material-ui/core";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../store/slices/dtms/loading";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const EditRole = (props) => {
  let {
    open = false,
    onClose = () => {},
    user,
    userRole,
    refreshUserList = () => {},
  } = props;

  const dispatch = useDispatch();
  const params = useParams();

  const tprlaProjectRoles = useSelector(
    (state) => state.tprlaMaster.tprlaProjectRoles
  );

  const [editUser, editUserResult] = useEditUserMutation();
  const [selectedRole, setSelectedRole] = useState("");
  const [userToEdit, setUserToEdit] = useState({
    email: user.email,
    role: "",
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [roleError, setRoleError] = useState("");

  useEffect(() => {
    setSelectedRole(userRole);
  }, [userRole]);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = editUserResult;

    if (isSuccess) {
      console.log("Yay! ", data);
      handleClose();
      refreshUserList();
      confirmAlert({
        title: "Success",
        message: "User role edited successfully!",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              refreshUserList();
            },
          },
        ],
      });
    } else if (isError) {
      console.log("Oops! ", error);
      confirmAlert({
        title: "Error",
        message: error?.data?.error?.message
          ? error.data.error.message
          : "Unable AA edit user role",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
    dispatch(setLoading(isLoading));
  }, [editUserResult]);

  const editRoleModalOptions = {
    title: "Edit user role",
    option: {
      open,
      maxWidth: "sm",
      actionButtonName: "Ok",
    },
  };

  const [modalOptions, setModalOptions] = useState(editRoleModalOptions);

  const handleChange = (e) => {
    if (e.target.value !== user.role) {
      setUserToEdit({
        ...userToEdit,
        role: e.target.value,
      });
      setRoleError("");
      setSelectedRole(e.target.value);
      setIsDisabled(false);
    } else {
      setRoleError("Please Select a different role.");
      setIsDisabled(true);
    }
  };

  const onEditUser = () => {
    if (selectedRole == user.role) {
      setRoleError("Please Select a different role.");
      return;
    }

    delete user.modules;

    const payload = {
      id: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      orgId: null,
      onboardedBy: null,
      modules: [
        {
          name: "TPRLA",
          role: {
            name: userToEdit.role,
            isActive: "Y",
          },
        },
      ],
      role: userToEdit.role,
      roleName: null,
      is_active_user: null,
    };
    handleClose();
    editUser({ user: payload, projectId: params.projectId });
  };

  const handleClose = () => {
    setModalOptions({
      ...modalOptions,
      option: { ...modalOptions.option, open: false },
    });
    onClose();
  };

  return (
    <>
      {open && (
        <ModalDialog
          {...editRoleModalOptions}
          onSave={onEditUser}
          onClose={handleClose}
          isDisabled={isDisabled}
        >
          <div className="edit-role">
            <Box>
              <FormLabel className="edit-role-form-label">Email</FormLabel>
              <TextField
                disabled
                id="outlined-disabled"
                defaultValue={user.email}
                fullWidth
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <FormLabel className="edit-role-form-label">
                  Select role
                </FormLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedRole}
                  defaultValue={user.roleName}
                  onChange={handleChange}
                >
                  {tprlaProjectRoles?.map((role) => (
                    <MenuItem key={role.name} value={role.name}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
                <Typography className="error-text">{roleError}</Typography>
              </FormControl>
            </Box>
          </div>
        </ModalDialog>
      )}
    </>
  );
};

export default EditRole;
