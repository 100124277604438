import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useShareFileMutation } from "../../../../store/services/tprla/files";
import { useState } from "react";
import { fileTypesConstants, tprlaProjectRoles } from "../../tprlaUtils";
import ModalDialog from "../../../../components/shared/Modal/Modal";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormHelperText,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import { setLoading } from "../../../../store/slices/dtms/loading";
import "./ProjectFiles.scss";
import { _getClientID, _getLoggedInUserDetails } from "../../../../envUrl";
import _ from "lodash";
import { emailPattern } from "../../../../utils/constants";

const Share = ({
  open = false,
  onClose = () => {},
  file,
  refreshFiles = () => {},
}) => {
  const tagModalOptions = {
    title: `Share ${
      file.type === fileTypesConstants.report ? "report" : "release"
    } letter`,
    option: {
      open,
      maxWidth: "sm",
      actionButtonName: "Share",
    },
  };

  const loggedInUserDetails = _getLoggedInUserDetails();

  const { tprlaUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const params = useParams();
  const [shareFile, shareFileResult] = useShareFileMutation();
  const userDetails = _getLoggedInUserDetails();
  const [users, setUsers] = useState([]);
  const [isUnregistered, setIsUnregistered] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedUsersList, setSelectedUsersList] = useState([]);
  const [error, setError] = useState("");
  const [modalOptions, setModalOptions] = useState(tagModalOptions);

  useEffect(() => {
    const clonedTprlaUsers = _.cloneDeep(tprlaUser);
    const users = clonedTprlaUsers.filter(
      (user) => user.email !== userDetails.email
    );
    setUsers(users);
  }, [tprlaUser]);

  useEffect(() => {
    const { isLoading, isFetching, isError, isSuccess, data, error } =
      shareFileResult;
    if (isSuccess) {
      confirmAlert({
        title: "Shared",
        message: `${file.fileName} shared successfully!`,
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              refreshFiles();
            },
          },
        ],
      });
    } else if (isError) {
      const errorMessage =
        error.data && error.data.error
          ? error.data.error.message
            ? error.data.error.message
            : "Unable to share file"
          : "Unable to share file";
      confirmAlert({
        title: "Error",
        message: errorMessage,
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
    dispatch(setLoading(isLoading));
  }, [shareFileResult]);

  const handleClose = () => {
    setModalOptions({
      ...modalOptions,
      option: { ...modalOptions.option, open: false },
    });
    setSelectedUsersList([]);
    setError("");
    setIsUnregistered(false);
    onClose();
  };

  const onShare = () => {
    const payload = {
      projectId: params.projectId,
      fileId: file.id,
      body: selectedUsersList,
    };

    if (!_.isEmpty(selectedUsersList) && selectedUsersList.length > 0) {
      shareFile(payload);
      handleClose();
    } else {
      setError("Please select users to share");
    }
  };

  const handleUserChange = (e, newValue) => {
    if (isUnregistered) {
      const value = e.target.value;
      setSelectedUser(value);
      if (value.trim() === "" || !emailPattern.test(value)) {
        setError("Please enter a valid email id");
      } else {
        setError("");
      }
    } else {
      setSelectedUser(newValue);
      addUserToList(newValue);
    }
  };

  const addUserToList = (newUser) => {
    var clonedSelectUserList = _.cloneDeep(selectedUsersList);
    clonedSelectUserList = clonedSelectUserList.map((user) => user.email);
    if (!newUser.email) {
      setError("Please enter a valid email id");
    } else if (!clonedSelectUserList.includes(newUser.email)) {
      setError("");
      setSelectedUsersList([
        ...selectedUsersList,
        {
          ...newUser,
          modules: [
            {
              id: 1,
              name: "TPRLA",
              isActive: "Y",
              role: {
                id: 1,
                name: tprlaProjectRoles.thirdparty,
                isActive: "Y",
                isCreator: false,
              },
            },
          ],
          role: tprlaProjectRoles.thirdparty,
          isCreator: false,
          product: _getClientID(),
          requestingUserEmail: loggedInUserDetails.email,
        },
      ]);
    } else {
      setError("User already selected");
    }
  };

  const deselectUser = (i) => {
    var clonedSelectUserList = _.cloneDeep(selectedUsersList);
    clonedSelectUserList.splice(i, 1);
    setSelectedUsersList(clonedSelectUserList);
  };

  useEffect(() => {
    setSelectedUser(isUnregistered ? "" : null);
  }, [selectedUsersList, isUnregistered]);

  return (
    <ModalDialog {...tagModalOptions} onClose={handleClose} onSave={onShare}>
      <Box className="tag-container">
        <div className="form-section">
          <FormLabel className="form-text">File name</FormLabel>
          <Typography className="form-file">{file.fileName}</Typography>
        </div>

        <div className="form-section form-add-users">
          <FormLabel>Add Users</FormLabel>

          <div className="unregistered-formField">
            <Checkbox
              style={{ marginRight: "8px" }}
              checked={isUnregistered}
              onChange={() => setIsUnregistered(!isUnregistered)}
            />
            <FormLabel>Unregistered user</FormLabel>
          </div>

          {isUnregistered ? (
            <div className="unregistered-user-add">
              <TextField
                required
                className="user-textfield"
                value={selectedUser}
                sx={{ width: "50%" }}
                onChange={handleUserChange}
              />
              <Button
                size="small"
                variant="contained"
                onClick={() => addUserToList({ email: selectedUser })}
                disabled={error !== ""}
              >
                Add
              </Button>
            </div>
          ) : (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              className="user-autocomplete"
              options={users}
              sx={{ width: "80%" }}
              value={selectedUser}
              componentsProps={{
                popper: {
                  modifiers: [
                    {
                      name: "flip",
                      enabled: false,
                    },
                    {
                      name: "preventOverflow",
                      enabled: false,
                    },
                  ],
                },
              }}
              getOptionLabel={(option) => option.email}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props} key={Math.random()}>
                    <Box className="user-menu">
                      <Typography variant="body1" className="user-menu__name">
                        {option.name}
                      </Typography>
                      <Typography variant="body2" className="user-menu__email">
                        {option.email}
                      </Typography>
                    </Box>
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Search by email" />
              )}
              onChange={handleUserChange}
            />
          )}
          <FormHelperText>{error}</FormHelperText>
          <div className="selected-user-chip">
            {selectedUsersList?.map((user, i) => (
              <Chip
                key={i}
                label={user?.email}
                size="small"
                className="user-chip"
                onClick={() => {}}
                onDelete={() => {
                  deselectUser(i);
                }}
              />
            ))}
          </div>
        </div>
      </Box>
    </ModalDialog>
  );
};

export default Share;
