import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { _getAuthHeaders, _getDTMSProxy } from "../../../envUrl";
import api from "../../api";

export const tprlaMasterApi = createApi({
  reducerPath: "tprlaMasterApi",
  baseQuery: fetchBaseQuery({ baseUrl: _getDTMSProxy() }),
  endpoints: (builder) => ({
    getTprlaRoles: builder.mutation({
      query: () => {
        const authHeaders = _getAuthHeaders();
        return {
          url: api.getModuleRoles,
          method: "GET",
          params: {
            moduleName: "TPRLA",
            isProject: true,
          },
          headers: authHeaders,
        };
      },
    }),
  }),
});

export const { useGetTprlaRolesMutation } = tprlaMasterApi;
