import { Box, IconButton, Snackbar, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import "./View.scss";
import { useEffect } from "react";
import { viewFile } from "../../../../../store/slices/tprla/fileSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";
import FloatingButton from "../../../../../components/shared/FloatingButton/floatingButton";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import {
  useDeleteCommentMutation,
  useEditCommentMutation,
  useGetAllCommentsMutation,
  usePostCommentMutation,
  useReplyCommentMutation,
} from "../../../../../store/services/tprla/comment";
import { _getLoggedInUserDetails } from "../../../../../envUrl";
import { fileTypesConstants, tprlaProjectRoles } from "../../../tprlaUtils";
import PDFViewer from "../../../../../components/shared/PDFViewer/PDFViewer";
import { setLoading } from "../../../../../store/slices/dtms/loading";

const ViewAndComment = () => {
  const { fileDetails, isLoading, isSigned, signedBy, isError } = useSelector(
    (state) => state.file
  );
  const { userRoleTPA, tprlaUser } = useSelector((state) => state.user);

  const [open, setOpen] = useState(true);
  const [comments, setComments] = useState([]);
  const [commentInfo, setCommentInfo] = useState(true);
  const [sharedWith, setSharedWith] = useState([]);
  const [sharedWithUserDts, setSharedWithUserDts] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const user = _getLoggedInUserDetails();
  const [getAllComments, getAllCommentsResult] = useGetAllCommentsMutation();
  const [postComment, postCommentResult] = usePostCommentMutation();
  const [editComment, editCommentResult] = useEditCommentMutation();
  const [deleteComment, getDeleteCommentResult] = useDeleteCommentMutation();
  const [replyComment, getReplyCommentResult] = useReplyCommentMutation();

  useEffect(() => {
    const { isFetching, isSuccess, data, isError, error } = postCommentResult;
    if (isSuccess) {
      getAllComments({
        projectId: params.projectId,
        releaseId: params.fileId,
      });
      dispatch(setLoading(false));
    }
    if (isError) {
      confirmAlert({
        title: "Error",
        message: "Could not post the comment! Please try again later!",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [postCommentResult]);

  useEffect(() => {
    const clonedUsers = _.cloneDeep(tprlaUser);
    dispatch(viewFile(params.fileId)).then((res) => {
      var clonedSharedWith = _.cloneDeep(res.payload.sharedWith);
      clonedSharedWith = clonedSharedWith.map((user) => {
        const userDts = clonedUsers.find((u) => u.email === user);
        userDts.display = userDts?.firstName + " " + userDts?.lastName;
        userDts.id = userDts.email;
        return userDts;
      });
      setSharedWithUserDts(clonedSharedWith);
      setSharedWith(res.payload.sharedWith);
      getAllComments({
        projectId: params.projectId,
        releaseId: params.fileId,
      });
    });
  }, [isSigned, params.fileId, params.fileType, tprlaUser]);

  useEffect(() => {
    const { isFetching, isSuccess, data, isError, error } =
      getAllCommentsResult;
    if (isSuccess) {
      setComments(data.result.comments);
      setCommentInfo(data.result);
    }
    if (isError) {
      confirmAlert({
        title: "Error",
        message: "Unable to load the comments! Please try again later!",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [getAllCommentsResult]);

  useEffect(() => {
    const { isFetching, isSuccess, data, isError, error } = editCommentResult;

    if (isSuccess) {
      refreshComments();
    }
    if (isError) {
      confirmAlert({
        title: "Error",
        message: "Could not edit the comment! Please try again later.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [editCommentResult]);

  useEffect(() => {
    const { isFetching, isSuccess, data, isError, error } =
      getDeleteCommentResult;

    if (isSuccess) {
      refreshComments();
    }
    if (isError) {
      confirmAlert({
        title: "Error",
        message: "Could not delete the comment! Please try again later.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [getDeleteCommentResult]);

  useEffect(() => {
    const { isFetching, isSuccess, data, isError, error } =
      getReplyCommentResult;

    if (isSuccess) {
      refreshComments();
    }
    if (isError) {
      confirmAlert({
        title: "Error",
        message: "Could not reply on the comment! Please try again later.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [getReplyCommentResult]);

  const handleClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const refreshComments = () => {
    getAllComments({
      projectId: params.projectId,
      releaseId: params.fileId,
    });
  };

  const postCommentHandler = (payload) => {
    dispatch(setLoading(true));
    postComment(payload);
  };

  return (
    <div className="view-main">
      {!isLoading && (
        <>
          <Box className="view-main-header" sx={{ justifyContent: "flex-end" }}>
            <>
              <Tooltip title="Back to file list">
                <IconButton
                  onClick={() => navigate(`/tprla/${params.projectId}`)}
                >
                  <span className="Appkit4-icon icon-left-chevron-fill ap-font-16" />
                </IconButton>
              </Tooltip>
              <Typography variant="h5">{fileDetails.fileName}</Typography>
            </>
          </Box>

          {!isError &&
            fileDetails.fileUrl !== "" &&
            !_.isEmpty(sharedWithUserDts) &&
            sharedWithUserDts?.length > 0 && (
              <PDFViewer
                url={fileDetails.fileUrl}
                isComment={
                  sharedWith?.includes(user.email) &&
                  params.fileType !== fileTypesConstants.report
                }
                commentsData={comments}
                disableComment={signedBy !== null}
                type={params.fileType}
                anchor="left"
                postComment={postCommentHandler}
                refreshComments={refreshComments}
                editComment={editComment}
                replyComment={replyComment}
                deleteComment={deleteComment}
                sharedWith={sharedWithUserDts}
              />
            )}

          {!_.isEmpty(sharedWith) &&
            sharedWith.includes(user.email) &&
            signedBy === null &&
            isSigned === false &&
            fileDetails.fileType === "RELEASE" &&
            userRoleTPA !== tprlaProjectRoles.client && (
              <FloatingButton
                buttonText={"E-Sign"}
                buttonClick={() =>
                  navigate(
                    `/tprla/${params.projectId}/sign/${params.fileType}/${params.fileId}`
                  )
                }
              />
            )}
          {signedBy === null &&
            sharedWith.includes(user.email) &&
            fileDetails.fileType === "RELEASE" && (
              <Snackbar
                open={open}
                message="Add comment in this document by highlighting the words or lines."
                autoHideDuration={4000}
                onClose={handleClose}
              />
            )}
        </>
      )}
    </div>
  );
};

export default ViewAndComment;
