import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  _getAuthHeaders,
  _getAuthToken,
  _getClientID,
  _getClientSecret,
  _getDTMSProxy,
  _getIdToken,
  _getRedirectUri,
} from "../../../envUrl";
import api from "../../api";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: _getDTMSProxy(),
  }),
  endpoints: (builder) => ({
    getAccessCode: builder.mutation({
      query: (code) => {
        const formData = new URLSearchParams();
        formData.append("grant_type", "authorization_code");
        formData.append("code", code);
        formData.append("redirect_uri", _getRedirectUri());
        formData.append("client_id", _getClientID());

        return {
          url: api.getAccessCode,
          method: "POST",
          body: formData,
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
          },
        };
      },
    }),
    getRefreshCode: builder.mutation({
      query: ({ code, refreshToken }) => {
        const formData = new URLSearchParams();
        formData.append("grant_type", "refresh_token");
        formData.append("redirect_uri", _getRedirectUri());
        formData.append("client_id", _getClientID());
        formData.append("code", code);
        formData.append("client_secret", _getClientSecret());
        formData.append("refresh_token", refreshToken);

        return {
          url: api.getRefreshCode,
          method: "POST",
          body: formData.toString(),
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
            Authorization: _getAuthToken(),
            param: _getIdToken(),
          },
        };
      },
    }),
    revokeToken: builder.mutation({
      query: (accessToken) => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.revokeToken,
          method: "POST",
          params: { token: accessToken, client_id: _getClientID() },
          body: {},
          headers: authHeaders,
        };
      },
    }),
    refreshSession: builder.mutation({
      query: () => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.refreshSession,
          method: "GET",
          headers: authHeaders,
        };
      },
    }),
  }),
});

export const {
  useGetAccessCodeMutation,
  useGetRefreshCodeMutation,
  useRevokeTokenMutation,
  useRefreshSessionMutation,
} = authApi;
