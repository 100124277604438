import { useEffect, useState } from "react";
import ModalDialog from "../../../../components/shared/Modal/Modal";
import { useDeleteFileMutation } from "../../../../store/services/tprla/files";
import { confirmAlert } from "react-confirm-alert";
import { setLoading } from "../../../../store/slices/dtms/loading";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const Delete = ({
  open = false,
  fileDetails = {},
  getFiles = () => {},
  onCloseModal = () => {},
}) => {
  const [modalOptions, setModalOptions] = useState({
    title: "Delete confirmation",
    option: {
      open: false,
      maxWidth: "sm",
      actionButtonName: "Delete",
      shouldCloseIconVisible: true,
      twoActionButtonRequired: true,
      actionSecondaryButtonName: "No",
    },
  });
  const params = useParams();
  const dispatch = useDispatch();

  const [deleteFile, deleteFileResult] = useDeleteFileMutation();

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = deleteFileResult;
    if (isSuccess) {
      console.log("Yay! ", data);
      confirmAlert({
        title: "Deleted",
        message: "File deleted successfully!",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              getFiles();
            },
          },
        ],
      });
    }
    if (isError) {
      console.log("Oops! ", error);
      const errorMessage =
        error.data && error.data.error
          ? error.data.error.message
            ? error.data.error.message
            : "Unable to delete file"
          : "Unable to delete file";
      confirmAlert({
        title: "Error",
        message: errorMessage,
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
    dispatch(setLoading(isLoading));
  }, [deleteFileResult]);

  useEffect(() => {
    setModalOptions({
      ...modalOptions,
      option: { ...modalOptions.option, open: open },
    });
  }, [open]);

  const onSave = () => {
    deleteFile({
      projectId: params.projectId,
      fileId: fileDetails.id,
    });
    onClose();
  };

  const onClose = () => {
    onCloseModal();
  };

  return (
    <ModalDialog {...modalOptions} onSave={onSave} onClose={onClose}>
      Are you sure you want to delete <strong>{fileDetails.fileName}</strong>?
    </ModalDialog>
  );
};

export default Delete;
