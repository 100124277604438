export default {
  wordBreak: "break-all",
  control: {
    fontSize: 14,
    fontWeight: "normal",
  },

  "&multiLine": {
    control: {
      fontSize: "1rem",
      fontWeight: 500,
      fontFamily: "unset",
    },
    highlighter: {
      //padding: "5px 14px",
      border: "1px solid transparent",
    },
    input: {
      //padding: "5px 14px",
      //   border: "1px solid silver",
      //   borderRadius: "4px",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    zIndex: 100,

    list: {
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#ff000015",
      },
    },
  },
};
