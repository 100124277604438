import React, { Component } from "react";
import "./Error.scss";
import _ from "lodash";

export default function fallbackRender(props) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  const { error } = props;
  console.log("Error: ", error);
  return (
    <div className="something-went-wrong">
      <h3>Service is down. Please try after sometimes.</h3>
    </div>
  );
}

// class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false, error: "" };
//   }

//   static getDerivedStateFromError(error) {
//     return { hasError: true };
//   }

//   componentDidCatch(error, info) {
//     console.log("Service Down Error: ", error);

//     this.setState({ hasError: true, error });
//   }

//   render() {
//     // const { serviceError } = this.props;
//     if (this.state.hasError) {
//       return (
//         <div className="something-went-wrong">
//           <h3>Service is down. Please try after sometimes.</h3>
//         </div>
//       );
//     }
//     return this.props.children;
//   }
//   componentWillUnmount() {
//     this.setState({ hasError: false, error: "" });
//   }
// }

// export default ErrorBoundary;
