import { useEffect } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import {
  viewFile,
  submitEsignHandler,
} from "../../../../../store/slices/tprla/fileSlice";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { _getLoggedInUserDetails } from "../../../../../envUrl";
import "./Esign.scss";
import PDFViewer from "../../../../../components/shared/PDFViewer/PDFViewer";

const ESign = () => {
  const { fileDetails, isError } = useSelector((state) => state.file);

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (fileDetails.fileUrl === "") {
      dispatch(viewFile(params.fileId));
    }
  }, []);

  const submitSignature = (payload) => {
    dispatch(submitEsignHandler(payload)).then((res) => {
      if (res && res.type.includes("fulfilled")) {
        window.location.href = `/tprla/${params.projectId}/view/SIGNED_RELEASE/${res.payload.fileId}`;
      } else {
        dispatch(viewFile(params.fileId));
      }
    });
  };

  return (
    <div className="esign-main">
      <Box className="view-main-header" sx={{ justifyContent: "flex-start" }}>
        <Tooltip title="Back to file list">
          <IconButton
            onClick={() =>
              (window.location.href = `/tprla/${params.projectId}`)
            }
          >
            <span className="Appkit4-icon icon-left-chevron-fill ap-font-16" />
          </IconButton>
        </Tooltip>
        <Typography variant="h5">{fileDetails.fileName}</Typography>
      </Box>
      {!isError && fileDetails.fileUrl !== "" && (
        <PDFViewer
          url={fileDetails.fileUrl}
          isEsign={true}
          submitSignature={submitSignature}
          goToView={() =>
            (window.location.href = `/tprla/${params.projectId}/view/${params.fileType}/${params.fileId}`)
          }
        />
      )}
    </div>
  );
};

export default ESign;
