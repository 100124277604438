import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Protected.scss";
import _ from "lodash";

const Protected = ({ component, access = null, isUserAdmin = true }) => {
  const location = useLocation();

  const [rootPath, setRootPath] = useState(null);

  useEffect(() => {
    setRootPath(`/${location.pathname.split("/")[1]}`);
  }, [location]);

  if (isUserAdmin) {
    return component;
  } else if (
    rootPath !== null && access !== null ? access.includes(rootPath) : true
  ) {
    return component;
  } else {
    return (
      <div className="not-authorised">
        <span
          className="Appkit4-icon icon-alert-outline ap-font-40"
          style={{
            color: "red",
            marginBottom: "20px",
          }}
        />
        <h3>You are not authorised to access this page.</h3>
      </div>
    );
  }
};

export default Protected;
