export const dtms = "DTMS";
export const admin = "ADMIN";
export const modules = ["TPRLA", "Energy Fund", "Media", "Test"];
export const emailPattern =
  /^\w.+@[a-zA-Z-_0-9]+?(\.[a-zA-Z]+)?\.[a-zA-Z]{2,3}(.[a-zA-Z]{2,3})?$/;
export const passwordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;

export const moduleNames = {
  tprla: "TPRLA",
  energyFund: "Energy Fund",
  media: "Media",
  test: "Test",
};

export const inviteUserConstants = {
  emailLanguage: "en",
  state: "123456",
};

export const defaultDevUser = {
  userId: 13,
  firstName: "Sangita",
  lastName: "Samata",
  email: "sangita.samanta@pwc.com",
  moduleAccess: [
    {
      moduleName: "DTMS",
      moduleId: 0,
      role: "Admin",
      isDeleted: false,
    },
    // {
    //   moduleName: "TPRLA",
    //   moduleId: 1,
    //   role: "Admin",
    //   isDeleted: true,
    // },
    // {
    //   moduleName: "Energy Fund",
    //   moduleId: 2,
    //   role: "Member",
    //   isDeleted: false,
    // },
  ],
};

export const regApps = ["ESRA", "DTMS", "DMT"];

export const tprlaFileTypes = {
  release: "RELEASE",
  report: "REPORT",
};
