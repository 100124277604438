import { createSlice } from "@reduxjs/toolkit";
import { _getLoggedInUserDetails } from "../../../envUrl";
import _ from "lodash";

const moduleSlice = createSlice({
  name: "module",
  initialState: { moduleDetails: [], menus: [], updateAccessModules: [] },
  reducers: {
    setModuleDetails(state, action) {
      state.moduleDetails = action.payload;
    },
    setMenuDetails(state, action) {
      state.menus = action.payload;
    },
    setUpdateAccessModules(state, action) {
      const userDetails = _getLoggedInUserDetails();
      const clonedPayload = _.cloneDeep(action.payload);
      var updateAccessModules = [];
      clonedPayload.forEach((module) => {
        let i = userDetails.modules.findIndex((m) => m.name === module.name);
        if (i === -1) {
          updateAccessModules.push(module);
        }
      });
      state.updateAccessModules = updateAccessModules;
    },
  },
});

export const { setModuleDetails, setMenuDetails, setUpdateAccessModules } =
  moduleSlice.actions;
export default moduleSlice.reducer;
