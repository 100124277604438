export const fileTypes = ["Release", "Report"];

export const tprlaProjectRoles = {
  auditor: "AUDITOR",
  client: "CLIENT",
  thirdparty: "THIRDPARTY",
};

export const tprlaModuleRoles = {
  admin: "ADMIN",
  nonAdmin: "USER",
};

export const uploadFileTypes = {
  report: "REPORT",
  release: "RELEASE",
};

export const fileTypesConstants = {
  report: "REPORT",
  release: "RELEASE",
  signedRelease: "SIGNED_RELEASE",
};

export const tpraDeleteConfirmMsg = {
  reportUnshared: `Are you sure you want to delete {fileName} file?`,
  reportSharedWithClient: `If you delete {fileName} file, all client users will lose access and will no longer be able to view it. Do you confirm you still want to delete it?`,
  reportSharedWithTP: `If you delete {fileName} file, all third party users will lose access and will no longer be able to view it. Do you confirm you still want to delete it?`,
  reportSharedWithBoth: `If you delete {fileName} file, all client and third party users will lose access and will no longer be able to view it. Do you confirm you still want to delete it?`,
  releaseUnshared: `Are you sure you want to delete {fileName} file?`,
  releaseShared: `{fileName} file is already shared with third party users. If you delete it, all third party users will lose access. Do you still want to delete it?`,
  releaseSigned: `{fileName} file is already shared with and signed by third party users. If you delete it, all third party users will lose access and all the signed copies will also be deleted. Do you confirm you still want to delete it?`,
};
