import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { moduleNames } from "../../../utils/constants";
import { _getLoggedInUserDetails } from "../../../envUrl";

const userSlice = createSlice({
  name: "user",
  initialState: {
    roles: [],
    tprlaUser: [],
    userRoleTPA: "",
  },
  reducers: {
    setTprlaUser: (state, action) => {
      const loggeInUser = _getLoggedInUserDetails();
      const clonedPayload = _.cloneDeep(action.payload);
      clonedPayload.forEach((user) => {
        if (user.email === loggeInUser.email) {
          const role = user.modules.find((m) => m.name === moduleNames.tprla)
            ?.role.name;
          state.userRoleTPA = role;
        }
      });
      state.tprlaUser = action.payload;
    },
    setTprlaRole: (state, action) => {
      state.roles = action.payload;
    },
  },
});

export const { setTprlaUser, setTprlaRole } = userSlice.actions;
export default userSlice.reducer;
