import { Box, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import CommentView from "./Comment/CommentView";
import { useDispatch } from "react-redux";
import { setViewComment } from "../../../store/slices/tprla/fileSlice";

const CommentSidePanel = ({
  comments,
  jumpToHighlightArea,
  refreshComments,
  disableComment,
  editComment = () => {},
  replyComment = () => {},
  deleteComment = () => {},
  sharedWith = [],
}) => {
  const dispatch = useDispatch();

  return (
    <Paper className="drawer-container">
      <div
        style={{
          overflow: "auto",
        }}
        className="comment-sidebar"
      >
        <div className="comment-sidebar__header">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6">
              Comments ({`${comments.length}`})
            </Typography>
          </Box>
          <Tooltip title="Full Comments View">
            <IconButton onClick={() => dispatch(setViewComment())}>
              <span className="Appkit4-icon icon-grid-view-outline ap-font-16 icon-grey-dark" />
            </IconButton>
          </Tooltip>
        </div>
        {comments?.length === 0 && (
          <div className="no-comments">
            <Typography variant="p">There are no comments.</Typography>
            <Typography>
              Please mark the contents and add your relevant comments.
            </Typography>
          </div>
        )}
        {comments?.map((comment) => {
          return (
            <CommentView
              comment={comment}
              jumpToHighlightArea={jumpToHighlightArea}
              refreshComments={refreshComments}
              disableComment={disableComment}
              editComment={editComment}
              replyComment={replyComment}
              deleteComment={deleteComment}
              sharedWith={sharedWith}
            />
          );
        })}
      </div>
    </Paper>
  );
};

export default CommentSidePanel;
