import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { _getDTMSProxy, _getLoggedInUserDetails } from "../../../envUrl";
import api from "../../api";
import { b64toBlob } from "../../../utils/utilFns";
import { _getAuthHeaders } from "../../../envUrl";
import { confirmAlert } from "react-confirm-alert";
import { trackPromise } from "react-promise-tracker";
const proxy = _getDTMSProxy();

let initialState = {
  user: "",
  fileDetails: {},
  base64EncodedData: "",
  response: [],
  base64response: "",
  isLoading: false,
  isError: false,
  message: "",
  isNotification: false,
  signedThirdParties: [],
  isDisabled: false,
  isSigned: false,
  signedBy: null,
  isCommented: false,
  viewComment: false,
  signedThirdPartiesData: "",
  agreedByTP: null,
  signedByTP: null,
};

export const viewFile = createAsyncThunk(
  "viewFile",
  async (payload, { rejectWithValue }) => {
    try {
      const authHeaders = _getAuthHeaders();
      const response = await trackPromise(
        axios.get(proxy + api.viewFile, {
          params: {
            file_id: payload,
          },
          headers: {
            ...authHeaders,
          },
        })
      );
      const data = response.data;
      const blob = b64toBlob(data.result.base64EncodedData);
      const blobUrl = URL.createObjectURL(blob);
      const responseBody = {
        fileId: data.result.fileId,
        fileName: data.result.filename,
        fileType: data.result.fileType,
        base64EncodedData: data.result.base64EncodedData,
        blobUrl,
        sharedWith: data.result.sharedWith,
        signedBy: data.result.signedBy,
      };
      return responseBody;
    } catch (err) {
      console.log("Error:", err);
      return rejectWithValue(err);
    }
  }
);

export const submitEsignHandler = createAsyncThunk(
  "submitEsign",
  async (payload, { rejectWithValue }) => {
    try {
      const authHeaders = _getAuthHeaders();
      const response = await trackPromise(
        axios.post(proxy + api.editPdf, payload.body, {
          params: { projectId: payload.projectId },
          headers: { ...authHeaders },
        })
      );
      const data = response;
      if (data.data.result.stream === null) {
        throw "Please Provide a proper release letter.";
      }
      const responseBody = {
        base64: data.data.result.stream,
        fileId: data.data.result.fileId,
      };
      return responseBody;
    } catch (err) {
      console.log("Error: ", err);
      if (err === "Please Provide a proper release letter.") {
        confirmAlert({
          title: "Error",
          message: "Please provide a proper release letter.",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
        return rejectWithValue(err);
      } else if (err !== "Please Provide a proper release letter.") {
        confirmAlert({
          title: "Error",
          message: "Unable to esign! Please try again.",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
        return rejectWithValue(err.response.data.result);
      } else {
        confirmAlert({
          title: "Error",
          message: "Unable to esign! Please try again.",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
        return rejectWithValue(err);
      }
    }
  }
);

export const downloadFile = createAsyncThunk(
  "downloadFile",
  async (file, { rejectWithValue }) => {
    try {
      const authHeaders = _getAuthHeaders();
      const response = await trackPromise(
        axios.post(proxy + api.downloadFile, null, {
          params: {
            file_id: file.id,
          },
          headers: {
            ...authHeaders,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          responseType: "blob",
        })
      ).then((payload) => {
        var contentType = payload.type;
        const url = window.URL.createObjectURL(
          new Blob([payload.data], { type: contentType })
        );
        const link = document.createElement("a");
        link.download = payload.filename;
        link.href = url;
        link.setAttribute("download", file.fileName);
        document.body.appendChild(link);
        link.click();
      });
      return response;
    } catch (err) {
      console.log("Error: ", err);
      return rejectWithValue(err);
    }
  }
);

const filesSlice = createSlice({
  name: "file",
  initialState: initialState,
  reducers: {
    fileDetailsTransfer(state, action) {
      state.fileDetails = action.payload;
    },
    base64Transfer(state, action) {
      state.base64EncodedData = action.payload;
    },
    currentUser(state, action) {
      state.user = action.payload;
    },
    setIsFileLoading(state, action) {
      state.isLoading = action.payload;
    },
    closeIsErrorNotification(state, action) {
      state.isNotification = false;
    },
    setIsDisabled(state, action) {
      state.isDisabled = action.payload;
    },
    setIsCommented(state, action) {
      state.isCommented = action.payload;
    },
    setViewComment(state, action) {
      state.viewComment = !state.viewComment;
    },
    resetViewComment(state, action) {
      state.viewComment = false;
    },
    resetfileUrl(state, action) {
      state.fileUrl = "";
    },
    setSignedTPData(state, action) {
      state.signedThirdPartiesData = action.payload;
    },
    setAgreedByTP(state, action) {
      state.agreedByTP = action.payload;
    },
    setSignedByTP(state, action) {
      state.signedByTP = action.payload;
    },
    resetIsSigned(state, action) {
      state.isSigned = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewFile.pending, (state, action) => {
        state.isError = false;
        state.isLoading = true;
        state.fileDetails.fileUrl = "";
      })
      .addCase(viewFile.fulfilled, (state, action) => {
        state.isError = false;
        state.response.push(action.payload);
        // state.fileDetails.fileId = action.payload.fileId;
        state.fileDetails.fileName = action.payload.fileName;
        state.fileDetails.fileType = action.payload.fileType;
        state.fileDetails.fileUrl = action.payload.blobUrl;
        state.base64EncodedData = action.payload.base64EncodedData;
        state.signedBy = action.payload.signedBy;
        state.isLoading = false;
      })
      .addCase(viewFile.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        confirmAlert({
          title: "Error",
          message: "Unable to view file",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
      })
      .addCase(submitEsignHandler.pending, (state, action) => {
        state.isError = false;
        state.isLoading = true;
        state.isDisabled = false;
        state.isSigned = false;
      })
      .addCase(submitEsignHandler.fulfilled, (state, action) => {
        state.isError = false;
        state.message = "Submitted Successfully";
        var base64result = action.payload.base64.split(",")[1];
        state.base64response = base64result;
        const blob = b64toBlob(base64result);
        const blobUrl = URL.createObjectURL(blob);
        state.fileDetails.fileUrl = blobUrl;
        state.fileDetails.fileId = action.payload.fileId;
        state.isLoading = false;
        confirmAlert({
          title: "E-Sign Successful",
          message:
            "E-Sign has been done successfully. You can view the signed PDF.",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
        state.isDisabled = true;
        state.isSigned = true;
      })
      .addCase(submitEsignHandler.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isDisabled = false;
        state.isSigned = false;
        console.log(action.payload);
      })
      .addCase(downloadFile.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(downloadFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        confirmAlert({
          title: "Download",
          message: "Downloaded Successfully.",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
      })
      .addCase(downloadFile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        confirmAlert({
          title: "Error",
          message: "Unable to download file! Please try again.",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
      });
  },
});

export const {
  fileDetailsTransfer,
  base64Transfer,
  currentUser,
  setIsFileLoading,
  closeIsErrorNotification,
  setIsDisabled,
  setIsCommented,
  setViewComment,
  resetViewComment,
  resetfileUrl,
  setSignedTPData,
  setAgreedByTP,
  setSignedByTP,
  resetIsSigned,
} = filesSlice.actions;

export default filesSlice.reducer;
