import React from "react";
import { useState, useEffect } from "react";
import "./Header.scss";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Link,
} from "@mui/material";
import { dtms } from "../../utils/constants";
import { useNavigate } from "react-router";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import ModalDialog from "../shared/Modal/Modal";
import { _getLoggedInUserDetails } from "../../envUrl";
import useDidMountEffect from "../shared/CustomHooks/didMountEffect";
import { useUpdatePublicModuleAccessMutation } from "../../store/services/dtms/user";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/dtms/loading";

const Header = ({
  isLoggedIn = false,
  menu = [],
  logout = () => {},
  updateModuleList = [],
  onAccessUpdate = () => {},
  isUserAdmin = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userDetails = _getLoggedInUserDetails();
  const [updateModuleAccess, updateModuleAccessResult] =
    useUpdatePublicModuleAccessMutation();

  const [isAdmin, setIsAdmin] = useState();
  const [anchorElUser, setAnchorElUser] = useState();
  const [isAdminMenuActive, setIsAdminMenuActive] = useState(
    sessionStorage.getItem("isAdminMenu") === "Y" ? true : false
  );
  const [menuToRender, setMenuToRender] = useState([]);
  const [updateAccessModules, setUpdateAccessModules] = useState({});
  const [updateAccessModal, setUpdateAccessModal] = useState({
    title: "Update Access",
    option: {
      open: false,
      maxWidth: "sm",
      actionButtonName: "Update",
    },
    error: "",
    modules: updateModuleList,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (isLoggedIn) {
      setUpdateAccessModal({ ...updateAccessModal, modules: updateModuleList });
    }
  }, [updateModuleList]);

  useEffect(() => {
    if (isLoggedIn) {
      setIsAdmin(isUserAdmin);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const clonedMenu = _.cloneDeep(menu);
      var filteredMenu = [];
      if (isAdmin && isAdminMenuActive) {
        filteredMenu = clonedMenu.filter(
          (item) => item.menu_type === "admin" || item.menu_type === "both"
        );
      } else {
        filteredMenu = clonedMenu.filter((item) => item.menu_type !== "admin");
      }
      setMenuToRender(filteredMenu);
    }
  }, [menu, isAdminMenuActive, isAdmin]);

  useDidMountEffect(() => {
    const { isLoading, isError, isSuccess, data, error } =
      updateModuleAccessResult;
    if (isSuccess) {
      onAccessUpdate();
      confirmAlert({
        title: "Success",
        message: "Your access has been successfully updated!",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
    if (isError) {
      confirmAlert({
        title: "Error",
        message: "Unable to fetch module roles details",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
    dispatch(setLoading(isLoading));
  }, [updateModuleAccessResult]);

  useDidMountEffect(() => {
    if (isAdmin) {
      navigate("/home");
    } else {
      console.log(menuToRender);
      navigate(menuToRender[0]?.modulePath);
    }
  }, [isAdminMenuActive]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleUpdateAccess = () => {
    openUpdateAccessModal();
    handleCloseUserMenu();
  };

  const handleToggleMenu = () => {
    setIsAdminMenuActive(!isAdminMenuActive);
    sessionStorage.setItem("isAdminMenu", isAdminMenuActive ? "N" : "Y");
    handleCloseUserMenu();
  };

  const handleFeedback = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLScBW6F2B15lhJs1In0HxhLLz739Pt9sKtpX_Fxf4EnLVu5-aQ/viewform",
      "_blank"
    );
    handleCloseUserMenu();
  };

  const handleLogout = () => {
    logout();
    handleCloseUserMenu();
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const openUpdateAccessModal = () => {
    setUpdateAccessModal({
      ...updateAccessModal,
      option: { ...updateAccessModal.option, open: true },
    });
  };

  const closeUpdateAccessModal = () => {
    setUpdateAccessModal({
      ...updateAccessModal,
      option: { ...updateAccessModal.option, open: false },
      error: "",
    });
    setUpdateAccessModules({});
  };

  const handleOnUpdate = () => {
    if (Object.values(updateAccessModules).includes(true)) {
      const moduleAccessArr = [];
      updateAccessModal.modules.forEach((module) => {
        if (updateAccessModules[module.name]) {
          moduleAccessArr.push({
            name: module.name,
            role: {
              name: module.roles[0].name,
              isActive: "Y",
            },
          });
        }
      });
      var clonedUserDts = _.cloneDeep(userDetails);
      clonedUserDts.modules = moduleAccessArr;
      updateModuleAccess(clonedUserDts);
      closeUpdateAccessModal();
    } else {
      setUpdateAccessModal({
        ...updateAccessModal,
        error: "Please select atleast one module to proceed",
      });
    }
  };

  const handleModuleChange = (e) => {
    setUpdateAccessModules({
      ...updateAccessModules,
      [e.target.name]: e.target.checked,
    });
    if (e.target.checked) {
      setUpdateAccessModal({
        ...updateAccessModal,
        error: "",
      });
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar position="static" className="dtms-header">
        <Container maxWidth="xl">
          <Toolbar disableGutters style={{ minHeight: "50px" }}>
            <div className="a-pwc-logo-grid" />
            <div className="dtms-header-app-name" style={{ flexGrow: 1 }}>
              Deals Technology Managed Services
            </div>

            {/* <Tooltip title="Info">
              <IconButton
                className="dtms-header-iconbtn"
                href="https://pwcdeu.sharepoint.com/sites/GBL-ADV-DealsTechnologyManagedServices"
                target="_blank"
              >
                <InfoOutlinedIcon
                  className="dtms-header-help"
                  fontSize="small"
                />
              </IconButton>
            </Tooltip> */}

            <Tooltip title="FAQ">
              <IconButton
                className="dtms-header-iconbtn"
                onClick={handleMenuClick}
              >
                <span className="Appkit4-icon icon-help-question-outline ap-font-16 icon-grey-dark" />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              onClick={handleMenuClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiSvgIcon-root": {
                    ml: -0.5,
                    mr: 1,
                  },
                },
              }}
              transformOrigin={{ horizontal: "center", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleMenuClose}>
                <span
                  className="Appkit4-icon icon-link-outline ap-font-24 icon-grey-dark"
                  style={{ marginRight: "8px" }}
                />
                <Link
                  href="https://docs.google.com/forms/d/e/1FAIpQLSczzI0-56ZD6-9tg4-9_s33waQYSqHO601EmLzyaZL7loYiBQ/viewform"
                  target="_blank"
                  underline="none"
                >
                  Issue management
                </Link>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleMenuClose}>
                <span
                  className="Appkit4-icon icon-link-outline ap-font-24 icon-grey-dark"
                  style={{ marginRight: "8px" }}
                />
                <Link
                  href="https://pwcdeu.sharepoint.com/sites/GBL-ADV-DealsTechnologyManagedServices"
                  target="_blank"
                  underline="none"
                >
                  Knowledge site
                </Link>
              </MenuItem>
            </Menu>

            {isLoggedIn && (
              <Box sx={{ flexGrow: 0, marginLeft: "10px" }}>
                <Tooltip title="User menu">
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{ p: 0 }}
                    className="dtms-header-username"
                  >
                    <div>
                      {userDetails.firstName[0] + userDetails.lastName[0]}
                    </div>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  className="user-menu"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem style={{ pointerEvents: "none" }}>
                    <Typography textAlign="center">
                      WELCOME
                      {" " +
                        userDetails.firstName.toUpperCase() +
                        " " +
                        userDetails.lastName.toUpperCase()}
                      !
                    </Typography>
                  </MenuItem>
                  <Divider />
                  {!isAdmin && (
                    <MenuItem
                      className="dtms-header-menuitem"
                      onClick={handleUpdateAccess}
                      disabled={
                        _.isEmpty(updateAccessModal.modules) &&
                        updateAccessModal.modules.length === 0
                      }
                    >
                      <Typography textAlign="center">Update access</Typography>
                    </MenuItem>
                  )}
                  {isAdmin && (
                    <MenuItem
                      className="dtms-header-menuitem"
                      onClick={handleToggleMenu}
                    >
                      <Typography textAlign="center">
                        {isAdminMenuActive ? "General menu" : "Admin menu"}
                      </Typography>
                    </MenuItem>
                  )}
                  <MenuItem
                    className="dtms-header-menuitem"
                    onClick={handleFeedback}
                  >
                    <Typography textAlign="center">Feedback</Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem className="logout-menuitem">
                    <Button variant="contained" onClick={handleLogout}>
                      Logout
                    </Button>
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Toolbar>
          {isLoggedIn && (
            <Toolbar disableGutters style={{ minHeight: "50px" }}>
              <Box
                className="nav-links"
                sx={{ borderColor: "divider" }}
                style={{ flexGrow: 1 }}
              >
                {menuToRender
                  .filter((menu) => menu.name !== dtms)
                  .map((menu) => {
                    return (
                      <NavLink
                        to={menu.modulePath ? menu.modulePath : `/media`}
                        className={({ isActive, isPending }) =>
                          isPending
                            ? "nav-link nav-link-pending"
                            : isActive
                            ? "nav-link nav-link-active"
                            : "nav-link"
                        }
                      >
                        {menu.description}
                      </NavLink>
                    );
                  })}
              </Box>
            </Toolbar>
          )}
        </Container>
      </AppBar>
      <ModalDialog
        {...updateAccessModal}
        onClose={closeUpdateAccessModal}
        onSave={handleOnUpdate}
        className="update-modal"
      >
        <FormLabel className="modal-text">
          Please select the modules you want the access
        </FormLabel>
        <FormGroup style={{ padding: "10px 0 0 50px" }}>
          {updateAccessModal.modules.map((module) => (
            <FormControlLabel
              className="modal-text"
              control={
                <Checkbox
                  checked={updateAccessModules[module.name]}
                  onChange={handleModuleChange}
                  name={module.name}
                />
              }
              label={module.name}
            />
          ))}
        </FormGroup>
        <FormHelperText>{updateAccessModal.error}</FormHelperText>
      </ModalDialog>
    </>
  );
};

export default Header;
