// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf-viewer-container {
  height: 550px;
  width: 100%;
}

.pdf-error-message {
  background-color: #e72b33;
  border-radius: 0.25rem;
  color: #fff;
  padding: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/PDFViewer/PDFViewer.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,WAAA;AADF;;AAKA;EACE,yBCmDa;EDlDb,sBAAA;EACA,WAAA;EACA,eAAA;AAFF","sourcesContent":["@import \"../../../variables.scss\";\n\n.pdf-viewer-container {\n  height: 550px;\n  width: 100%;\n  // overflow-y: scroll;\n}\n\n.pdf-error-message {\n  background-color: $secondaryRed;\n  border-radius: 0.25rem;\n  color: #fff;\n  padding: 0.5rem;\n}\n","@import \"./assets/lib/appkit-styles/scss/variables\";\n\n$Grey: #f6f6f7;\n$DarkGrey: #e9e9ea;\n$White: #fff;\n$Black: #000;\n\n$textGrey: #9b9a9c;\n$textDarkGrey: #403f41;\n$textMediumGrey: #8a8c8d;\n$textBlack: #636466;\n$textRed: #df301e;\n\n$backgroundGrey: #fbfbfc;\n$backgroundDarkGrey: #f8f9fa;\n\n$userRed: #e0301e;\n$userOrange: #eb8c00;\n$userYellow: #fcb133;\n\n$tableRowBorder: #909294;\n$hrHeaderColor: #919395;\n$hrMenuColor: #636466;\n$bgUser: #edeeee;\n\n$sideMenuHoverBGColor: #f6f6f7;\n$sideMenuActiveBGColor: #f6f6f7;\n$sideMenuActiveColor: #444;\n\n//Territory status Color\n$territoryApproved: #22992e;\n$territoryInproggress: #ffbf1f;\n$territoryRejected: #c52a1a;\n$territoryNotSelected: #dddddd;\n\n// Button Spec\n$buttonColor: #df301e;\n$buttonTextColor: #ffffff;\n$buttonHoverColor: #e75443;\n\n// Basic Colors\n$orange: #d04a20;\n$charcoal: #2d2d2d;\n$grey: #dedede;\n\n$orange0: #fedacc;\n$orange1: #fdab8d;\n$orange2: #fb7c4d;\n$orange3: #e45c2b;\n\n$charcoal0: #d5d5d5;\n$charcoal1: #ababab;\n$charcoal2: #818181;\n$charcoal3: #575757;\n\n$grey0: #f8f8f8;\n$grey1: #f2f2f2;\n$grey2: #ebebeb;\n$grey3: #e5e5e5;\n\n$secondaryRed: #e72b33;\n\n$white: #fff;\n$Grey: #f6f6f7;\n\n// Font Color\n\n$font-color-1: #696969;\n$font-color-2: #474747;\n$font-color-3: #252525;\n\n$dtms-font-family: \"PwC Helvetica Neue\" !important;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
