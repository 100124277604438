import { useState, Fragment, useEffect } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { highlightPlugin } from "@react-pdf-viewer/highlight";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import Tooltip from "@mui/material/Tooltip";
import "./PDFViewer.scss";
import { _getLoggedInUserDetails } from "../../../envUrl";
import { Button, Card, IconButton } from "@mui/material";
import {
  resetViewComment,
  setIsCommented,
} from "../../../store/slices/tprla/fileSlice";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import StickyPanel from "../StickyPanel/StickyPanel";
import { useDispatch } from "react-redux";
import CommentSidePanel from "./CommentSidePanel";
import EsignSidePanel from "./EsignSidePanel";
import _ from "lodash";
import Mentions from "../Mentions/Mentions";

const PDFViewer = ({
  url = "",
  isComment = false,
  isEsign = false,
  disableComment = true,
  commentsData = [],
  anchor = "right",
  submitSignature = () => {},
  goToView = () => {},
  postComment = () => {},
  refreshComments = () => {},
  editComment = () => {},
  replyComment = () => {},
  deleteComment = () => {},
  sharedWith = [],
}) => {
  const [message, setMessage] = useState("");
  const [mentions, setMentions] = useState([]);
  const [comments, setComments] = useState([]);
  const { viewComment, fileDetails, isError } = useSelector(
    (state) => state.file
  );
  const [isOpen, setIsOpen] = useState(false);

  const params = useParams();
  const dispatch = useDispatch();

  const user = _getLoggedInUserDetails();
  const noteEles = new Map();

  useEffect(() => {
    const clonedComment = _.cloneDeep(commentsData);
    setComments(clonedComment);
  }, [commentsData]);

  useEffect(() => {
    if (!isOpen) {
      dispatch(resetViewComment());
    }
  }, [isOpen]);

  const onMention = (e) => {
    const i = mentions.findIndex((m) => m === e);
    if (i === -1) {
      setMentions([...mentions, e]);
    }
  };

  const renderHighlightTarget = (props) => (
    <>
      {!disableComment && (
        <Tooltip title="Add a comment" arrow={true}>
          <div
            style={{
              position: "absolute",
              left: `${props.selectionRegion.left}%`,
              top: `${
                props.selectionRegion.top <= 85
                  ? props.selectionRegion.top + props.selectionRegion.height
                  : props.selectionRegion.top - props.selectionRegion.height - 7
              }%`,
            }}
            onClick={props.toggle}
            className="comment-dialog"
          >
            <IconButton
              disableRipple={true}
              onClick={() => {
                const viewerContainer = document.querySelector(".comment-icon");
                const page = document.querySelector(".rpv-core__inner-pages");
                const rect = viewerContainer.getBoundingClientRect();
                if (rect.bottom > 100 && rect.bottom < 500) {
                  page.scrollBy({
                    top: -200,
                    left: 0,
                    behavior: "smooth",
                  });
                }
              }}
              className="comment-icon"
            >
              <span className="Appkit4-icon icon-comment-fill ap-font-24" />
            </IconButton>
          </div>
        </Tooltip>
      )}
    </>
  );

  const renderHighlightContent = (props) => {
    const addNote = async () => {
      if (message !== "") {
        const payload = {
          projectId: params.projectId,
          releaseId: params.fileId,
          comments: [
            {
              comment: message,
              commenterId: user.id,
              commenterName: `${user.firstName} ${user.lastName}`,
              commenterEmail: user.email,
              highlightedText: props.selectedText,
              coordinates: props.highlightAreas,
              replyList: [],
              replyOf: "",
              commentTime: Date.now(),
              mentionedUserList: mentions,
            },
          ],
        };
        await postComment(payload);
        setIsCommented(true);
        setMessage("");
        setIsOpen(true);
        props.cancel();
      }
    };

    const cancelComment = () => {
      props.cancel();
      setMessage("");
    };

    return (
      <div
        style={{
          background: "#fff",
          border: "1px solid rgba(0, 0, 0, .3)",
          borderRadius: "2px",
          padding: "8px",
          position: "absolute",
          left: `${props.selectionRegion.left}%`,
          top: `${
            props.selectionRegion.top <= 85
              ? props.selectionRegion.top + props.selectionRegion.height
              : props.selectionRegion.top - 20
          }%`,
          zIndex: 30,
        }}
        className="comment-input-box"
      >
        <div style={{ maxHeight: "90px", overflowY: "auto" }}>
          <Mentions
            data={sharedWith}
            text={message}
            onChange={(e) => setMessage(e.target.value)}
            onAdd={onMention}
            isDisabled={false}
            style={{
              minHeight: "5rem",
              width: "180px",
              fontSize: "0.8rem",
              "&multiLine": {
                control: {
                  fontSize: "0.8rem !important",
                },
              },
            }}
          />
          {/* <textarea
            rows={3}
            style={{
              border: "1px solid rgba(0, 0, 0, .3)",
            }}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea> */}
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            marginTop: "8px",
          }}
        >
          <Button size="small" variant="outlined" onClick={cancelComment}>
            Cancel
          </Button>

          <Button
            variant="contained"
            size="small"
            onClick={addNote}
            disabled={message.trim() === "" ? true : false}
          >
            Add
          </Button>
        </div>
      </div>
    );
  };

  const jumpToNote = (note) => {
    if (noteEles.has(note.id)) {
      noteEles.get(note.id).scrollIntoView();
    }
  };

  const renderHighlights = (props) => (
    <div>
      {comments?.map((note) => (
        <>
          <Fragment key={note.id}>
            {note.coordinates
              .filter((area) => area.pageIndex === props.pageIndex)
              .map((area, idx) => (
                <div
                  key={idx}
                  style={Object.assign(
                    {},
                    {
                      background: "rgb(255,255,0,0.4)",
                    },
                    props.getCssProperties(area, props.rotation)
                  )}
                  onClick={() => jumpToNote(note)}
                  ref={(ref) => {
                    noteEles.set(note.id, ref);
                  }}
                  className="comment-highlight"
                >
                  {/* {viewComment && (
                      <Card className="comment-highlight-info">
                        <p>"{note.comment}"</p>
                        <small>- {note.commenterName}</small>
                      </Card>
                    )} */}
                </div>
              ))}
          </Fragment>
          {viewComment && (
            <Fragment key={note.id}>
              {note.coordinates[0].pageIndex === props.pageIndex && (
                <Card
                  style={Object.assign(
                    {},
                    props.getCssProperties(note.coordinates[0], props.rotation)
                  )}
                  className="comment-highlight-info"
                >
                  <h5>{note.highlightedText}</h5>
                  <p>{`"${note.comment}"`}</p>
                  <small>{`- ${note.commenterName}`}</small>
                </Card>
              )}
            </Fragment>
          )}
        </>
      ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    renderHighlightContent,
    renderHighlights,
  });

  const { jumpToHighlightArea } = highlightPluginInstance;

  const renderError = (error) => {
    let message = "";
    switch (error.name) {
      case "InvalidPDFException":
        message = "The document is invalid or corrupted";
        break;
      case "MissingPDFException":
        message = "The document is missing";
        break;
      case "UnexpectedResponseException":
        message = "Unexpected server response";
        break;
      case "FormatError":
        message = "Format not correct";
        break;
      case "UnknownErrorException":
        message = "Unexpected server response";
        break;
      default:
        message = "Cannot load the document";
        break;
    }
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <div className="pdf-error-message">{message}</div>
      </div>
    );
  };

  const renderSidePanel = () => {
    if (isComment) {
      return (
        <CommentSidePanel
          comments={comments}
          jumpToHighlightArea={jumpToHighlightArea}
          refreshComments={refreshComments}
          disableComment={disableComment}
          editComment={editComment}
          replyComment={replyComment}
          deleteComment={deleteComment}
          sharedWith={sharedWith}
        />
      );
    }
    if (isEsign) {
      return (
        <EsignSidePanel submitSignature={submitSignature} goToView={goToView} />
      );
    }
  };

  return (
    <div>
      {isComment || isEsign ? (
        <StickyPanel
          disableCloseBtn={isEsign}
          drawerStatus={isEsign ? true : isOpen}
          anchor={anchor}
          toggleDrawer={(input) => setIsOpen(input)}
          panelContent={renderSidePanel()}
          mainContent={
            fileDetails.fileUrl !== "" || !isError ? (
              <div
                style={
                  isEsign
                    ? {
                        flex: "1 1 0",
                        overflow: "auto",
                        height: 1000,
                      }
                    : {}
                }
                className={isEsign ? `viewer-container` : ""}
              >
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={url}
                    renderError={renderError}
                    plugins={isComment ? [highlightPluginInstance] : []}
                    defaultScale={1}
                  />
                </Worker>
              </div>
            ) : (
              <p>No File!</p>
            )
          }
        />
      ) : (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
          <Viewer
            fileUrl={url}
            renderError={renderError}
            plugins={[]}
            defaultScale={1}
          />
        </Worker>
      )}
    </div>
  );
};

export default PDFViewer;
