import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "./Loader.scss";
import { usePromiseTracker } from "react-promise-tracker";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const Loader = () => {
  const { promiseInProgress } = usePromiseTracker();
  const isLoading = useSelector((state) => state.loading.isLoading);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    //console.log("Loading: ", isLoading);
    setLoading(isLoading);
  }, [isLoading]);
  return (
    (loading || promiseInProgress) && (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  );
};

export default Loader;
