import { useEffect, useState } from "react";
import {
  Card,
  IconButton,
  Select,
  Button,
  FormHelperText,
  MenuItem,
  Typography,
  FormLabel,
} from "@mui/material";
import _ from "lodash";
import "./UploadFile.scss";
import FileDropzone from "./FileDropzone";
import { fileTypesConstants, uploadFileTypes } from "../../../tprlaUtils";
import { useGetAllFilesMutation } from "../../../../../store/services/tprla/files";
import { useDispatch } from "react-redux";
import { onUploadFile } from "../../../../../store/slices/tprla/uploadSlice";
import { useNavigate, useParams } from "react-router-dom";
import { _getLoggedInUserDetails } from "../../../../../envUrl";

const UploadFile = () => {
  const params = useParams();

  const [selectedFile, setSelectedFile] = useState();
  const [getAllFiles] = useGetAllFilesMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fileDetails, setFileDetails] = useState({
    file: {},
    updatedFile: "",
    created_by: null,
    type: "",
  });
  const [fileSys, setFileSys] = useState("");
  const [reportDisabled, setReportDisabled] = useState(true);
  const [typeError, setTypeError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState({
    fileError: "",
    typeError: "",
    fileExist: "",
  });

  const user = _getLoggedInUserDetails();
  useEffect(() => {
    getAllFiles({
      id: user.id,
      projectId: params.projectId,
    })
      .then((pa) => {
        if (pa.error !== undefined && pa.error.data === null) {
          if (pa.error.status === 401) {
            throw pa.error.status;
          }
        } else if (pa.data !== undefined && pa.data.success) {
          const clonedRes = _.cloneDeep(pa.data.result.content);
          setFiles(clonedRes);
          const a = clonedRes.filter((d) => d.type === "REPORT");
          a.forEach((d) => {
            d.value = d.fileName;
            d.label = d.fileName;
          });
          const b = a.filter((data) => data.releaseLetters === null);
        }
      })
      .catch((err) => {
        if (err === 401) {
          console.log("Unauthorized");
        }
      });
  }, [isSubmit]);

  useEffect(() => {
    const names = files.map((file) => file.fileName);
    if (selectedFile) {
      const fileToUpload = names.some((name) => name === selectedFile.name);
      if (fileToUpload) {
        setError({
          ...error,
          fileExist:
            "File with same name already exist! Please select a different file.",
        });
      }
    }
  }, [files, selectedFile, fileSys]);

  const onFileSelection = (file) => {
    setSelectedFile(file);
    setFileDetails({ ...fileDetails, file: file });
    setFileSys(file);
    setReportDisabled(false);
    setError({ ...error, fileError: "", fileExist: "" });
  };

  const cancelFileSelection = () => {
    setFileDetails({ ...fileDetails, file: {}, type: "" });
    setSelectedFile("");
    setReportDisabled(true);
    setError({ ...error, fileError: "", fileExist: "" });
  };

  const onTypeChange = (e) => {
    const selectedType = e.target.value;
    if (selectedType === "Report") {
      setFileDetails({ ...fileDetails });
      setError({ ...error, typeError: "" });
      setTypeError("");
    }
    if (selectedType === "Release") {
      setFileDetails({ ...fileDetails });
      setError({ ...error, typeError: "" });
      setTypeError("");
    }
    setFileDetails({ ...fileDetails, type: selectedType });
  };

  const validateFileData = () => {
    var isValid = [];
    let tempError = {};

    if (_.isEmpty(fileDetails.file)) {
      isValid.push(false);
      tempError.fileError = "Please upload a file to proceed";
    } else {
      isValid.push(true);
      tempError.fileError = "";
    }

    if (fileDetails.type === "") {
      isValid.push(false);
      tempError.typeError = "Please select a file type";
      setTypeError("Please select a file type");
    } else {
      isValid.push(true);
      tempError.typeError = "";
      setTypeError("");
    }

    if (error.fileExist !== "") {
      isValid.push(false);
      tempError.fileExist =
        "File with same name already exist! Please select a different file.";
    } else {
      isValid.push(true);
      tempError.fileExist = "";
    }

    setError(tempError);
    return !isValid.includes(false);
  };

  const clearInputs = () => {
    setSelectedFile("");
    setFileDetails({ ...fileDetails, file: {}, type: "" });
    setFileSys("");
  };

  const uploadFile = async () => {
    if (validateFileData() && error.fileExist === "") {
      const user = _getLoggedInUserDetails();
      const payload = {
        fileSys,
        params: {
          email: user.email,
          type: fileDetails.type.toUpperCase(),
          projectId: params.projectId,
        },
      };
      await dispatch(onUploadFile(payload));
      clearInputs();
      setReportDisabled(true);
      setIsSubmit(!isSubmit);
    }
  };

  const goToFileList = () => {
    navigate(`/tprla/${params.projectId}`);
  };

  return (
    <>
      <div className="file-upload">
        <Card className="action-card">
          <div className="card-content">
            <h4>File upload</h4>
            <FileDropzone onFileSelection={onFileSelection} />
            <FormHelperText className="error-text">
              {error.fileError}
            </FormHelperText>
            <Typography variant="small">
              Please upload file of size 10MB or less.
            </Typography>
            <Typography variant="small" className="file-supported">
              (Supported files - PDF)
            </Typography>
            {(selectedFile ? !_.isEmpty(selectedFile.name) : false) && (
              <Card className="file-card">
                <div className="file-card-description">
                  <span
                    className="Appkit4-icon icon-circle-checkmark-fill ap-font-20"
                    style={{
                      color: "green",
                      margin: "8px",
                    }}
                  />
                  {selectedFile.name}
                </div>
                <div>
                  <IconButton onClick={cancelFileSelection}>
                    <span
                      className="Appkit4-icon icon-circle-delete-fill ap-font-20"
                      style={{ color: "red" }}
                    />
                  </IconButton>
                </div>
              </Card>
            )}
            <Typography variant="small" className="file-exist">
              {error.fileExist}
            </Typography>
            <div className="file-type">
              <FormLabel className="file-type-label">Type</FormLabel>
              <Select
                fullWidth
                value={fileDetails.type}
                onChange={onTypeChange}
                sx={{ mt: 1 }}
                disabled={reportDisabled}
              >
                {Object.values(uploadFileTypes).map((type) => (
                  <MenuItem value={type}>{type}</MenuItem>
                ))}
              </Select>
              <FormHelperText className="error-text">
                {/* {error.typeError} */}
                {typeError}
              </FormHelperText>
            </div>
          </div>
          <div className="card-action">
            <Button variant="outlined" onClick={goToFileList}>
              Go To File List
            </Button>
            <Button
              variant="contained"
              className="upload-button"
              onClick={uploadFile}
            >
              Upload
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
};

export default UploadFile;
