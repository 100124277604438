import { useEffect, useState } from "react";
import ModalDialog from "../../../../components/shared/Modal/Modal";
import { useDeleteUserMutation } from "../../../../store/services/tprla/users";
import "./TprlaRoleAssign.scss";
import { Typography } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../store/slices/dtms/loading";
import { useParams } from "react-router-dom";

const DeleteRole = (props) => {
  const {
    open = false,
    onClose = () => {},
    user,
    userRole,
    refreshUserList = () => {},
  } = props;
  const dispatch = useDispatch();
  const params = useParams();

  const [deleteUser, deleteUserResult] = useDeleteUserMutation();

  const deleteRoleModalOptions = {
    title: "Delete user",
    option: {
      open,
      maxWidth: "sm",
      actionButtonName: "Yes",
      twoActionButtonRequired: true,
      actionSecondaryButtonName: "No",
    },
  };

  const [modalOptions, setModalOptions] = useState(deleteRoleModalOptions);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = deleteUserResult;

    if (isSuccess) {
      console.log("Yay! ", data);
      confirmAlert({
        title: "Success",
        message: "User access deleted successfully!",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              refreshUserList();
            },
          },
        ],
      });
    } else if (isError) {
      console.log("Oops! ", error);
      confirmAlert({
        title: "Error",
        message: "Unable to delete user access",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
    dispatch(setLoading(isLoading));
  }, [deleteUserResult]);

  const onDeleteUser = () => {
    const payload = {
      id: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      orgId: null,
      onboardedBy: null,
      modules: [
        {
          name: "TPRLA",
          role: {
            name: userRole,
            isActive: "N",
          },
        },
      ],
      roleName: null,
      is_active_user: null,
    };
    handleClose();
    deleteUser({ projectId: params.projectId, userId: user.id });
  };

  const handleClose = () => {
    setModalOptions({
      ...modalOptions,
      option: { ...modalOptions.option, open: false },
    });
    onClose();
  };
  return (
    <ModalDialog
      {...deleteRoleModalOptions}
      onSave={onDeleteUser}
      onClose={handleClose}
    >
      <Typography>
        Do you want to remove user{" "}
        <strong>
          {user.firstName} {user.lastName}
        </strong>{" "}
        ?
      </Typography>
    </ModalDialog>
  );
};

export default DeleteRole;
