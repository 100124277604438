import { useState } from "react";
import {
  Box,
  Avatar,
  Button,
  FormHelperText,
  IconButton,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { _getLoggedInUserDetails } from "../../../../envUrl";
import DeleteCommentModal from "./DeleteCommentModal";
import { timestampToDate } from "../../../../utils/utilFns";
import { useParams } from "react-router-dom";
import ReplyView from "./ReplyView";
import Mentions from "../../Mentions/Mentions";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import _ from "lodash";

const CommentView = ({
  comment,
  jumpToHighlightArea,
  refreshComments = () => {},
  disableComment,
  editComment = () => {},
  replyComment = () => {},
  deleteComment = () => {},
  sharedWith = [],
}) => {
  const [isEditActive, setIsEditActive] = useState(false);
  const [commentError, setCommentError] = useState("");
  const [deleteCommentModal, setDeleteCommentModal] = useState({
    open: false,
    commentId: null,
    comment: "",
  });
  const [reply, setReply] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [mentions, setMentions] = useState([]);
  const [editedComment, setEditedComment] = useState("");

  const user = _getLoggedInUserDetails();

  const params = useParams();

  const handleCommentEdit = () => {
    if (editedComment.comment.trim() !== "") {
      setIsEditActive(false);
      setCommentError("");
      const payload = {
        projectId: params.projectId,
        releaseId: params.fileId,
        comments: [
          {
            id: comment.id,
            comment: editedComment.comment,
            commenterId: user.id,
            commenterName: `${user.firstName} ${user.lastName}`,
            commenterEmail: user.email,
            commentTime: comment.commentTime,
            mentionedUserList: mentions,
          },
        ],
      };
      editComment(payload);
    } else {
      setCommentError("Please enter your comments!");
    }
  };

  const handleReplySubmit = () => {
    const payload = {
      projectId: params.projectId,
      releaseId: params.fileId,
      comments: [
        {
          comment: reply,
          commenterId: user.id,
          commenterName: `${user.firstName} ${user.lastName}`,
          commenterEmail: user.email,
          highlightedText: comment.highlightedText,
          coordinates: comment.coordinates,
          replyList: [],
          replyOf: comment.id,
          commentTime: Date.now(),
          mentionedUserList: mentions,
        },
      ],
    };

    replyComment(payload);
    setReply("");

    if (comment?.replyList?.length > 1) {
      setExpanded("panel1");
    }
  };

  const openDeleteModal = () => {
    setDeleteCommentModal({
      ...deleteCommentModal,
      open: true,
      commentId: comment.id,
      comment: comment.comment,
    });
  };

  const closeDeleteModal = () => {
    setDeleteCommentModal({
      ...deleteCommentModal,
      open: false,
    });
  };

  const onDeleteComment = () => {
    deleteComment({
      projectId: params.projectId,
      releaseId: params.fileId,
      commentId: comment.id,
    });
    setDeleteCommentModal({
      open: false,
      commentId: null,
    });
    setIsEditActive(false);
  };

  const handleChange = (panel) => (e, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onMention = (e) => {
    const i = mentions.findIndex((m) => m === e);
    if (i === -1) {
      setMentions([...mentions, e]);
    }
  };

  const onClickEditComment = (cmt) => {
    setIsEditActive(true);
    setEditedComment({ id: cmt.commentId, comment: cmt.comment });
  };

  const onClickCancelEditComment = (cmt) => {
    setIsEditActive(false);
    setEditedComment("");
  };

  const onCommentChange = (e) => {
    const value = e.target.value;
    if (value.length > 1000) {
      setCommentError("Comment too long. Please enter a shorter comment.");
    } else {
      setCommentError(null);
    }
    setEditedComment({ ...editedComment, comment: value });
  };

  return (
    <div
      className="comment-container"
      key={comment.id}
      onClick={() => jumpToHighlightArea(comment.coordinates[0])}
    >
      <div className="reply-header">
        <Avatar sx={{ width: 27, height: 27 }}>{`${comment.commenterName
          .split(" ")[0]
          .slice(0, 1)}${comment.commenterName
          .split(" ")[1]
          .slice(0, 1)}`}</Avatar>

        <Typography className="name">{comment.commenterName}</Typography>
        <FiberManualRecordIcon
          sx={{ width: "0.2em", height: "0.2em", fill: "#9b9a9c" }}
        />
        <Typography className="date">
          {timestampToDate(comment.commentTime)}
        </Typography>
        {comment.isCommentEdited && (
          <>
            <FiberManualRecordIcon
              sx={{ width: "0.2em", height: "0.2em", fill: "#9b9a9c" }}
            />
            <p className="edited">Edited</p>
          </>
        )}

        {comment.commenterEmail === user.email && !disableComment && (
          <div className="btn-grp">
            {!isEditActive ? (
              <Tooltip title="Edit Reply">
                <IconButton
                  size="small"
                  sx={{ fill: "#000" }}
                  id={comment.id}
                  onClick={() => onClickEditComment(comment)}
                >
                  <span className="Appkit4-icon icon-pencil-fill ap-font-16 icon-grey-dark" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Cancel Edit">
                <IconButton
                  size="small"
                  sx={{ fill: "#000" }}
                  id={comment.id}
                  onClick={() => onClickCancelEditComment(comment)}
                >
                  <span className="Appkit4-icon icon-close-outline ap-font-16 icon-grey-dark" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Delete Reply">
              <IconButton
                size="small"
                onClick={openDeleteModal}
                disabled={
                  !_.isEmpty(comment?.replyList) &&
                  comment?.replyList?.length > 0
                }
              >
                <span
                  className={`Appkit4-icon icon-delete-outline ap-font-16 icon-grey-dark ${
                    !_.isEmpty(comment?.replyList) &&
                    comment?.replyList?.length > 0
                      ? "disabled"
                      : ""
                  }`}
                />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="comment-container__content">
        <blockquote>{comment.highlightedText}</blockquote>
        <div className="comment-container__content-edit-field">
          <div
            className={`${isEditActive ? "comment-view edit" : "comment-view"}`}
          >
            <Mentions
              data={sharedWith}
              text={isEditActive ? editedComment.comment : comment.comment}
              onChange={onCommentChange}
              onAdd={onMention}
              isDisabled={!isEditActive}
            />
            {isEditActive && (
              <Tooltip title="Send">
                <IconButton
                  size="small"
                  sx={{ height: "30px" }}
                  onClick={handleCommentEdit}
                  disabled={
                    editedComment?.comment?.trim() === "" ||
                    editedComment.comment === comment.comment
                      ? true
                      : false
                  }
                >
                  <span className="Appkit4-icon icon-right-chevron-outline ap-font-16" />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <FormHelperText>{commentError}</FormHelperText>
        </div>

        {comment?.replyList?.length > 0 && (
          <div className="comment-container__content-reply">
            {!expanded && comment?.replyList?.length !== 0 && (
              <Typography variant="subtitle2" sx={{ fontSize: "12px", mb: 2 }}>
                Replies
              </Typography>
            )}
            {comment?.replyList?.length > 1 ? (
              <>
                {!expanded && (
                  <Box className="initial-replies">
                    <ReplyView
                      comment={comment?.replyList[0]}
                      refreshComments={refreshComments}
                      disableComment={disableComment}
                      editComment={editComment}
                      deleteComment={deleteComment}
                      sharedWith={sharedWith}
                    />
                  </Box>
                )}
                <Box className="replies-container">
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                    elevation={0}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="subtitle2" sx={{ fontSize: "12px" }}>
                        {`All replies (+${
                          !expanded
                            ? comment.replyList.length - 1
                            : comment.replyList.length
                        }):`}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {comment?.replyList?.map((reply) => (
                        <ReplyView
                          comment={reply}
                          refreshComments={refreshComments}
                          disableComment={disableComment}
                          editComment={editComment}
                          deleteComment={deleteComment}
                          sharedWith={sharedWith}
                        />
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </>
            ) : (
              <Box>
                {comment?.replyList?.map((reply) => (
                  <ReplyView
                    comment={reply}
                    refreshComments={refreshComments}
                    disableComment={disableComment}
                    editComment={editComment}
                    deleteComment={deleteComment}
                    sharedWith={sharedWith}
                  />
                ))}
              </Box>
            )}
          </div>
        )}

        {!disableComment &&
          (comment.replyList === undefined || comment?.replyList?.length === 0
            ? comment.commenterEmail !== user.email
            : comment.replyList !== undefined ||
              comment?.replyList?.length === 0) && (
            <div div className="comment-reply">
              <Mentions
                data={sharedWith}
                text={reply}
                onChange={(e) => setReply(e.target.value)}
                onAdd={onMention}
                isDisabled={false}
                style={{
                  "&multiLine": {
                    control: {
                      fontSize: "0.8rem !important",
                    },
                  },
                }}
              />
              <Button
                size="small"
                onClick={handleReplySubmit}
                disabled={reply.trim() === "" ? true : false}
              >
                Reply
              </Button>
            </div>
          )}
      </div>
      <DeleteCommentModal
        open={deleteCommentModal.open}
        onClose={closeDeleteModal}
        commentId={deleteCommentModal.commentId}
        comment={deleteCommentModal.comment}
        onDeleteComment={onDeleteComment}
        type="comment"
      />
    </div>
  );
};

export default CommentView;
