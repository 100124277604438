import React, { useState } from "react";
import "./TprlaDashboard.scss";
import { Button } from "@mui/material";
import { Box, Card, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

const ProjectCard = ({ projectDetails, isAdmin, onDelete }) => {
  const navigate = useNavigate();

  const openDeleteModal = (e) => {
    e.stopPropagation();
    onDelete({
      projectId: projectDetails.projectId,
      projectName: projectDetails.projectName,
    });
  };

  const cardClickHandler = () => {
    navigate(`${projectDetails.projectId}`);
  };

  return (
    <div>
      <Card className="project-card" onClick={cardClickHandler}>
        <Box className="project-card__header">
          <Typography
            className="h4 flex-grow-1 flex-direction"
            variant="h6"
            style={{ color: "black" }}
          >
            {projectDetails.projectName}
          </Typography>
        </Box>
        <Box className="content">
          <div className="project-label">
            <Typography
              className="inline variable-font-size"
              variant="subtitle2"
            >
              Client name
            </Typography>
            <br></br>
            <Typography
              className="inline variable-font-size"
              variant="subtitle2"
            >
              Start date
            </Typography>
            <br></br>
            <Typography
              className="inline variable-font-size"
              variant="subtitle2"
            >
              End date
            </Typography>
            <br></br>
          </div>
          <div className="project-value">
            <Typography
              className="inline variable-font-size "
              color="textSecondary"
              variant="subtitle2"
            >
              <span>:</span>
              {projectDetails.client}
            </Typography>
            <br></br>
            <Typography
              className="inline variable-font-size"
              color="textSecondary"
              variant="subtitle2"
            >
              <span>:</span>
              {projectDetails.startDate}
            </Typography>
            <br></br>
            <Typography
              className="inline variable-font-size"
              color="textSecondary"
              variant="subtitle2"
            >
              <span>:</span>
              {projectDetails.endDate}
            </Typography>
            <br></br>
          </div>
        </Box>
        {isAdmin && (
          <div className="project-card__footer">
            <Button onClick={openDeleteModal}>Close project</Button>
          </div>
        )}
      </Card>
    </div>
  );
};

export default ProjectCard;
