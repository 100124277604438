import _ from "lodash";
import {
  fileTypesConstants,
  tpraDeleteConfirmMsg,
} from "../modules/tprla/tprlaUtils";
import moment from "moment";

const CryptoJS = require("crypto-js");

export const getRandomString = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const b64toBlob = (
  b64Data,
  contentType = "application/pdf",
  sliceSize = 512
) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const sortByName = (user, nextUser) => {
  if (user.firstName !== nextUser.firstName) {
    return user.firstName.toLowerCase() < nextUser.firstName.toLowerCase()
      ? -1
      : user.firstName.toLowerCase() > nextUser.firstName.toLowerCase()
      ? 1
      : 0;
  } else {
    return user.lastName.toLowerCase() < nextUser.lastName.toLowerCase()
      ? -1
      : user.lastName.toLowerCase() > nextUser.lastName.toLowerCase()
      ? 1
      : 0;
  }
};

export const convertToBytes = (memory) => {
  let memorySuffix = memory.substring(memory.length - 2, memory.length);
  switch (memorySuffix) {
    case "KB":
      return Math.ceil(1024 * parseFloat(memory));

    case "MB":
      return Math.ceil(1048576 * parseFloat(memory));

    case "GB":
      return Math.ceil(1073741824 * parseFloat(memory));

    default:
      return Math.ceil(parseFloat(memory));
  }
};

export const hmacEncode = (message, key) => {
  const encryptedText = CryptoJS.HmacSHA256(JSON.stringify(message), key);
  const hashInBase64 = CryptoJS.enc.Base64.stringify(encryptedText);
  return hashInBase64;
};

export function format(inputDate) {
  let date, month, year;

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();

  date = date.toString().padStart(2, "0");

  month = month.toString().padStart(2, "0");

  return `${date}/${month}/${year}`;
}

export const getDeleteConfirmationMsg = (
  type,
  status = "",
  isShareWithTP = false,
  isSharedWithClient = false
) => {
  if (type === "REPORT") {
    if (!isShareWithTP && !isSharedWithClient) {
      return { message: tpraDeleteConfirmMsg.reportUnshared, reConfirm: false };
    } else if (!isShareWithTP && isSharedWithClient) {
      return {
        message: tpraDeleteConfirmMsg.reportSharedWithClient,
        reConfirm: true,
      };
    } else if (isShareWithTP && !isSharedWithClient) {
      return {
        message: tpraDeleteConfirmMsg.reportSharedWithTP,
        reConfirm: true,
      };
    } else if (isShareWithTP && isSharedWithClient) {
      return {
        message: tpraDeleteConfirmMsg.reportSharedWithBoth,
        reConfirm: true,
      };
    }
  } else {
    switch (status) {
      case "YET_TO_BE_SHARED":
        return {
          message: tpraDeleteConfirmMsg.releaseUnshared,
          reConfirm: false,
        };

      case "SHARED":
        return {
          message: tpraDeleteConfirmMsg.releaseShared,
          reConfirm: false,
        };

      case "SIGNED":
        return { message: tpraDeleteConfirmMsg.releaseSigned, reConfirm: true };

      default:
        break;
    }
  }
};

export const makeFileDataForTP = (files) => {
  const clonedFiles = _.cloneDeep(files);
  var tpfiles = clonedFiles.filter(
    (file) =>
      file.type === fileTypesConstants.release ||
      file.type === fileTypesConstants.report
  );
  tpfiles.forEach((file) => {
    var currentFile = {};
    file.copies = clonedFiles.filter(
      (f) =>
        f.fileName === file.fileName &&
        f.type !== fileTypesConstants.release &&
        f.type !== fileTypesConstants.report
    );
    file.copies.forEach((f) => {
      if (
        currentFile.createdTime ? currentFile.createdTime < f.createdTime : true
      ) {
        currentFile = f;
      }
    });
    file.latestCopyFileId = currentFile.id ? currentFile.id : file.id;
    file.status = currentFile.status ? currentFile.status : file.status;
    file.isSigned = currentFile.isSigned ? currentFile.isSigned : file.isSigned;
    file.signedBy = currentFile.signedBy ? currentFile.signedBy : file.signedBy;
  });
  console.log("TP File List: ", tpfiles);
  return tpfiles;
};

export const timestampToDate = (timestamp) => {
  var date = "";

  if (
    moment(Date()).format("DD MM YYYY") ===
    moment(timestamp).format("DD MM YYYY")
  ) {
    date = moment(timestamp).format("h:mm A");
  } else if (
    moment(Date()).subtract(1, "days").format("DD MM YYYY") ===
    moment(timestamp).format("DD MM YYYY")
  ) {
    date = "Yesterday " + moment(timestamp).format("h:mm A");
  } else if (
    moment(Date()).subtract(2, "days").format("DD MM YYYY") ===
      moment(timestamp).format("DD MM YYYY") ||
    moment(Date()).subtract(3, "days").format("DD MM YYYY") ===
      moment(timestamp).format("DD MM YYYY") ||
    moment(Date()).subtract(4, "days").format("DD MM YYYY") ===
      moment(timestamp).format("DD MM YYYY") ||
    moment(Date()).subtract(5, "days").format("DD MM YYYY") ===
      moment(timestamp).format("DD MM YYYY") ||
    moment(Date()).subtract(6, "days").format("DD MM YYYY") ===
      moment(timestamp).format("DD MM YYYY") ||
    moment(Date()).subtract(7, "days").format("DD MM YYYY") ===
      moment(timestamp).format("DD MM YYYY")
  ) {
    date = moment(timestamp).format("ddd h:mm A");
  } else {
    date = moment(timestamp).format("MMM D, h:mm A");
  }

  return date;
};
