import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  _getAuthHeaders,
  _getDTMSProxy,
  _getLoggedInUserDetails,
} from "../../../envUrl";
import api from "../../api";

export const filesApi = createApi({
  reducerPath: "filesApi",
  baseQuery: fetchBaseQuery({ baseUrl: _getDTMSProxy() }),
  endpoints: (builder) => ({
    getAllFiles: builder.mutation({
      query: (details) => {
        const authHeaders = _getAuthHeaders();
        const user = _getLoggedInUserDetails();

        return {
          url: api.getAllFiles,
          method: "POST",
          params: { projectId: details.projectId },
          body: {
            pageNo: 1,
            pageSize: 3000,
            sortBy: "",
            sortDir: "",
            user_id: details.userId,
          },
          headers: { ...authHeaders, email: user.email },
        };
      },
    }),
    shareReportFile: builder.mutation({
      query: (details) => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.shareReport,
          method: "POST",
          params: { projectId: details.projectId },
          body: {
            fileId: details.fileId,
            clientIds: details.clientIds,
          },
          headers: authHeaders,
        };
      },
    }),
    shareReleaseFile: builder.mutation({
      query: (details) => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.shareRelease,
          method: "POST",
          params: { projectId: details.projectId },
          body: {
            fileId: details.fileId,
            investorIds: details.investorIds,
            // reportIds: details.reportIds,
          },
          headers: authHeaders,
        };
      },
    }),
    shareReportToTp: builder.mutation({
      query: (details) => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.shareReportToTp,
          method: "POST",
          params: { projectId: details.projectId },
          body: {
            fileId: details.fileId,
            investorIds: details.investorIds,
          },
          headers: authHeaders,
        };
      },
    }),
    shareFile: builder.mutation({
      query: (details) => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.shareFile,
          method: "POST",
          params: { projectId: details.projectId, fileId: details.fileId },
          body: details.body,
          headers: authHeaders,
        };
      },
    }),
    getReleaseLetterUser: builder.mutation({
      query: (details) => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.getReleaseUser,
          method: "GET",
          params: { projectId: details.projectId, releaseId: details.fileId },
          headers: authHeaders,
        };
      },
    }),
    deleteFile: builder.mutation({
      query: (details) => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.deleteTprlaFile,
          method: "DELETE",
          params: { projectId: details.projectId, fileId: details.fileId },
          headers: authHeaders,
        };
      },
    }),
    getReportsToLink: builder.mutation({
      query: (details) => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.getReports,
          method: "GET",
          params: { projectId: details.projectId, releaseId: details.fileId },
          headers: authHeaders,
        };
      },
    }),
    approveFileAccessRequest: builder.mutation({
      query: (details) => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.approveFile,
          method: "POST",
          params: { projectId: details.projectId },
          body: {
            fileId: details.fileId,
          },
          headers: authHeaders,
        };
      },
    }),
    rejectFileAccessRequest: builder.mutation({
      query: (details) => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.rejectFile,
          method: "POST",
          params: { projectId: details.projectId },
          body: {
            fileId: details.fileId,
          },
          headers: authHeaders,
        };
      },
    }),
    getSignedThirdParties: builder.mutation({
      query: (details) => {
        const authHeaders = _getAuthHeaders();

        return {
          url: api.getSignedThirdParties,
          method: "GET",
          params: {
            releaseId: details.fileId,
            projectId: details.projectId,
          },
          headers: authHeaders,
        };
      },
    }),
  }),
});

export const {
  useGetAllFilesMutation,
  useShareReportFileMutation,
  useShareReleaseFileMutation,
  useShareReportToTpMutation,
  useShareFileMutation,
  useGetReleaseLetterUserMutation,
  useDeleteFileMutation,
  useGetReportsToLinkMutation,
  useApproveFileAccessRequestMutation,
  useRejectFileAccessRequestMutation,
  useGetSignedThirdPartiesMutation,
} = filesApi;
