// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.something-went-wrong {
  margin-top: 15%;
  margin-bottom: 15%;
  text-align: center;
}

.error-not-found {
  margin-top: 10%;
  text-align: center;
}

.not-authorised {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/error/Error.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AACA;EACE,eAAA;EACA,kBAAA;AAEF;;AACA;EACE,SAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EAEA,gCAAA;EACA,kBAAA;AAEF","sourcesContent":[".something-went-wrong {\n  margin-top: 15%;\n  margin-bottom: 15%;\n  text-align: center;\n}\n.error-not-found {\n  margin-top: 10%;\n  text-align: center;\n}\n\n.not-authorised {\n  margin: 0;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  -ms-transform: translate(-50%, -50%);\n  transform: translate(-50%, -50%);\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
