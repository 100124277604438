// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sticky-panel-dtms main.height-setting div.rpv-core__inner-pages {
  height: 600px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/StickyPanel/StickyPanel.scss"],"names":[],"mappings":"AAII;EACE,wBAAA;AAHN","sourcesContent":["@import \"../../../variables.scss\";\n\n.sticky-panel-dtms {\n  main.height-setting {\n    div.rpv-core__inner-pages {\n      height: 600px !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
