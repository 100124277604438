import React, { Fragment, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { TextField } from "@material-ui/core";
import { Box, IconButton, InputAdornment } from "@mui/material";
import "./SearchAndFilter.scss";

const SearchAndFilter = (props) => {
  const { onSearchChange, clearSearch = "", clearFilter = "" } = props;
  const [value, setValue] = useState("");
  const debounceSearch = useRef(_.debounce(onSearchChange, 500));

  // const onFilter = (val) => {
  //   if (selectedValue.toString() === val.toString()) {
  //     setSelectedValue([]);
  //     onFilterChange([]);
  //   } else {
  //     setSelectedValue(val);
  //     onFilterChange([...val]);
  //   }
  //   handleClose();
  // };

  const onSearch = (val) => {
    setValue(val);
    debounceSearch.current(val);
  };

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const onClear = () => {
    onSearch("");
  };

  useEffect(() => {
    setValue("");
  }, [clearSearch, clearFilter]);

  return (
    <Fragment>
      <Box className="search-bar">
        <TextField
          value={value}
          onChange={(event) => onSearch(event.target.value)}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="Appkit4-icon icon-search-outline ap-font-16" />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {value && (
                  <InputAdornment position="end">
                    <IconButton onClick={onClear}>
                      <span className="Appkit4-icon icon-close-outline ap-font-16" />
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            ),
          }}
        />
      </Box>
    </Fragment>
  );
};

export default SearchAndFilter;
