import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  _getAuthHeaders,
  _getClientID,
  _getDTMSProxy,
  _getRedirectUri,
} from "../../../envUrl";
import api from "../../api";

export const moduleApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: _getDTMSProxy(),
  }),
  endpoints: (builder) => ({
    getPublicModules: builder.mutation({
      query: () => {
        return {
          url: api.getPublicModules,
          method: "GET",
        };
      },
    }),
    getModuleRolesAdmin: builder.mutation({
      query: () => {
        const authHeaders = _getAuthHeaders();
        return {
          url: api.getModuleRoles,
          method: "GET",
          params: { moduleName: "DTMS", isProject: false },
          headers: authHeaders,
        };
      },
    }),
    getModuleRolesNonAdmin: builder.mutation({
      query: () => {
        const authHeaders = _getAuthHeaders();
        return {
          url: api.getPublicModuleRoles,
          method: "GET",
          params: { moduleName: "DTMS" },
          headers: authHeaders,
        };
      },
    }),
    getAccessibleModules: builder.mutation({
      query: () => {
        const authHeaders = _getAuthHeaders();
        return {
          url: api.getAccessibleModules,
          method: "GET",
          headers: authHeaders,
        };
      },
    }),
  }),
});

export const {
  useGetPublicModulesMutation,
  useGetModuleRolesAdminMutation,
  useGetModuleRolesNonAdminMutation,
  useGetAccessibleModulesMutation,
} = moduleApi;
