import axios from "axios";

export const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
  (request) => {
    let tempRequestHeaders = {};
    tempRequestHeaders["Accept"] = "application/json";
    request.headers = request.headers
      ? { ...request.headers, ...tempRequestHeaders }
      : tempRequestHeaders;
    console.log("Request: ", request.headers, tempRequestHeaders);
    return request;
  },
  (error) => {
    console.log("Error: ", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log("Response: ", response);
    if (response.data.success || response.status === 200) {
      if (response.headers["content-disposition"]) {
        return response;
      }
      return response.data;
    } else {
      let errorMsg = "Something went wrong";
      console.log("Error: ", errorMsg);
      throw new Error(errorMsg);
    }
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    var errorMsg = "";
    console.log("Error: ", error, error.response);
    if (!error.response || !error.response.data.error) {
      errorMsg = "Something went wrong";
    } else {
      errorMsg =
        error.response.data.error.message === "" ||
        error.response.data.error.message === null ||
        error.response.data.error.message === undefined
          ? error.message
          : error.response.data.error.message;
    }
    console.log("Error Message: ", errorMsg);
    throw new Error(errorMsg);
  }
);

export const requestConnectorWithInterceptor = (
  method,
  url,
  headers,
  params,
  bodyData,
  responseType
) => {
  return axiosInstance({
    method: `${method}`,
    url: `${url}`,
    data: bodyData ? bodyData : null,
    params: params ? params : null,
    headers: headers ? headers : null,
    responseType: responseType ? responseType : "",
  });
};
